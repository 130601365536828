import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

// import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
// import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

// import { useRoot } from 'context/RootContext';

const useLanguages = () => {
  const path = API_ENDPOINTS.LANGUAGES;
  // const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  // const { showNotification } = useRoot();
  const [{ result: languages, loading: loadingLanguages, error: errorLanguages }, getLanguages] = useApi(path, {
    initialFetch: false,
  });

  const language = useSelector(getLanguage);

  useEffect(() => {
    getLanguages();
  }, [getLanguages, language]);

  // useEffect(() => {
  //   if (errorLanguages) {
  //     showNotification(
  //       {
  //         message: t('languages_fetch_fail'),
  //       },
  //       ALERT_VARIANTS.DANGER
  //     );
  //   }
  // }, [errorLanguages, showNotification, t]);

  return [{ languages, loadingLanguages, errorLanguages }, getLanguages];
};

export default useLanguages;
