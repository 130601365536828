import { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

// import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';
// import { useRoot } from 'context/RootContext';

const useCurrentEdition = () => {
  const path = API_ENDPOINTS.CURRENT_EDITION;
  // const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  // const { showNotification } = useRoot();
  const [
    { result: currentEdition, loading: loadingCurrentEdition, error: errorCurrentEdition },
    getCurrentEdition,
  ] = useApi(path, {
    initialFetch: false,
  });

  const language = useSelector(getLanguage);

  useEffect(() => {
    getCurrentEdition();
  }, [getCurrentEdition, language]);

  // useEffect(() => {
  //   if (errorCurrentEdition) {
  //     showNotification(
  //       {
  //         message: t('CurrentEdition_fetch_fail'),
  //       },
  //       ALERT_VARIANTS.DANGER
  //     );
  //   }
  // }, [errorCurrentEdition, showNotification, t]);

  return [{ currentEdition, loadingCurrentEdition, errorCurrentEdition }, getCurrentEdition];
};

export default useCurrentEdition;
