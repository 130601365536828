import { useEffect, useCallback, useState } from 'react';
import { useImmer } from 'use-immer';

const useQuery = initialQuery => {
  const [query, setQuery] = useImmer({ ...initialQuery });
  const [count, setCount] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const changePage = useCallback(
    newPage => {
      setQuery(draft => (draft.page = newPage - 1));
    },
    [setQuery]
  );

  useEffect(() => {
    if (count >= 0) {
      setMaxPages(Math.ceil(count / query.pageSize));
    }
  }, [count, query.pageSize]);

  useEffect(() => {
    setQuery(draft => {
      draft.page = 0;
    });
  }, [query.term, query.search, query.sortBy, setQuery]);

  /**
   * pageSize - number of records per page
   * count - total number of records
   * maxPages - total number of pages
   */

  return [query, setQuery, count, setCount, maxPages, changePage];
};

export default useQuery;
