import React from 'react';
import styled from 'styled-components';

const HeadCell = ({ column }) => {
  return (
    <Wrapper>
      <div className="d-flex align-items-center">{column.name}</div>
    </Wrapper>
  );
};

const Wrapper = styled.th`
  && {
    padding: 0 1.5rem;
    font-size: 0.75rem;
    letter-spacing: 1.25px;
    line-height: 7.5;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--label);
    border: none;
  }
`;

export default HeadCell;
