// import { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
// import { useRoot } from 'context/RootContext';
import { API_ENDPOINTS, HTTP_VERBS } from 'utils/constants';

const useDeleteCart = () => {
  // const { t } = useTranslation(LOCALIZATION_PAGES.CART);
  // const { showNotification } = useRoot();
  const [{ result, loading, error, setResult }, apiClearCart] = useApi(API_ENDPOINTS.CART, {
    initialFetch: false,
    verb: HTTP_VERBS.DELETE,
  });

  /* eslint-disable */
  // useEffect(() => {
  //   if (result) {
  //     showNotification({
  //       message: t('delete_cart_success'),
  //     });
  //   }
  // }, [result, showNotification]);

  // useEffect(() => {
  //   if (error) {
  //     showNotification(
  //       {
  //         message: t('delete_cart_fail'),
  //       },
  //       ALERT_VARIANTS.DANGER
  //     );
  //   }
  // }, [error, showNotification]);
  /* eslint-enable */

  return [{ result, loading, error, setResult }, apiClearCart];
};

export default useDeleteCart;
