import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS, API_QUERY } from 'utils/constants';

const useVenues = (query = API_QUERY.defaultAll) => {
  const queryString = `${API_ENDPOINTS.VENUES}/online?Term=${query.term}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const [path, setPath] = useState(queryString);
  const [{ result: venues, loading: loadingVenues, error }, getVenues] = useApi(path, {
    initialFetch: false,
  });
  const language = useSelector(getLanguage);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    getVenues();
  }, [getVenues, language]);

  return [{ venues, loadingVenues, error }, getVenues];
};

export default useVenues;
