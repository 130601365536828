import React from 'react';
import styled from 'styled-components';
import { Dropdown as ReactstrapDropdown, DropdownMenu } from 'reactstrap';

import { useToggle } from 'utils/hooks';

const Dropdown = ({ open = false, ToggleElem, toggleFunc = false, children, ...rest }) => {
  const [isOpen, setIsOpen] = useToggle(open);

  const toggle = e => {
    if (toggleFunc) {
      toggleFunc(e, setIsOpen, isOpen);
    } else {
      setIsOpen();
    }
  };

  return (
    <CustomDropdown isOpen={isOpen} toggle={toggle} {...rest}>
      <ToggleElem />
      <MenuWrapper right flip={false}>
        {children}
      </MenuWrapper>
    </CustomDropdown>
  );
};

const CustomDropdown = styled(ReactstrapDropdown)`
  display: inline-block;
  position: relative;
`;

const MenuWrapper = styled(DropdownMenu)`
  max-height: 250px;
  overflow: auto;

  @media (min-width: 1201px) {
    transform: translate3d(0, 55px, 0px) !important;
  }

  @media (min-width: 1201px) {
    &.dropdown-menu {
      width: unset !important;
      max-width: 250% !important;
    }
  }

  &.dropdown-menu {
    padding: 0;
    width: 100%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09);
    border: 0;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    max-width: 400px;
  }
  .dropdown-item:hover,
  .dropdown-item.active:hover {
    background-color: var(--primary);
    > * {
      color: var(--white);
    }
  }
  .dropdown-item.active {
    background-color: var(--white);
    > * {
      color: var(--primary);
    }
  }
`;
export default Dropdown;
