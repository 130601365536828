import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Modal as DefaultModal,
  Button as DefaultButton,
  ModalFooter as DefaultModalFooter,
  Alert as DefaultAlert,
  Spinner,
} from 'reactstrap';

import routes, { matchRoute } from 'utils/routes';
import { ModalBody, ModalHeader, Text, FormAlert as AlertComponent } from 'components';
import {
  TICKET_TYPE_SELECTORS,
  LOCALIZATION_PAGES,
  CURRENCY,
  SCREEN_BREAKPOINTS,
  MAX_TICKETS_PURCHASE_LIMIT,
  SEATING_TYPES,
  ALERT_VARIANTS,
} from 'utils/constants';

const PurchaseModal = ({
  width,
  isOpen,
  values,
  ticketTypeSelector,
  setTicketTypeSelector,
  screening,
  isLimitReached,
  currency = CURRENCY.BAM,
  ticketsCountAndPrice,
  accreditation,
  toAccTicketSelection,
  loading,
  fetchLoading,
  toggle,
  onBack,
  onConfirm,
  isSubmitDisabled,
  isEveryCartSeatDeselected,
  isSeatTaken = false,
  setIsSeatTaken = null,
  children,
}) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const history = useHistory();
  const currentRoute = matchRoute(history.location.pathname);
  const cards = [
    {
      id: 1,
      img: `${process.env.PUBLIC_URL}/assets/icons/regular.svg`,
      mainText: t('regular_main_text'),
      description: t('regular_description'),
      buttonText: t('buy_tickets'),
      onClick: () => setTicketTypeSelector(TICKET_TYPE_SELECTORS.REGULAR),
    },
    {
      id: 2,
      img: `${process.env.PUBLIC_URL}/assets/icons/accreditation.svg`,
      mainText: t('accreditation_main_text'),
      description: t('accreditation_description'),
      buttonText: t('get_tickets'),
      onClick: () => setTicketTypeSelector(TICKET_TYPE_SELECTORS.ACCREDITATION),
    },
  ];
  // const screeningName = screening?.title || screening?.screeningName;

  const handleModalHeaderText = () => {
    if (!ticketTypeSelector) return t('modal_header_one');

    if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && !toAccTicketSelection)
      return t('modal_header_accreditation');

    return (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && toAccTicketSelection) ||
      ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR
      ? t('modal_header_three')
      : '';
  };

  const handleSubmitBtnText = () => {
    if (currentRoute.path === routes.CART.path) {
      return tCommon('update_cart');
    }
    if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && !toAccTicketSelection) {
      return tCommon('to_ticket_selection');
    }
    if (isEveryCartSeatDeselected) return tCommon('update_cart');
    return tCommon(
      'add_tickets',
      ticketTypeSelector !== TICKET_TYPE_SELECTORS.ACCREDITATION &&
        ticketsCountAndPrice.length >= 2 && {
          ticketsCount: ticketsCountAndPrice[0],
          ticketsPrice: `- ${parseFloat(ticketsCountAndPrice[1]).toFixed(2)}`,
          currency,
        }
    );
  };

  const handleIsSubmitBtnDisabled = () => {
    // Distributed seating
    if (screening && screening.seatingType === SEATING_TYPES.DISTRIBUTION) {
      let tickets = values?.ticketInserts?.find(t => t.ticketTypeSelectorId === ticketTypeSelector);

      if ((!tickets || !tickets?.ticketSeatTypes.length) && toAccTicketSelection) return true;
      if (currentRoute.path === routes.CART.path) return loading || fetchLoading ? true : false;

      if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && accreditation && !toAccTicketSelection)
        return false;

      return !!(ticketsCountAndPrice[0] === 0 || loading || fetchLoading);
    }
    // Reserved seating
  };

  const AlertMessage = () => {
    let message = '';

    if (
      ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION &&
      toAccTicketSelection &&
      ticketsCountAndPrice[0] === MAX_TICKETS_PURCHASE_LIMIT
    )
      message = t('tickets_limit');

    if (
      ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION &&
      toAccTicketSelection &&
      ticketsCountAndPrice[0] < MAX_TICKETS_PURCHASE_LIMIT
    )
      message = tCommon('accreditation_tickets_limit');

    if (ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR) message = t('tickets_limit');

    return !fetchLoading && message && <Alert isOpen={isLimitReached} alertText={message} />;
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      // className={`m-0 m-lg-auto ${width < SCREEN_BREAKPOINTS.XL && 'mw-100'}`}
      size="xl"
    >
      <ModalHeader
        className={`mt-lg-1 mx-lg-3 mb-3 mb-xl-0 my-lg-3" ${width < SCREEN_BREAKPOINTS.LG ? 'border-bottom-0' : ''}`}
        toggle={toggle}
      >
        {handleModalHeaderText()}
      </ModalHeader>

      <ModalBody
        className={`bg-white d-lg-flex flex-lg-column mt-2 px-2 px-lg-4 pb-0 ${
          !ticketTypeSelector && width >= SCREEN_BREAKPOINTS.LG ? 'rounded-bottom' : ''
        }`}
      >
        <AlertComponent
          color={ALERT_VARIANTS.DANGER}
          isOpen={isSeatTaken}
          toggle={() => setIsSeatTaken(false)}
          className="mx-3 mt-lg-2"
        >
          {t('seat_taken')}
        </AlertComponent>
        <AlertMessage />

        {!ticketTypeSelector ? (
          <CardsWrapper className="h-100 w-100 d-flex flex-column flex-lg-row align-items-center justify-content-center pb-3">
            {cards.map(c => (
              <Card key={c.id} className="my-lg-4 mx-lg-3 pt-3 pt-lg-4 pb-4 px-3 px-sm-5 px-lg-5">
                <ViewImg src={c.img} className="my-lg-5" />
                <CardText text={c.mainText} />
                <CardDescriptionText text={c.description} />

                <Button className="mt-4 mb-lg-5" color="primary" onClick={c.onClick}>
                  <WhiteBtnText text={c.buttonText} />
                </Button>
              </Card>
            ))}
          </CardsWrapper>
        ) : (
          children
        )}
      </ModalBody>

      {(ticketTypeSelector || width <= SCREEN_BREAKPOINTS.LG) && (
        <ModalFooter className="py-3 px-4 mx-lg-3" border={!ticketTypeSelector ? 'false' : 'true'}>
          <div className="d-flex justify-content-between w-100">
            {ticketTypeSelector && width >= SCREEN_BREAKPOINTS.LG && (
              <SecondaryButton outline onClick={toggle}>
                <GreyBtnText text={tCommon('cancel')} />
              </SecondaryButton>
            )}

            <div className="w-100 d-lg-flex flex-lg-row-reverse">
              {ticketTypeSelector && (
                <Button
                  color="primary"
                  className={`${width <= SCREEN_BREAKPOINTS.LG ? 'w-100 mb-3' : 'ml-3'}`}
                  disabled={isSubmitDisabled ? isSubmitDisabled() : handleIsSubmitBtnDisabled()}
                  onClick={onConfirm}
                >
                  {loading ? <Spinner size="sm" /> : <WhiteBtnText text={handleSubmitBtnText()} />}
                </Button>
              )}

              {currentRoute && currentRoute.path !== routes.CART.path && (
                <SecondaryButton
                  className={`${width <= SCREEN_BREAKPOINTS.LG ? 'w-100 mr-3' : ''}`}
                  outline
                  onClick={onBack}
                >
                  <GreyBtnText text={tCommon('back')} />
                </SecondaryButton>
              )}
            </div>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

const Alert = ({ isOpen, alertText }) => (
  <DefaultAlert color="warning" isOpen={isOpen} className="mx-3 mt-lg-2">
    <Text fontSize="0.875rem" fontWeight="500" lineHeight="1.7" color="textWarning">
      {alertText}
    </Text>
  </DefaultAlert>
);

const CardText = ({ text }) => (
  <Text className="mb-3 text-center" fontSize="1rem" fontWeight="500" lineHeight="1.88">
    {text}
  </Text>
);

const WhiteBtnText = ({ text }) => (
  <Text fontSize="0.75rem" fontWeight="bold" color="white" textTransform="uppercase">
    {text}
  </Text>
);

const GreyBtnText = ({ text }) => (
  <Text fontSize="0.75rem" fontWeight="bold" color="grey" textTransform="uppercase">
    {text}
  </Text>
);

const Modal = styled(DefaultModal)`
  @media (min-width: 1200px) {
    .modal-content {
      min-height: 584.2px;
    }
  }
`;

//   && {
//     /* max-width: max-content; */
//     /* height: -webkit-fill-available;
//     height: -moz-available; */

//     @media (max-width: 1199px) {
//       .modal-dialog {
//         width: 100%;
//         /* height: 100%; */
//         margin: 0;
//         padding: 0;
//       }

//       .modal-content {
//         /* height: -webkit-fill-available;
//         height: -moz-available; */
//         width: 100vw;
//         border-radius: 0;
//       }
//     }

//     @media (min-width: 1200px) {
//       .modal-content {
//         min-height: 584.2px;
//         /* max-width: 1122px; */
//       }
//     }
//   }
// ;

const ModalFooter = styled(DefaultModalFooter)`
  && {
    background-color: var(--white);
    border-top: ${({ border }) => (border === 'true' ? '1px solid #f7f7f7;' : 'none;')};
  }
`;

const CardsWrapper = styled.div`
  background-color: var(--white);
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 530px;
  margin: 0 1rem;
  border-radius: 6px;
  border: solid 1px #d8e5ee;
  background-color: #fafbfc;

  &:first-child {
    margin-bottom: 2rem;
  }
`;

const CardDescriptionText = styled(({ text, ...rest }) => (
  <Text fontWeight="500" color="grey" lineHeight="1.86" {...rest}>
    {text}
  </Text>
))`
  max-width: 343px;
  margin: 0 2rem;
  text-align: center;
`;

const ViewImg = styled.img`
  width: 100%;
  height: 67.2px;
  margin: 2rem 0;
  object-fit: contain;
`;

const Button = styled(DefaultButton)`
  && {
    width: 236px;
    height: 38px;
    border-radius: 4px;
    background-color: var(--primary);

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const SecondaryButton = styled(DefaultButton)`
  && {
    width: 120px;
    height: 38px;
    border-radius: 4px;
    border: solid 1px #d8d8d8;

    &:hover {
      p {
        color: var(--white);
      }
    }

    &:disabled {
      cursor: not-allowed;

      p {
        color: var(--grey);
      }
    }
  }
`;

PurchaseModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  width: PropTypes.number.isRequired,
  setTicketTypeSelector: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLimitReached: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  toAccTicketSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  fetchLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  ticketTypeSelector: PropTypes.number,
  ticketsCountAndPrice: PropTypes.array,
  currency: PropTypes.string,
  screeningName: PropTypes.string,
};

export default PurchaseModal;
