import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';
// import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
// import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
// import { useRoot } from 'context/RootContext';
import { API_ENDPOINTS } from 'utils/constants';

const useFilms = query => {
  const queryString = `${API_ENDPOINTS.FILMS}?Term=${query.term}&CountryId=${query.countryId}&LanguageId=${query.languageId}&SectionId=${query.sectionId}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  // const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);
  // const { showNotification } = useRoot();
  const [{ result: films, loading: loadingFilms, error: errorFilms }, getFilms] = useApi(queryString, {
    initialFetch: false,
  });

  const language = useSelector(getLanguage);

  useEffect(() => {
    getFilms();
  }, [getFilms, language]);

  // useEffect(() => {
  //   if (errorFilms) {
  //     showNotification(
  //       {
  //         message: t('films_fetch_fail'),
  //       },
  //       ALERT_VARIANTS.DANGER
  //     );
  //   }
  // }, [errorFilms, showNotification, t]);

  return [{ films, loadingFilms, errorFilms }, getFilms];
};

export default useFilms;
