import { useEffect } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { useTranslation } from 'react-i18next';

import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';

const useSignUp = () => {
  const { showNotification } = useRoot();
  const { t } = useTranslation(LOCALIZATION_PAGES.AUTH);

  const [{ result: resultSignUp, loading: loadingSignUp, error: errorSignUp }, signUp] = useApi(
    'accounts/external/register',
    {
      initialFetch: false,
      verb: HTTP_VERBS.POST,
    }
  );

  useEffect(() => {
    if (errorSignUp) {
      showNotification(
        {
          message: t('sign_up_failed'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorSignUp, showNotification, t]);

  return [{ resultSignUp, loadingSignUp, errorSignUp }, signUp];
};

export default useSignUp;
