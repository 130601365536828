import React from 'react';

import Text from './Text';

const Label = props => (
  <Text color={props.color ? props.color : 'label'} textTransform="uppercase" fontWeight={'bold'} {...props}>
    {props.children}
  </Text>
);

export default Label;
