import React from 'react';
import styled, { css } from 'styled-components';
import { FiArrowDown as DefaultFiArrowDown } from 'react-icons/fi';

const iconsPath = `${process.env.PUBLIC_URL}/assets/icons/`;
const mediaPath = `${process.env.PUBLIC_URL}/assets/media/`;

const FilterIcon = ({ isFiltered }) =>
  isFiltered ? (
    <FilterIconImg src={`${iconsPath}filterAppliedIcon.svg`} />
  ) : (
    <FilterIconImg src={`${iconsPath}filterIcon.svg`} />
  );
const FilterIconImg = styled.img``;

const SFFLogo = props => <SFFLogoImg {...props} src={`${mediaPath}sff_logo_2024.svg`} />;
const SFFLogoImg = styled.img`
  width: 150px;

  @media (min-width: 400px) {
    width: 190px;
  }

  @media (min-width: 600px) {
    width: 250px;
    ${({ scrolled }) =>
      scrolled &&
      css`
        width: 190px;
      `};
  }

  @media (min-width: 767px) {
    ${({ scrolled }) =>
      scrolled &&
      css`
        width: 190px;
      `};
  }

  @media (min-width: 900px) {
    width: 315px;
    ${({ scrolled }) =>
      scrolled &&
      css`
        width: 190px;
      `};
  }
`;

const RaiffeisenHeader = props => <RaiffeisenHeaderImg {...props} src={`${mediaPath}raiffeisen.svg`} />;
const RaiffeisenHeaderImg = styled.img`
  ${({ scrolled }) =>
    scrolled &&
    css`
      height: 22px;
    `};
`;

const UnicreditHeader = props => <UnicreditHeaderImg {...props} src={`${mediaPath}unicredit1.svg`} />;
const UnicreditHeaderImg = styled.img`
  ${({ scrolled }) =>
    scrolled &&
    css`
      height: 14px;
    `};
`;

const CocaColaHeader = props => <CocaColaHeaderImg {...props} src={`${mediaPath}cocacola.svg`} />;
const CocaColaHeaderImg = styled.img`
  ${({ scrolled }) =>
    scrolled &&
    css`
      height: 20px;
    `};
`;

const BHTelecomHeader = props => <BHTelecomHeaderImg {...props} src={`${mediaPath}bhtelecom.svg`} />;
const BHTelecomHeaderImg = styled.img`
  ${({ scrolled }) =>
    scrolled
      ? css`
          height: 24px;
        `
      : css`
          height: 29.4px;
        `};
`;

const RaiffeisenFooter = props => <RaiffeisenFooterImg {...props} src={`${mediaPath}footer/raiffeisen.svg`} />;
const RaiffeisenFooterImg = styled.img``;

const UnicreditFooter = props => <img {...props} src={`${mediaPath}footer/unicredit-white1.svg`} alt="Unicredit" />;

const BingoFooter = props => <img {...props} src={`${mediaPath}footer/bingo.svg`} alt="Bingo Group" />;

const UniqaFooter = props => <img {...props} src={`${mediaPath}footer/uniqa.svg`} alt="Uniqa" />;

const BHTelecomFooter = props => <BHTelecomFooterImg {...props} src={`${mediaPath}footer/bhtelecom-new.svg`} />;
const BHTelecomFooterImg = styled.img``;

const CocaColaFooter = props => <CocaColaFooterImg {...props} src={`${mediaPath}footer/cocacola.svg`} />;
const CocaColaFooterImg = styled.img``;

const AtlanticFooter = props => <AtlanticFooterImg {...props} src={`${mediaPath}footer/atlantic.svg`} />;
const AtlanticFooterImg = styled.img``;

const DMFooter = props => <DMFooterImg {...props} src={`${mediaPath}footer/dm.svg`} />;
const DMFooterImg = styled.img``;

const DohaFooter = props => <DohaFooterImg {...props} src={`${mediaPath}footer/doha.svg`} />;
const DohaFooterImg = styled.img``;

const MasterCardFooter = props => <MasterCardFooterImg {...props} src={`${mediaPath}footer/mastercard.svg`} />;
const MasterCardFooterImg = styled.img``;

const ASAFooter = props => <ASAFooterImg {...props} src={`${mediaPath}footer/asa.svg`} />;
const ASAFooterImg = styled.img``;

const EAMediaProgrammeFooter = props => <EAMediaProgrammeFooterImg {...props} src={`${mediaPath}footer/nestoEU.svg`} />;
const EAMediaProgrammeFooterImg = styled.img``;

const TRTFooter = props => <TRTFooterImg {...props} src={`${mediaPath}footer/trt.svg`} />;
const TRTFooterImg = styled.img`
  padding-bottom: 10px;
`;

const MinistarstvoFooter = props => (
  <MinistarstvoFooterImg {...props} src={`${mediaPath}footer/federalno-ministarstvo.svg`} />
);
const MinistarstvoFooterImg = styled.img``;

const VladaKSFooter = props => <VladaKSFooterImg {...props} src={`${mediaPath}footer/vlada-KS.svg`} />;
const VladaKSFooterImg = styled.img``;

const GradSarajevoFooter = props => <GradSarajevoFooterImg {...props} src={`${mediaPath}footer/grad-sarajevo.svg`} />;
const GradSarajevoFooterImg = styled.img``;

const VisitSarajevoFooter = props => (
  <VisitSarajevoFooterImg {...props} src={`${mediaPath}footer/visit-sarajevo.svg`} />
);
const VisitSarajevoFooterImg = styled.img``;

const FondacijaFooter = props => <FondacijaFooterImg {...props} src={`${mediaPath}footer/fondacija.svg`} />;
const FondacijaFooterImg = styled.img``;

const MistralLogo = props => <MistralLogoImg {...props} src={`${mediaPath}mistral.svg`} />;
const MistralLogoImg = styled.img`
  width: 73px;
`;
const HTECLogoImg = styled.img``;
const HTECLogo = props => <HTECLogoImg {...props} src={`${mediaPath}htec.svg`} />;

const UnescoFooter = props => <UnescoFooterImg {...props} src={`${mediaPath}footer/unesco.svg`} />;
const UnescoFooterImg = styled.img``;

const CineplexxFooter = props => <CineplexxFooterImg {...props} src={`${mediaPath}footer/cineplexx.svg`} />;
const CineplexxFooterImg = styled.img`
  padding-bottom: 10px;
`;

const MinOkolisFooter = props => <MinOkolisFooterImg {...props} src={`${mediaPath}footer/minioko.svg`} />;
const MinOkolisFooterImg = styled.img``;

export const FiArrowDown = styled(DefaultFiArrowDown)`
  margin-bottom: 3px;
`;

export {
  FilterIcon,
  SFFLogo,
  MistralLogo,
  RaiffeisenHeader,
  CocaColaHeader,
  BHTelecomHeader,
  UnicreditHeader,
  RaiffeisenFooter,
  UnicreditFooter,
  BHTelecomFooter,
  CocaColaFooter,
  AtlanticFooter,
  DMFooter,
  ASAFooter,
  DohaFooter,
  MasterCardFooter,
  EAMediaProgrammeFooter,
  TRTFooter,
  MinistarstvoFooter,
  VladaKSFooter,
  GradSarajevoFooter,
  VisitSarajevoFooter,
  FondacijaFooter,
  UniqaFooter,
  BingoFooter,
  HTECLogo,
  UnescoFooter,
  CineplexxFooter,
  MinOkolisFooter,
};
