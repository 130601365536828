import React, { createContext, useMemo, useContext, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLanguage } from 'redux/reducers/sessionReducer';

import useApi from 'utils/hooks/api/useApi';
import routes, { generateLink, matchRoute } from 'utils/routes';
import usePay from 'utils/hooks/api/usePay';
import { useDeleteCart, useGlobalDiscountStatus } from 'utils/hooks/api';
import { useCurrentWidth, useCurrentHeight, useToggle } from 'utils/hooks';
import { isNumber } from 'utils/helpers';
import { sessionActions } from 'redux/actions';
import { getCartItemsCount } from 'redux/reducers/sessionReducer';
import { CURRENCY, API_ENDPOINTS, HTTP_VERBS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const GRATIS = 'GRATIS';

const CartContext = createContext();

export const CartProvider = props => {
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [promoCode, setPromoCode] = useState('');
  const [{ isDiscountDisabled }] = useGlobalDiscountStatus();
  const [{ result: cart, loading: cartLoading, setResult }, getCart] = useApi(API_ENDPOINTS.CART);
  const [
    { result: paymentConfirmResult_0, loading: paymentConfirmLoading_0, error: paymentConfirmError_0 },
    confirmPayment,
  ] = useApi(API_ENDPOINTS.PAYMENT_CONFIRM, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });
  const [
    { result: promoCodeResult, loading: promoCodeLoading, error: promoCodeError, setError: setPromoCodeError },
    addPromoCode,
  ] = useApi(API_ENDPOINTS.PROMO_CODE(promoCode), { initialFetch: false, verb: HTTP_VERBS.PUT });
  const [{ resultPay, loadingPay: loadingPayment }, pay] = usePay();
  const [
    { result: apiClearCartResult, loading: apiClearCartLoading, setResult: setApiClearCartResult },
    apiClearCart,
  ] = useDeleteCart();
  const { resultGlobalSalesSettings } = useRoot();
  const history = useHistory();
  const dispatch = useDispatch();
  const width = useCurrentWidth();
  const height = useCurrentHeight();
  const currentCartItemsCount = useSelector(getCartItemsCount);
  const currentRoute = matchRoute(history.location.pathname);
  const language = useSelector(getLanguage);

  const goToFilmsList = useCallback(() => history.push(generateLink(routes.FILMS)), [history]);

  const goToScreeningsList = useCallback(() => history.push(generateLink(routes.SCREENINGS)), [history]);

  const clearCart = useCallback(() => setResult(null), [setResult]);

  const clearCartApi = useCallback(async () => {
    await apiClearCart();
    setIsModalOpen();
  }, [apiClearCart, setIsModalOpen]);

  const clearCartRequest = useCallback(() => {
    apiClearCart();
  }, [apiClearCart]);

  const handleTotalCartPrice = useCallback(
    () => (cart && isNumber(cart.totalWithDiscount) ? `${cart.totalWithDiscount.toFixed(2)} ${CURRENCY.BAM}` : 0),
    [cart]
  );

  const onSubmit = useCallback(() => {
    if (cart?.totalWithDiscount === 0) {
      confirmPayment();
    } else {
      pay();
    }
  }, [pay, cart, confirmPayment]);

  const handleCartItemsCount = useCallback(() => {
    let count = 0;

    if (cart && cart.cartScreenings.length) {
      cart.cartScreenings.forEach(c => {
        count += c.seatType.count;
      });
    }

    if (count !== currentCartItemsCount) dispatch(sessionActions.getCartItemsCount(count));
  }, [cart, currentCartItemsCount, dispatch]);

  const handleTotalScreeningPrice = useCallback((price, count) => {
    if (price === 0) return GRATIS;

    return isNumber(price) && isNumber(count) ? `${(price * count).toFixed(2)} ${CURRENCY.BAM}` : 0;
  }, []);

  const handlePromoCodeChange = useCallback(
    e => {
      if (promoCodeError) setPromoCodeError(null);

      setPromoCode(e.target.value);
    },
    [setPromoCode, promoCodeError, setPromoCodeError]
  );

  const addPromoCodeApi = useCallback(async () => {
    await addPromoCode();
    setPromoCode('');
    window.scrollTo(0, 0);
  }, [addPromoCode]);

  useEffect(() => {
    handleCartItemsCount();
  }, [handleCartItemsCount]);

  useEffect(() => {
    if (promoCodeResult) getCart();
  }, [promoCodeResult, getCart]);

  useEffect(() => {
    if (paymentConfirmResult_0) history.push(generateLink(routes.PAYMENT_SUCCESS));
    if (paymentConfirmError_0) history.push(generateLink(routes.PAYMENT_FAIL));
  }, [paymentConfirmResult_0, history, paymentConfirmError_0]);

  useEffect(() => {
    if (apiClearCartResult && currentRoute !== routes.PAYMENT_SUCCESS.path) {
      clearCart();
      dispatch(sessionActions.getCartItemsCount(0));
      setTimeout(() => setApiClearCartResult(null), 200);
    }
  }, [apiClearCartResult, setApiClearCartResult, goToScreeningsList, dispatch, clearCart, currentRoute]);

  useEffect(() => {
    getCart();
  }, [getCart, language]);

  const context = useMemo(
    () => ({
      isModalOpen,
      setIsModalOpen,
      width,
      height,
      cart,
      cartLoading,
      getCart,
      clearCart,
      apiClearCartLoading,
      clearCartApi,
      goToFilmsList,
      goToScreeningsList,
      handleTotalScreeningPrice,
      handleTotalCartPrice,
      handlePromoCodeChange,
      onSubmit,
      promoCode,
      promoCodeLoading,
      promoCodeError,
      addPromoCodeApi,
      loadingPayment,
      resultPay,
      apiClearCartResult,
      isDiscountDisabled,
      resultGlobalSalesSettings,
      clearCartRequest,
      paymentConfirmLoading_0,
    }),
    [
      isModalOpen,
      setIsModalOpen,
      width,
      height,
      cart,
      cartLoading,
      getCart,
      apiClearCartLoading,
      clearCartApi,
      clearCart,
      goToFilmsList,
      goToScreeningsList,
      handleTotalScreeningPrice,
      handleTotalCartPrice,
      handlePromoCodeChange,
      onSubmit,
      promoCode,
      promoCodeLoading,
      promoCodeError,
      addPromoCodeApi,
      loadingPayment,
      resultPay,
      apiClearCartResult,
      isDiscountDisabled,
      resultGlobalSalesSettings,
      clearCartRequest,
      paymentConfirmLoading_0,
    ]
  );

  return <CartContext.Provider value={context}>{props.children}</CartContext.Provider>;
};

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error(`useCartContext must be used within a CartProvider`);
  }
  return context;
};

export const Consumer = CartContext.Consumer;
