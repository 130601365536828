import React from 'react';
import { Pagination as ReactstrapPagination } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PaginationItem from 'components/Pagination/PaginationItem';

const Pagination = ({ page, maxPages, onPageChange }) => {
  const pages = [];

  for (let i = 1; i <= maxPages; i++) {
    pages.push(i);
  }

  const visiblePages = (numDisplayed, pageNumber) => {
    let upperLimit = page + Math.ceil(numDisplayed / 2);
    let lowerLimit = page - Math.ceil(numDisplayed / 2);
    if (lowerLimit < 0) upperLimit += Math.abs(lowerLimit);
    if (upperLimit > maxPages) {
      lowerLimit -= upperLimit - maxPages;
      lowerLimit++;
    }
    if ((lowerLimit < pageNumber && pageNumber <= page) || (pageNumber > page && pageNumber < upperLimit)) return true;
    else return false;
  };

  return (
    <Pager>
      <PaginationItem linkAttrs={{ previous: true }} onClick={onPageChange} number={page - 1} disabled={page === 1} />

      {pages.map((p, i) => (
        <PaginationItem number={p} onClick={onPageChange} key={i} active={p === page} visible={visiblePages(6, p)} />
      ))}

      <PaginationItem
        linkAttrs={{ next: true }}
        onClick={onPageChange}
        number={page + 1}
        disabled={page === maxPages}
      />
    </Pager>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  limit: PropTypes.number,
  onPageChange: PropTypes.func,
};

const Pager = styled(ReactstrapPagination)``;

export default Pagination;
