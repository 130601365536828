import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Carousel as ReactstrapCarousel,
  CarouselItem as DefaultCarouselItem,
  CarouselControl,
  CarouselIndicators as DefaultCarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

const Carousel = ({
  items,
  hasIndicators = true,
  hasControls = true,
  innerContentHeight,
  carouselItemHeight,
  indicatorsBottomOffset,
  ...rest
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item, index) => {
    return (
      <CarouselItem
        key={index}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        background={item.src}
      >
        <CarouselItemContent carouselItemHeight={carouselItemHeight} innerContentHeight={innerContentHeight}>
          {(item.caption || item.header) && <CarouselCaption captionText={item.caption} captionHeader={item.header} />}
        </CarouselItemContent>
      </CarouselItem>
    );
  });

  return (
    <ReactstrapCarousel
      ride={items.length > 1 ? 'carousel' : undefined}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...rest}
    >
      {hasIndicators && (
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          bottomOffset={indicatorsBottomOffset}
        />
      )}
      {slides}
      {hasControls && (
        <>
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
            // className="d-none d-xl-flex"
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
            // className="d-none d-xl-flex"
          />
        </>
      )}
    </ReactstrapCarousel>
  );
};

const CarouselItem = styled(({ bacgrkound, innerContentHeight, children, ...rest }) => (
  <DefaultCarouselItem {...rest}>{children}</DefaultCarouselItem>
))`
  width: 100%;
  ${({ background }) =>
    background &&
    css`
    background-position: center;
    background-size: cover;
    background-image: url('${background}');
    background-color: rgba(0,0,0,0.5);
    background-blend-mode: darken;
    `}
`;

const CarouselIndicators = styled(DefaultCarouselIndicators)`
  ${({ bottomOffset }) =>
    bottomOffset &&
    css`
      &.carousel-indicators {
        bottom: ${bottomOffset}px;
      }
    `}
  &.carousel-indicators li {
    width: 9px;
    height: 9px;
    background-color: var(--white);
    margin-right: 2px;
    margin-left: 2px;
    cursor: pointer;
    border-radius: 100%;
    opacity: 0.5;
  }
  &.carousel-indicators li:active,
  &.carousel-indicators li.active {
    opacity: 1;
  }
`;
const CarouselItemContent = styled.div`
  ${({ innerContentHeight, carouselItemHeight }) =>
    innerContentHeight && carouselItemHeight && innerContentHeight > carouselItemHeight
      ? css`
          height: ${innerContentHeight + 'px'};
        `
      : css`
          height: ${carouselItemHeight + 'px'};
        `}
`;
export default Carousel;
