import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS } from 'utils/constants';

const useScreening = id => {
  const path = `${API_ENDPOINTS.SCREENINGS}/${id}`;
  const language = useSelector(getLanguage);
  const [{ result: screening, loading, error }, getScreening] = useApi(path, { initialFetch: false });

  useEffect(() => {
    if (id) {
      getScreening();
    }
  }, [id, getScreening, language]);

  return [{ screening, loading, error }, getScreening];
};

export default useScreening;
