import styled, { css } from 'styled-components';

import { DEFAULT_POSTER } from 'utils/constants';

const FilmPoster = styled.div`
  background-color: var(--gray);
  ${({ size }) => {
    switch (size) {
      case 'xs':
        return css`
          width: 75px;
          height: 99px;
        `;
      case 'small':
        return css`
          width: 162px;
          height: 240px;
        `;
      case 'medium':
        return css`
          width: 213px;
          height: 315px;
        `;
      case 'large':
        return css`
          width: 353px;
          height: 521px;
        `;
      default:
        return css`
          width: 152px;
          height: 224px;
        `;
    }
  }};
  ${({ poster }) =>
    poster
      ? css`
          background-image: url('${poster}');
        `
      : css`
          background-image: url('${DEFAULT_POSTER}');
        `}
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export default FilmPoster;
