import React from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const ScreeningBasicInfo = ({ screening, ...rest }) => {
  const { t: tPurchase } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { title = '-', startTime = '', location = { name: '-' }, programs = [] } = screening || {};
  const handleDateAndStartTime = date =>
    date
      ? tPurchase('screening_start_time', {
          startDate: moment(date).format('DD. MMMM YYYY'),
          startTime: moment(date).format('HH:mm'),
        })
      : '-';
  return (
    <div {...rest}>
      {title && <ScreeningTitle title={title} />}
      {startTime && <ScreeningDateAndTime dateAndTime={handleDateAndStartTime(startTime)} />}
      {location && location.name && <ScreeningLocation location={location.name} />}
      {programs && programs.length > 0 && <ScreeningPrograms programs={programs.map(p => p.name).join(' | ')} />}
    </div>
  );
};

const ScreeningTitle = ({ title }) => (
  <Text className="mb-2" fontSize="1.2rem" fontWeight="500" lineHeight="1.31">
    {title}
  </Text>
);

const ScreeningDateAndTime = ({ dateAndTime }) => (
  <Text className="mb-lg-1" fontWeight="500" lineHeight="1.36">
    {dateAndTime}
  </Text>
);

const ScreeningLocation = ({ location }) => (
  <Text className="mb-1" fontSize="0.75rem" fontWeight="500" lineHeight="1.58" color="grey">
    {location}
  </Text>
);

const ScreeningPrograms = styled(({ programs, ...rest }) => (
  <Text className="mt-2" fontSize="0.75rem" fontWeight="500" lineHeight="1.58" color="grey" {...rest}>
    {programs}
  </Text>
))`
  max-width: 270px;
`;

export default ScreeningBasicInfo;
