import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS } from 'utils/constants';

const useSeatAvailability = id => {
  const [
    { result: seats, loading: loadingSeatAvailability, error: errorSeatAvailability, setResult: setSeats },
    getSeatAvailability,
  ] = useApi(API_ENDPOINTS.SEAT_AVAILABILITY(id), {
    initialFetch: false,
  });

  return [{ seats, loadingSeatAvailability, errorSeatAvailability, setSeats }, getSeatAvailability];
};

export default useSeatAvailability;
