import styled from 'styled-components';
import DefaultLabel from 'components/Typography/Label';

const Label = styled(DefaultLabel)`
  font-size: 12px;
  font-weight: bold;
  line-height: 2.5;
  color: var(--defaultText);
  text-transform: none;
  margin-bottom: 0.5rem;
`;

export default Label;
