import React from 'react';
import { Formik } from 'formik';

import DistributedPurchaseForm from './DistributedPurchaseForm';
import CustomerAccreditation from '../CustomerAccreditation';
import { PurchaseModal } from 'components';
import { useDistributedPurchaseContext } from 'context/DistributedPurchaseContext';
import { TICKET_TYPE_SELECTORS } from 'utils/constants';

const DistributedPurchase = () => {
  const {
    width,
    selectedScreening,
    ticketTypeSelector,
    setTicketTypeSelector,
    initialValues,
    isModalOpen,
    toggleModal,
    handleCountAndPrice,
    isLimitReached,
    accreditationNumber,
    accreditation,
    accreditationLoading,
    apiScreeningLoading,
    seatAvailabilityLoading,
    accreditationError,
    toAccTicketSelection,
    handleAccreditationChange,
    onConfirmAccreditation,
    addToCartLoading,
    onBack,
    onConfirm,
    clearValues,
  } = useDistributedPurchaseContext();

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {({ values, resetForm }) => (
        <PurchaseModal
          width={width}
          isOpen={isModalOpen}
          values={values}
          screening={selectedScreening}
          ticketTypeSelector={ticketTypeSelector}
          setTicketTypeSelector={setTicketTypeSelector}
          isLimitReached={isLimitReached}
          accreditation={accreditation}
          toAccTicketSelection={toAccTicketSelection}
          loading={addToCartLoading}
          fetchLoading={apiScreeningLoading || seatAvailabilityLoading || accreditationLoading}
          ticketsCountAndPrice={handleCountAndPrice(values)}
          onConfirm={() => onConfirm(values)}
          toggle={() => {
            toggleModal();
            resetForm();
            setTimeout(() => clearValues(), 200);
          }}
          onBack={() => {
            onBack();
            resetForm();
          }}
        >
          {ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR ||
          (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && accreditation && toAccTicketSelection) ? (
            <DistributedPurchaseForm values={values} accreditation={accreditation} />
          ) : (
            <CustomerAccreditation
              onChange={handleAccreditationChange}
              accreditationNumber={accreditationNumber}
              accreditation={accreditation}
              error={accreditationError}
              loading={accreditationLoading}
              onClick={onConfirmAccreditation}
            />
          )}
        </PurchaseModal>
      )}
    </Formik>
  );
};

export default DistributedPurchase;
