import React from 'react';
import styled, { css } from 'styled-components';
import { Table as ReactstrapTable } from 'reactstrap';

import HeadCell from './HeadCell';
import { Label } from 'components';

const Table = ({ label, children, columns, areScreenings }) => {
  return (
    <Wrapper areScreenings={areScreenings}>
      {label && (
        <LabelWrapper>
          <Label className="pl-4 d-inline align-top h-100" fontWeight="bold" fontSize="0.75rem">
            {label}
          </Label>
        </LabelWrapper>
      )}
      <TableWrapper areScreenings={areScreenings}>
        {columns && (
          <thead>
            <HeadTR>
              {columns.map((c, i) => {
                return <HeadCell column={c} key={i} />;
              })}
            </HeadTR>
          </thead>
        )}
        <tbody>{children}</tbody>
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    height: 100%;
    width: 100%;
    ${({ areScreenings }) =>
      areScreenings &&
      css`
        padding: 0 2rem;
      `}
  }
`;

const LabelWrapper = styled.div`
  height: 2rem;
`;

const HeadTR = styled.tr`
  border: none !important;
`;

const TableWrapper = styled(({ areScreenings, children, ...rest }) => (
  <ReactstrapTable {...rest}>{children}</ReactstrapTable>
))`
  && tr:first-child {
    border-top: 2px solid var(--border);
  }

  && tr {
    border-bottom: 2px solid var(--border);
  }

  && td {
    padding: 1rem 1.5rem;
    color: var(--textGrey);
    font-size: 0.875rem;
    font-weight: 500;
    border-top: none;
  }

  ${({ areScreenings }) =>
    areScreenings &&
    css`
      && tr:first-child {
        border-top: 2px solid var(--border);
      }

      && td:last-child,
      td:nth-last-child(2) {
        text-align: right;
      }

      && tr:hover {
        background-color: rgba(245, 245, 245, 0.57);
        cursor: pointer;
      }
    `}
`;

export default Table;
