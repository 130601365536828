import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';
// import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
// import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

// import { useRoot } from 'context/RootContext';

const useCountries = () => {
  const path = API_ENDPOINTS.COUNTRIES;
  // const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  // const { showNotification } = useRoot();
  const [{ result: countries, loading: loadingCountries, error: errorCountries }, getCountries] = useApi(path, {
    initialFetch: false,
  });
  const language = useSelector(getLanguage);

  useEffect(() => {
    getCountries();
  }, [getCountries, language]);

  // useEffect(() => {
  //   if (errorCountries) {
  //     showNotification(
  //       {
  //         message: t('Countries_fetch_fail'),
  //       },
  //       ALERT_VARIANTS.DANGER
  //     );
  //   }
  // }, [errorCountries, showNotification, t]);

  return [{ countries, loadingCountries, errorCountries }, getCountries];
};

export default useCountries;
