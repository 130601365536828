import { useEffect, useState } from 'react';

const usePath = queryString => {
  const [path, setPath] = useState(queryString);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  return path;
};

export default usePath;
