import React, { createContext, useMemo, useContext, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';

import useApi from 'utils/hooks/api/useApi';
import routes, { generateLink } from 'utils/routes';
import { useCartContext } from 'context/CartContext';
import { useAddToCartDistributed } from 'utils/hooks/api';
import { useToggle, useCurrentWidth } from 'utils/hooks';
import { isNumber } from 'utils/helpers';
import { API_ENDPOINTS, SCREEN_BREAKPOINTS, MAX_TICKETS_PURCHASE_LIMIT, TICKET_TYPE_SELECTORS } from 'utils/constants';

const DistributedPurchaseContext = createContext();

export const DistributedPurchaseProvider = props => {
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [selectedScreening, setSelectedScreening] = useState(null);
  const [ticketTypeSelector, setTicketTypeSelector] = useState(null);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [accreditationNumber, setAccreditationNumber] = useState('');
  const [toAccTicketSelection, setToAccTicketSelection] = useState(false);
  const [cartItems, setCartItems] = useState({});
  const [screeningsCart, setScreeningsCart] = useState([]);
  const [initialValues, setInitialValues] = useImmer({
    cartId: null,
    screeningId: null,
    ticketInserts: [],
  });
  const [{ result: apiScreening, loading: apiScreeningLoading }, getScreening] = useApi(
    API_ENDPOINTS.SCREENING(selectedScreening?.id || selectedScreening?.screeningId),
    {
      initialFetch: false,
    }
  );
  const [
    { result: seatAvailability, loading: seatAvailabilityLoading, setResult: setSeatAvailabilityResult },
    getSeats,
  ] = useApi(API_ENDPOINTS.SEAT_AVAILABILITY(selectedScreening?.id || selectedScreening?.screeningId), {
    initialFetch: false,
  });
  const [
    {
      result: accreditation,
      loading: accreditationLoading,
      error: accreditationError,
      setResult: setAccreditation,
      setError: setAccreditationError,
    },
    getAccreditation,
  ] = useApi(
    API_ENDPOINTS.CUSTOMER_ACCREDITATION(accreditationNumber, selectedScreening?.id || selectedScreening?.screeningId),
    {
      initialFetch: false,
    }
  );
  const [
    { result: addToCartResult, loading: addToCartLoading, error: addToCartError, setResult: setAddToCartResult },
    addToCart,
  ] = useAddToCartDistributed();
  const { cart, getCart } = useCartContext();
  const history = useHistory();
  const width = useCurrentWidth();

  const goToCart = useCallback(() => history.push(generateLink(routes.CART)), [history]);

  const fetchData = useCallback(async () => {
    await getScreening();
    await getSeats();
  }, [getScreening, getSeats]);

  const clearValues = useCallback(() => {
    setSeatAvailabilityResult(null);
    setCartItems({});

    setInitialValues(draft => {
      draft.cartId = null;
      draft.screeningId = null;
      draft.ticketInserts = [];
    });
  }, [setInitialValues, setSeatAvailabilityResult]);

  const toggleModal = useCallback(
    (screening, ticketTypeSelector) => {
      setIsModalOpen();

      if (!selectedScreening) {
        setSelectedScreening(screening);
      } else setTimeout(() => setSelectedScreening(null), 300);

      if (addToCartResult) setAddToCartResult(null);
      if (isLimitReached) setIsLimitReached(false);
      if (accreditationError) setAccreditationError(null);
      if (!accreditation && toAccTicketSelection) setTimeout(() => setToAccTicketSelection(false), 200);

      if (ticketTypeSelector) {
        setTicketTypeSelector(ticketTypeSelector);
      } else setTimeout(() => setTicketTypeSelector(null), 200);
    },
    [
      setIsModalOpen,
      selectedScreening,
      accreditationError,
      setAccreditationError,
      accreditation,
      toAccTicketSelection,
      isLimitReached,
      setIsLimitReached,
      addToCartResult,
      setAddToCartResult,
    ]
  );

  const onBack = useCallback(() => {
    if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && toAccTicketSelection) {
      setToAccTicketSelection(false);
      setAccreditation(null);
      setAccreditationNumber('');
      setScreeningsCart(screeningsCart.filter(c => c.seatType.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.REGULAR));
    } else {
      if (width <= SCREEN_BREAKPOINTS.XL && !ticketTypeSelector) {
        toggleModal();
        clearValues();
      }
      if (accreditationError) setAccreditationError(null);

      setTicketTypeSelector(null);
    }

    if (isLimitReached) setIsLimitReached(false);
  }, [
    clearValues,
    width,
    ticketTypeSelector,
    toggleModal,
    setTicketTypeSelector,
    isLimitReached,
    toAccTicketSelection,
    setAccreditation,
    accreditationError,
    setAccreditationError,
    screeningsCart,
  ]);

  const onConfirm = useCallback(
    async values => {
      if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && !toAccTicketSelection) {
        setToAccTicketSelection(true);
      } else {
        await addToCart(values);
        toggleModal();
        await getCart();
      }
    },
    [addToCart, toggleModal, getCart, ticketTypeSelector, toAccTicketSelection]
  );

  const handleAccreditationChange = useCallback(
    e => {
      if (accreditationError) setAccreditationError(null);

      setAccreditationNumber(e.target.value);
    },
    [accreditationError, setAccreditationError]
  );

  const onConfirmAccreditation = useCallback(() => {
    if (accreditation) setAccreditation(null);

    getAccreditation();
  }, [accreditation, setAccreditation, getAccreditation]);

  const handleScreeningsCart = useCallback(
    id => {
      let cartItems = cart && cart.cartScreenings && cart.cartScreenings.filter(c => c.screeningId === id);

      if (cartItems) setScreeningsCart(cartItems);
      if (!cartItems) setScreeningsCart([]);
    },
    [cart]
  );

  const handleCartItems = useCallback(() => {
    let screenings = {};

    let cartItems = screeningsCart.filter(s => s.seatType.ticketTypeSelectorId === ticketTypeSelector);

    if (cartItems && cartItems.length > 0) {
      cartItems.forEach(v => {
        screenings[v.screeningId] = !screenings[v.screeningId] ? [] : screenings[v.screeningId];
        screenings[v.screeningId].push({ seatTypeId: v.seatType.seatTypeId, count: v.seatType.count });
      });
    }

    setCartItems(screenings);
  }, [ticketTypeSelector, screeningsCart]);

  const handleCountAndPrice = useCallback(
    values => {
      let count = 0;
      let price = 0;

      if (values && values?.ticketInserts.length) {
        let tickets = values.ticketInserts.filter(v => v.ticketTypeSelectorId === ticketTypeSelector);

        if (tickets)
          tickets.forEach(insert => {
            if (insert && insert.ticketSeatTypes)
              insert.ticketSeatTypes.forEach(ticketSeatType => {
                if (isNumber(ticketSeatType.quantity) && ticketSeatType.quantity > 0) {
                  count += ticketSeatType.quantity;
                  price += ticketSeatType.price * ticketSeatType.quantity;
                }
              });
          });
      }

      return [count, price];
    },
    [ticketTypeSelector]
  );

  const totalRemainingSeatsHandler = useCallback(
    (totalSeats, totalSoldSeats, seatTypeId) => {
      if (
        ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR &&
        cartItems[selectedScreening.id || selectedScreening.screeningId]
      ) {
        let seatType = cartItems[selectedScreening.id || selectedScreening.screeningId].find(
          c => c.seatTypeId === seatTypeId
        );

        return seatType ? seatType.count + totalSeats - totalSoldSeats : totalSeats - totalSoldSeats;
      }

      return totalSeats - totalSoldSeats;
    },
    [cartItems, selectedScreening, ticketTypeSelector]
  );

  const handleIsLimitReached = useCallback(
    values => {
      if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && accreditation) {
        const {
          ticketsUsedForScreening,
          ticketsUsedForDay,
          ticketsLimitDaily,
          ticketsLimitPerScreening,
        } = accreditation;
        let limit = 0;
        let count = 0;
        let accCount = 0;

        if (ticketsLimitPerScreening - ticketsUsedForScreening < ticketsLimitDaily - ticketsUsedForDay) {
          limit = ticketsLimitPerScreening - ticketsUsedForScreening;
        } else {
          limit = ticketsLimitDaily - ticketsUsedForDay;
        }

        if (screeningsCart.length > 0) {
          let screening = screeningsCart.filter(s => s.seatType.accreditationNumber === accreditationNumber);

          if (screening) {
            screening.forEach(s => {
              accCount += s?.seatType?.count;
            });
          }
        }

        values.forEach(v => {
          count += v.quantity;
        });

        limit += accCount;
        if (count >= MAX_TICKETS_PURCHASE_LIMIT && !isLimitReached) setIsLimitReached(true);
        if (count < MAX_TICKETS_PURCHASE_LIMIT) {
          if (count - accCount >= limit - accCount && !isLimitReached) setIsLimitReached(true);

          if (count - accCount < limit - accCount && isLimitReached) setIsLimitReached(false);
        }
      }

      if (ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR) {
        let count = 0;

        values.forEach(v => {
          count += v.quantity;
        });

        if (count >= MAX_TICKETS_PURCHASE_LIMIT && !isLimitReached) setIsLimitReached(true);
        if (count < MAX_TICKETS_PURCHASE_LIMIT && isLimitReached) setIsLimitReached(false);
      }
    },
    [isLimitReached, setIsLimitReached, accreditation, ticketTypeSelector, accreditationNumber, screeningsCart]
  );

  const handleInitialValues = useCallback(
    (id, screeningTicketTypeSelectors) => {
      setInitialValues(draft => {
        draft.screeningId = id;
        draft.ticketInserts = screeningTicketTypeSelectors
          .filter(tts => tts.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.REGULAR)
          .map(tts => ({
            totalAvailableSeats: tts.totalAvailable - tts.totalNonAvailable,
            totalSeats: tts.totalAvailable,
            ticketTypeSelectorId: tts.ticketTypeSelectorId,
            ticketSeatTypes: tts.screeningSelectorSeatTypes
              .filter(sts => sts.totalSeats > 0)
              .map(sts => {
                let screening = null;

                if (screeningsCart && screeningsCart.length > 0)
                  screening = screeningsCart
                    .filter(c => c.seatType.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.REGULAR)
                    .find(c => c.seatType.seatTypeId === sts.seatTypeId);

                return {
                  seatTypeId: sts?.seatTypeId,
                  seatTypeName: sts?.seatTypeName,
                  totalSeats: sts?.totalSeats,
                  totalSoldSeats: sts?.totalSoldSeats,
                  price: sts?.price,
                  quantity: screening ? screening?.seatType?.count : 0,
                };
              }),
          }));
      });

      if (accreditation) {
        setInitialValues(draft => {
          draft.screeningId = id;
          draft.ticketInserts = [
            ...draft.ticketInserts,
            {
              ticketTypeSelectorId: TICKET_TYPE_SELECTORS.ACCREDITATION,
              ticketSeatTypes: accreditation?.screeningSelectorSeatTypes
                .filter(sst => sst.totalSeats > 0)
                .map(sts => {
                  let screening = null;

                  if (screeningsCart && screeningsCart.length > 0)
                    screening = screeningsCart
                      .filter(c => c.seatType.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION)
                      .find(c => c.seatType.seatTypeId === sts.seatTypeId);

                  return {
                    accreditationId: accreditation.id,
                    seatTypeId: sts?.seatTypeId,
                    seatTypeName: sts?.seatTypeName,
                    totalSeats: sts?.totalSeats,
                    totalSoldSeats: sts?.totalSoldSeats,
                    price: 0,
                    quantity: screening ? screening?.seatType?.count : 0,
                  };
                }),
            },
          ];
        });
      }
    },
    [setInitialValues, accreditation, screeningsCart]
  );

  useEffect(() => {
    if (selectedScreening) fetchData();
  }, [selectedScreening, fetchData]);

  useEffect(() => {
    if (selectedScreening) handleScreeningsCart(selectedScreening.id || selectedScreening.screeningId);
  }, [handleScreeningsCart, selectedScreening]);

  useEffect(() => {
    if (cart && cart.cartScreenings && ticketTypeSelector) handleCartItems();
  }, [cart, ticketTypeSelector, handleCartItems]);

  useEffect(() => {
    if (addToCartResult && !addToCartError) setTimeout(() => goToCart(), 400);
  }, [addToCartResult, goToCart, addToCartError]);

  useEffect(() => {
    if (accreditationNumber && toAccTicketSelection && selectedScreening) getAccreditation();
  }, [selectedScreening, accreditationNumber, getAccreditation, toAccTicketSelection]);

  useEffect(() => {
    if (selectedScreening?.seatType?.accreditationNumber) {
      setAccreditationNumber(selectedScreening.seatType.accreditationNumber);
      setToAccTicketSelection(true);
    }
  }, [selectedScreening]);

  useEffect(() => {
    if (selectedScreening && apiScreening && seatAvailability?.distributedSeats?.screeningTicketTypeSelectors)
      handleInitialValues(
        selectedScreening.id || selectedScreening.screeningId,
        seatAvailability.distributedSeats.screeningTicketTypeSelectors
      );
  }, [selectedScreening, apiScreening, seatAvailability, handleInitialValues]);

  const context = useMemo(
    () => ({
      width,
      isModalOpen,
      selectedScreening,
      apiScreening,
      apiScreeningLoading,
      seatAvailabilityLoading,
      initialValues,
      isLimitReached,
      ticketTypeSelector,
      getAccreditation,
      accreditationNumber,
      accreditation,
      accreditationLoading,
      accreditationError,
      toAccTicketSelection,
      addToCartLoading,
      totalRemainingSeatsHandler,
      toggleModal,
      setTicketTypeSelector,
      handleCountAndPrice,
      handleIsLimitReached,
      handleAccreditationChange,
      onConfirmAccreditation,
      onBack,
      onConfirm,
      clearValues,
    }),
    [
      width,
      isModalOpen,
      selectedScreening,
      apiScreening,
      apiScreeningLoading,
      seatAvailabilityLoading,
      initialValues,
      isLimitReached,
      ticketTypeSelector,
      getAccreditation,
      accreditationNumber,
      accreditation,
      accreditationLoading,
      accreditationError,
      toAccTicketSelection,
      addToCartLoading,
      totalRemainingSeatsHandler,
      toggleModal,
      setTicketTypeSelector,
      handleCountAndPrice,
      handleIsLimitReached,
      handleAccreditationChange,
      onConfirmAccreditation,
      onBack,
      onConfirm,
      clearValues,
    ]
  );

  return <DistributedPurchaseContext.Provider value={context}>{props.children}</DistributedPurchaseContext.Provider>;
};

export const useDistributedPurchaseContext = () => {
  const context = useContext(DistributedPurchaseContext);
  if (!context) {
    throw new Error(`useDistributedPurchaseContext must be used within a DistributedPurchaseProvider`);
  }
  return context;
};

export const Consumer = DistributedPurchaseContext.Consumer;
