import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input as ReactstrapInput } from 'reactstrap';
import styled from 'styled-components';

import { ErrorMessage, PasswordToggle } from 'components';
import Label from './InputLabel';

const Input = ({ width = 'auto', label = '', validationMessage, togglePassword, type = 'text', ...props }) => {
  const [inputType, setInputType] = useState(type);

  return (
    <InputWrapper>
      {label && (
        <Label textTransform="none" lineHeight="2.5" fontWeight="500">
          {label}
        </Label>
      )}
      <InputComponent width={width} {...props} type={inputType} />
      {togglePassword && <PasswordToggle type={inputType} setInputType={setInputType} />}
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </InputWrapper>
  );
};

Input.propTypes = {
  width: PropTypes.string || PropTypes.number,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  togglePassword: PropTypes.bool,
};

const InputWrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const InputComponent = styled(ReactstrapInput)`
  && {
    width: 100%;
    height: 50px;
    border: solid 2px #f5f5f5;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 4.17;
    border-radius: 4px;

    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--textGrey);
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
    ${`@-moz-document url-prefix()`} {
      &[type='date'] {
        line-height: 1.5;
      }
    }
  }
`;

export default Input;
