import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useApi from 'utils/hooks/api/useApi';
import { matchRoute } from 'utils/routes';
import { SALE_TYPES } from 'utils/constants';

const path = id => `external/settings/sales/${id}`;
const useGlobalSalesSettings = (id = SALE_TYPES.ONLINE.id) => {
  const [
    { result: resultGlobalSalesSettings, loading: loadingGlobalSalesSettings, error },
    getScreeningGlobalSalesSettings,
  ] = useApi(path(id), { initialFetch: false });
  const history = useHistory();
  const currentRoute = matchRoute(history.location.pathname);

  useEffect(() => {
    getScreeningGlobalSalesSettings();
  }, [getScreeningGlobalSalesSettings, currentRoute]);

  return [{ resultGlobalSalesSettings, loadingGlobalSalesSettings, error }, getScreeningGlobalSalesSettings];
};

export default useGlobalSalesSettings;
