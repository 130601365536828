import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

import useApi from 'utils/hooks/api/useApi';
import { usePath } from 'utils/hooks';
import moment from 'moment';

const useFilmScreenings = (query, id) => {
  const queryString = `screenings/screening-film/${id}/basic?&Term=${query.term}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}&StartDate=${query.startDate}`;
  const path = usePath(queryString);
  const [{ result, loading: loadingFilmScreenings, error: errorFilmScreenings }, getFilmScreenings] = useApi(path, {
    initialFetch: false,
  });
  const language = useSelector(getLanguage);
  const [filmScreenings, setFilmScreenings] = useState(null);

  useEffect(() => {
    getFilmScreenings();
  }, [getFilmScreenings, language]);

  useEffect(() => {
    if (result && !loadingFilmScreenings) {
      setFilmScreenings({
        ...result,
        data: result.data.sort((a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf()),
      });
    }
  }, [result, loadingFilmScreenings, setFilmScreenings]);

  return [{ filmScreenings, loadingFilmScreenings, errorFilmScreenings }, getFilmScreenings];
};

export default useFilmScreenings;
