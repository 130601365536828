import React, { useState } from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';

import Seat from 'components/Seating/Seat';
import { CenterContent } from 'components';

const Seats = ({ colLabels, rowLabels, layout, seatTypes, onSeatClick }) => {
  const [currentSeat, setCurrentSeat] = useState(null); // used to display only one popover (seat types) at time

  return (
    <>
      <SeatRow className="flex-nowrap">
        {colLabels &&
          colLabels.map(cl => {
            return (
              <Cell key={cl}>
                <CenterContent>{cl}</CenterContent>
              </Cell>
            );
          })}
      </SeatRow>
      {layout.map((row, i) => {
        return (
          <React.Fragment key={i}>
            <SeatRow className="flex-nowrap">
              <Cell>
                <CenterContent>{rowLabels && rowLabels[i] ? rowLabels[i] : '-'}</CenterContent>
              </Cell>
              {row.map((cell, j) => {
                return (
                  <Seat
                    key={`${cell.label}-${cell.id}-${j}`}
                    id={cell.label}
                    label={cell.label}
                    setCurrentSeat={setCurrentSeat}
                    onSeatClick={onSeatClick}
                    disableSeat={currentSeat !== cell.label}
                    x={i}
                    y={j}
                    layout={layout}
                    seatTypes={seatTypes}
                  />
                );
              })}
            </SeatRow>
          </React.Fragment>
        );
      })}
    </>
  );
};

const SeatRow = styled(Row)`
  margin-bottom: 1rem;
`;

const Cell = styled.div`
  min-width: 44px;
  min-height: 40px;
  /* margin-right: 5px; */
  /* margin-right: 0.3rem; */
  margin-right: 1rem;
  text-transform: uppercase;
`;

export default Seats;
