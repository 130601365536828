import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS, API_ENDPOINTS } from 'utils/constants';

const useSignIn = () => {
  const [{ result: resultSignIn, loading: loadingSignIn, error: errorSignIn }, signIn] = useApi(
    API_ENDPOINTS.AUTH_TOKEN,
    {
      initialFetch: false,
      verb: HTTP_VERBS.POST,
    }
  );
  return [{ resultSignIn, loadingSignIn, errorSignIn }, signIn];
};

export default useSignIn;
