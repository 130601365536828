import axios from 'axios';
import * as TokenService from 'utils/tokenService';

import routes from 'utils/routes';
import { HTTP_CODES } from 'utils/constants';
import { getDefaultLanguage, getCurrentLanguageFromStorage } from 'utils/localization/localization';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const setupApiConfig = token => {
  if (!token) token = TokenService.getToken();

  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
};

export const setupLangHeader = language => {
  const preferredLanguage = language || getCurrentLanguageFromStorage();

  if (preferredLanguage) {
    instance.defaults.headers.common['Content-Language'] = preferredLanguage.ISO_CODE;
  } else {
    const defaultLanguage = getDefaultLanguage();
    instance.defaults.headers.common['Content-Language'] = defaultLanguage.ISO_CODE;
  }
};

export const removeAuthorization = () => {
  delete instance.defaults.headers.common['Authorization'];
};

export const setupInterceptors = () => {
  instance.interceptors.request.use(
    config => {
      const preferredLanguage = getCurrentLanguageFromStorage();

      config.headers.common['Content-Language'] = preferredLanguage.ISO_CODE;

      return config;
    },
    error => {
      if (error.response && error.response.status === HTTP_CODES.UNAUTHORIZED) {
        TokenService.removeTokens();
        window.location.replace(routes.LOGIN.path);
      }
      return Promise.reject(error.response);
    }
  );

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === HTTP_CODES.UNAUTHORIZED) {
        TokenService.removeTokens();
        if (window.location.pathname !== routes.LOGIN.path) {
          window.location.href = routes.LOGIN.path;
        }
      }
      return Promise.reject(error.response);
    }
  );
};

export const getData = response => {
  return response.data;
};

export default instance;
