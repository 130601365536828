import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

import { useRoot } from 'context/RootContext';

const useGlobalDiscountStatus = () => {
  const path = API_ENDPOINTS.CART;
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const { showNotification } = useRoot();
  const [
    { result: isDiscountDisabled, loading: loadingDiscountDisabled, error: errorDiscountDisabled },
    getIsDiscountDisabled,
  ] = useApi(`${path}/discountstatus/global`);

  useEffect(() => {
    if (errorDiscountDisabled) {
      showNotification(
        {
          message: t('global_discount_status_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorDiscountDisabled, showNotification, t]);

  return [{ isDiscountDisabled, loadingDiscountDisabled, errorDiscountDisabled }, getIsDiscountDisabled];
};

export default useGlobalDiscountStatus;
