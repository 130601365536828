import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

// import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
// import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

// import { useRoot } from 'context/RootContext';

const useFilmsPrograms = () => {
  const path = `${API_ENDPOINTS.FILMS}/programs`;
  // const { t } = useTranslation(LOCALIZATION_PAGES.FILMS);
  // const { showNotification } = useRoot();
  const [{ result: programs, loading: loadingPrograms, error: errorPrograms }, getPrograms] = useApi(path, {
    initialFetch: false,
  });

  const language = useSelector(getLanguage);

  useEffect(() => {
    getPrograms();
  }, [getPrograms, language]);

  // useEffect(() => {
  //   if (errorPrograms) {
  //     showNotification(
  //       {
  //         message: t('film_programs_fetch_fail'),
  //       },
  //       ALERT_VARIANTS.DANGER
  //     );
  //   }
  // }, [errorPrograms, showNotification, t]);

  return [{ programs, loadingPrograms, errorPrograms }, getPrograms];
};

export default useFilmsPrograms;
