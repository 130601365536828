import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation, getInitialProps } from 'react-i18next';
// import { TiArrowRight, TiArrowLeft } from 'react-icons/ti';
import { Container, Row, Col } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ba from 'date-fns/locale/sr-Latn';

import { LOCALIZATION_PAGES } from 'utils/constants';
import { useToggle } from 'utils/hooks';

import DefaultModal from 'components/Modal/Modal';
import PortalDatePicker from './PortalDatePicker';
import moment from 'moment';

const DatePickerModal = ({ isOpen, onConfirm, onDecline, title, selectedDate = new Date(), highlightedDates }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const [startDate, setStartDate] = useState(null);
  const [festivalStartDate, setFestivalStartDate] = useState(null);
  const language = getInitialProps().initialLanguage;
  registerLocale('ba', ba);

  useEffect(() => {
    setStartDate(moment(selectedDate).toDate());
  }, [selectedDate]);

  useEffect(() => {
    setFestivalStartDate(highlightedDates[0]);
  }, [setFestivalStartDate, highlightedDates]);

  //? Library workaround
  //? There is no method to revert back to current month after clicking today/reset buttons
  //? Setting updateOnChange to false after these events
  //? useEffect sets it to true to rerender the Datepicker
  //////////////////////////////////////////////////////
  const [updateOnChange, toggleChange] = useToggle(true);

  useEffect(() => {
    if (!updateOnChange) {
      toggleChange();
    }
  }, [toggleChange, updateOnChange]);
  //////////////////////////////////////////////////////
  //? Workaround code above this line

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={onDecline}
      confirmText={t('confirm')}
      declineText={t('cancel')}
      onConfirm={() => onConfirm(startDate)}
      onDecline={onDecline}
      justifyFooter={'center'}
      customSize={410}
      title={t('select_screening_date')}
    >
      <Container fluid className="flex-grow-1 d-flex justify-content-center px-0">
        <Row noGutters>
          <Col sm={12} className="d-flex align-items-center justify-content-center flex-column">
            <div className="d-flex">
              {updateOnChange ? (
                <PortalDatePicker>
                  <DatePicker
                    inline
                    fixedHeight
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    // renderCustomHeader={DatePickerHeader}
                    minDate={moment(festivalStartDate)
                      .subtract(1, 'years')
                      .toDate()}
                    maxDate={moment(highlightedDates[highlightedDates.length - 1])
                      .add(1, 'years')
                      .toDate()}
                    onChange={date => setStartDate(date)}
                    startDate={startDate}
                    disabledKeyboardNavigation
                    selected={startDate}
                    highlightDates={highlightedDates}
                    locale={language === t('ba') && ba}
                  />
                </PortalDatePicker>
              ) : (
                <DatePickerPlaceholder />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const DatePickerPlaceholder = styled.div`
  height: 484px;
`;

const Modal = styled(DefaultModal)`
  & .modal-header {
    padding: 2rem 2.25rem;
  }
  & .modal-body {
    padding-right: 0;
    padding-left: 0;
  }
  & .modal-footer {
    padding: 2rem 2.25rem;
  }
`;

export default DatePickerModal;
