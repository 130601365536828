import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = props => <Wrapper {...props}>{props.children}</Wrapper>;

const Wrapper = styled(
  ({
    color,
    margin,
    fontSize,
    fontFamily,
    fontWeight,
    textTransform,
    fontStretch,
    lineHeight,
    letterSpacing,
    children,
    ...rest
  }) => <h2 {...rest}>{children}</h2>
)`
  color: ${({ color }) => (color ? `var(--${color})` : 'var(--defaultText)')};
  margin: ${({ margin }) => (margin ? `${margin}` : '0')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '1.5rem')};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : '')};
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : 'normal')};
  text-transform: ${({ textTransform }) => textTransform && `${textTransform}`};
  font-stretch: ${({ fontStretch }) => fontStretch && `${fontStretch}`};
  line-height: ${({ lineHeight }) => lineHeight && `${lineHeight}`};
  letter-spacing: ${({ letterSpacing }) => letterSpacing && `${letterSpacing}`};
`;

Title.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  fontStretch: PropTypes.string,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string,
};

export default Title;
