import React, { useEffect } from 'react';
import { Container as ContainerDefault, Button as ButtonDefault } from 'reactstrap';
import styled, { css } from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { useRoot } from 'context/RootContext';
import { LOCALIZATION_PAGES, URL } from 'utils/constants';
import { getUser, getCartItemsCount } from 'redux/reducers/sessionReducer';
import { logOut } from 'redux/actions/sessionActions';
import { LanguageSelect, HeaderMenu } from 'components';
import { SFFLogo, UnicreditHeader, CocaColaHeader, BHTelecomHeader } from 'components/Icons';

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const { t } = useTranslation(LOCALIZATION_PAGES.LAYOUT);
  const session = useSelector(getUser);
  const currentCartItemsCount = useSelector(getCartItemsCount);
  const dispatch = useDispatch();
  const { setRedirectOnLoginUrl } = useRoot();

  const location = useLocation();

  // TODO: Discuss scrolling behaviour
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    if (!isOpen) {
      document.body.style.overflow = 'unset';
    }
    return () => (document.body.style.overflow = 'unset');
  }, [isOpen]);

  // TODO: Consider making a custom hook (useIsWindowScrolled?)
  useEffect(() => {
    const onScroll = e => {
      if (window.scrollY > 0 && scrolled !== 0) setScrolled(true);
      else setScrolled(false);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrolled]);

  // Close mobile navigation modal when the user navigates
  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
    // Disable warning for adding 'isOpen' to dependency array because we only want to run the code when location.pathname changes
    // eslint-disable-next-line
  }, [location.pathname]);

  const logOutUser = async () => {
    dispatch(logOut());
    await Auth.signOut();
  };

  const setReturnUrl = () => {
    if (window.location.pathname !== '/signin') {
      setRedirectOnLoginUrl(window.location.pathname);
    }
  };

  return (
    <FullWidthContainer className="sticky-top" scrolled={scrolled}>
      <Container className="d-flex flex-row justify-content-between">
        <SFFLogoWrapper>
          <a href={URL.SFF}>
            <SFFLogo scrolled={scrolled} />
          </a>
        </SFFLogoWrapper>

        <Wrapper scrolled={scrolled}>
          <Sponsors scrolled={scrolled}>
            <a href={URL.COCA_COLA} target="_blank" rel="noopener noreferrer">
              <Sponsor>
                <CocaColaHeader scrolled={scrolled} />
              </Sponsor>
            </a>
            <a href={URL.BHTELECOM} target="_blank" rel="noopener noreferrer">
              <Sponsor>
                <BHTelecomHeader scrolled={scrolled} />
              </Sponsor>
            </a>
            <a href={URL.UNICREDIT} target="_blank" rel="noopener noreferrer">
              <Sponsor>
                <UnicreditHeader scrolled={scrolled} />
              </Sponsor>
            </a>
          </Sponsors>
          <DesktopNav>
            <DesktopNavItem>
              <a href={URL.SFF}>{t('home')}</a>
            </DesktopNavItem>
            <NavButtonSeparator scrolled={scrolled} />
            <DesktopNavItem>
              <Link to="/screenings">{t('screenings')}</Link>
            </DesktopNavItem>
            <NavButtonSeparator scrolled={scrolled} />
            <DesktopNavItem>
              <Link to="/films">{t('films')}</Link>
            </DesktopNavItem>
            {session && (
              <>
                <NavButtonSeparator scrolled={scrolled} />
                <DesktopNavItem>
                  <Link to="/cart">{t('cart', { count: currentCartItemsCount })}</Link>
                </DesktopNavItem>
              </>
            )}
            <NavButtonSeparator scrolled={scrolled} />
            <LanguageSelect />
            <NavButtonSeparator scrolled={scrolled} />
            {!session ? (
              <>
                <DesktopNavItem>
                  <Link onClick={() => setReturnUrl()} to="/signin">
                    {t('sign_in')}
                  </Link>
                </DesktopNavItem>
                <NavButtonSeparator scrolled={scrolled} />
                <DesktopNavItem>
                  <Link to="/signup">{t('sign_up')}</Link>
                </DesktopNavItem>
              </>
            ) : (
              <HeaderMenu />
            )}
          </DesktopNav>
        </Wrapper>

        <MobileNav>
          <Button color="transparent" onClick={() => setIsOpen(!isOpen)}>
            <Hamburger />
          </Button>
        </MobileNav>
      </Container>
      {isOpen && (
        <MobileNavList className="px-4 d-flex flex-column">
          <a href={URL.SFF}>
            <MobileNavButton>{t('home')}</MobileNavButton>
          </a>
          <Link to="/screenings">
            <MobileNavButton>{t('screenings')}</MobileNavButton>
          </Link>
          <Link to="/films">
            <MobileNavButton>{t('films')}</MobileNavButton>
          </Link>
          {session && (
            <Link to="/cart">
              <MobileNavButton>{t('cart', { count: currentCartItemsCount })}</MobileNavButton>
            </Link>
          )}
          {!session ? (
            <>
              <MobileNavButton>
                <Link onClick={() => setReturnUrl()} to="/signin">
                  {t('sign_in')}
                </Link>
              </MobileNavButton>
              <MobileNavButton>
                <Link to="/signup">{t('sign_up')}</Link>
              </MobileNavButton>
            </>
          ) : (
            <>
              <MobileNavButton>
                <Link to="/user-profile">{t('my_profile')}</Link>
              </MobileNavButton>

              <MobileNavButton>
                <Link to="/signin" onClick={logOutUser}>
                  {t('sign_out')}
                </Link>
              </MobileNavButton>
            </>
          )}
          <MobileNavButton>
            <LanguageSelect />
          </MobileNavButton>
        </MobileNavList>
      )}
    </FullWidthContainer>
  );
};

export default Header;

const Container = styled(ContainerDefault)`
  background-color: white;
  padding: 1.35rem;
`;

const FullWidthContainer = styled.header`
  background-color: white;
  ${({ scrolled }) =>
    scrolled &&
    css`
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    `};
`;

const SFFLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileNavButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  background-color: white;
  color: #666666;
  height: 50px;
  width: 100%;

  text-align: left;

  &:focus {
    outline: none;
  }

  & a {
    color: #666666;
  }
`;

const Button = styled(ButtonDefault)`
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const Hamburger = styled(FaBars)`
  color: #ff0013;
  width: 26px;
  height: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 992px) {
    align-items: center;
  }

  ${({ scrolled }) =>
    scrolled &&
    css`
      flex-direction: row;
      flex-grow: 1;
      align-items: center;

      @media (max-width: 992px) {
        justify-content: flex-end;
      }
    `};
`;

const Sponsors = styled.div`
  display: none;
  margin-top: 15px;

  @media (min-width: 767px) {
    display: block;
  }

  ${({ scrolled }) =>
    scrolled &&
    css`
      margin-top: 0px;

      @media (max-width: 992px) {
        display: none;
      }
    `};
`;

const Sponsor = styled.div`
  display: inline-block;
  padding-left: 25px;
`;

const MobileNav = styled.div`
  display: block;

  @media (min-width: 767px) {
    display: none;
  }
`;

const DesktopNav = styled.div`
  display: none;

  @media (min-width: 767px) {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const DesktopNavItem = styled.span`
  font-size: 14px;

  white-space: nowrap;

  color: #666666;
  & a {
    color: #666666;
  }
`;

const NavButtonSeparator = styled.div`
  height: 14px;
  width: 1px;
  background-color: #f2f2f2;
  margin: auto 10px;
`;

const MobileNavList = styled.div`
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.09);
  z-index: 99;
  display: block;
  background-color: white;
  position: absolute;
  width: 100%;

  @media (min-width: 767px) {
    display: none;
  }
`;
