import React, { memo } from 'react';
import DropdownToggleButton from 'components/Dropdown/DropdownToggleButton';

import Select from 'components/Dropdown/Select';

const ButtonDropdown = memo(({ items, selectedItem, disabled, fullWidth, ...rest }) => {
  const onSelect = item => {
    if (selectedItem.id !== item.id) item.onSelect(item);
  };

  const Toggle = () => {
    return (
      <DropdownToggleButton className={`${fullWidth ? 'w-100' : ''}`} disabled={disabled}>
        {selectedItem?.name}
      </DropdownToggleButton>
    );
  };

  return (
    <Select
      fullWidth={fullWidth}
      ToggleElem={Toggle}
      items={items}
      onSelect={onSelect}
      {...rest}
      selectedItem={selectedItem}
    />
  );
});

export default ButtonDropdown;
