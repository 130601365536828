import React from 'react';
import styled from 'styled-components';
import DefaultButton from './Button';
import { FiArrowDown } from 'components/Icons';

const LoadMoreButton = ({ children, ...rest }) => (
  <Button {...rest} color="transparent">
    {children} <LoadMoreArrow />
  </Button>
);

const Button = styled(DefaultButton)`
  &.btn.btn-transparent {
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 1.63px;
    text-align: right;
    color: #b3b3b3;
  }
  &.btn:focus,
  &.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0;
  }
`;

const LoadMoreArrow = styled(FiArrowDown)`
  color: #d8d8d8;
  height: 24px;
  width: 24px;
`;

export default LoadMoreButton;
