import React, { useRef } from 'react';
import styled from 'styled-components';
import { DropdownToggle as ReactstrapDropdownToggle, DropdownItem } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from 'components';
import { Label, Dropdown as DefaultDropdown, Title as DefaultTitle } from 'components';
import { LOCALIZATION_PAGES } from 'utils/constants';

const FilterDropdown = ({ resetFilters, applyFilters, isFiltered, children, ...rest }) => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const dropdownRef = useRef(null);
  const FilterToggle = () => {
    return (
      <DropdownToggle>
        <FilterIcon isFiltered={isFiltered} />
        <Label color={isFiltered ? 'primary' : 'defaultText'} className="ml-2 text-right">
          {tCommon('filter')}
        </Label>
      </DropdownToggle>
    );
  };

  const toggleFunc = (e, toggleOpen, isOpen) => {
    if (isOpen && (e.target === dropdownRef.current || !dropdownRef.current.contains(e.target))) return;
    toggleOpen();
  };

  return (
    <div ref={dropdownRef}>
      <Dropdown ToggleElem={FilterToggle} toggleFunc={toggleFunc}>
        <div className="d-flex justify-content-between mb-3">
          <Title>{tCommon('filter')}</Title>
          <CloseDropdown>
            <MdClose color={'grey'} />
          </CloseDropdown>
        </div>
        {Object.values(children).map((x, index) => (
          <DropdownChild key={index}>{x}</DropdownChild>
        ))}
        <DropdownActions>
          <DropdownItem className="mb-2 dropdown-item-primary" onClick={applyFilters}>
            {tCommon('apply_filter')}
          </DropdownItem>
          <DropdownItem className="dropdown-item-default" onClick={resetFilters}>
            {tCommon('reset_filter')}
          </DropdownItem>
        </DropdownActions>
      </Dropdown>
    </div>
  );
};

const DropdownToggle = styled(ReactstrapDropdownToggle)`
  &.btn.btn-secondary {
    padding-right: 0;
    height: 51.5px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
  }
  &.btn.btn-secondary:not(:disabled):not(.disabled):active,
  &.btn.btn-secondary:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border: none;
  }
  &.btn.btn-secondary:focus,
  .btn.btn-secondary.focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }
`;

const Dropdown = styled(DefaultDropdown)`
  & > .dropdown-menu {
    top: -54.5px !important;
    padding: 1rem;
    max-height: none;
    width: 100vw;
    left: -6% !important;
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.16);
  }
`;

const DropdownChild = styled.div`
  width: 90vw;
  & > * {
    display: block;
    position: relative;
    margin-bottom: 0.5rem;
  }
  & .dropdown {
    display: block;
    position: relative;
  }
  & .btn.btn-secondary {
    max-width: none;
    width: 100%;
    height: 42px;
  }
  & .dropdown-menu {
    width: 100%;
  }
`;

const CloseDropdown = styled(DropdownItem)`
  &.dropdown-item {
    display: inline-block;
    width: auto;
    padding: 0;
    :hover {
      background: transparent;
    }
  }
`;
const DropdownActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
  align-items: center;
  & .dropdown-item {
    height: 38px;
    display: block;
    text-transform: uppercase;
    border-radius: 4px;
    border: solid 1px #d8d8d8;
    color: #999999;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    transition: all 0.3s;
  }
  & .dropdown-item-primary {
    background-color: var(--primary);
    color: var(--white);
    border-color: var(--primary);
  }
  & .dropdown-item-primary:hover {
    color: var(--white);
    background-color: #af1b21;
    border-color: #a4191f;
  }
  & .dropdown-item-default:hover {
    background-color: #d8d8d8;
  }
`;

const Title = styled(DefaultTitle)`
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  color: var(--grey);
`;

export default FilterDropdown;
