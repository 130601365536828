import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { LOCALIZATION_PAGES, ALERT_VARIANTS, HTTP_VERBS } from 'utils/constants';

const path = '/accounts/external/password/change';

const useChangePassword = () => {
  const { showNotification } = useRoot();
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);
  const [{ result, loading, error }, changePassword] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('password_change_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('password_change_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ loading, error }, changePassword];
};

export default useChangePassword;
