import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';

const useConfirmEmail = () => {
  const [{ result: resultConfirmEmail, loading: loadingConfirmEmail, error: errorConfirmEmail }, confirmEmail] = useApi(
    'accounts/external/confirm',
    {
      initialFetch: false,
      verb: HTTP_VERBS.POST,
    }
  );

  return [{ resultConfirmEmail, loadingConfirmEmail, errorConfirmEmail }, confirmEmail];
};

export default useConfirmEmail;
