import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'components';
import { LOCALIZATION_PAGES } from 'utils/constants';

// TODO
const CustomerAccreditationInfo = ({
  accreditation,
  ticketsInCartPerScreening = 0,
  ticketsInCartPerScreeningDate = 0,
}) => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const {
    barcode = '-',
    ticketsLimitDaily = '-',
    ticketsUsedForDay = '-',
    ticketsLimitPerScreening = '-',
    ticketsUsedForScreening = '-',
  } = accreditation || {};

  return (
    <div className="mb-3">
      <AccreditationId accreditationId={tCommon('accreditation_id', { accreditationId: barcode })} />
      <AccreditationLimits
        limits={tCommon('accreditation_limits', {
          ticketsLimitDaily,
          ticketsUsedForDay: ticketsUsedForDay - ticketsInCartPerScreeningDate,
          ticketsLimitPerScreening,
          ticketsUsedForScreening: ticketsUsedForScreening - ticketsInCartPerScreening,
        })}
      />
      {ticketsInCartPerScreening && ticketsInCartPerScreeningDate ? (
        <AccreditationLimits limits={tCommon('in_cart_tickets', { inCart: ticketsInCartPerScreening })} />
      ) : (
        undefined
      )}
    </div>
  );
};

const AccreditationId = ({ accreditationId }) => (
  <Text className="mb-2" fontWeight="500" lineHeight="1.36">
    {accreditationId}
  </Text>
);

const AccreditationLimits = ({ limits }) => (
  <Text color="grey" fontWeight="500" lineHeight="1.36">
    {limits}
  </Text>
);

export default CustomerAccreditationInfo;
