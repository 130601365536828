import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_QUERY } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { usePath } from 'utils/hooks';

const useFilmSimilar = ({ query = API_QUERY.filmSimilarAll, id }) => {
  const path = usePath(`/films/${id}/similar?&Includes=${query.includes}`);
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();
  const [{ result: similarFilms, loading: loadingSimilarFilms, error: errorSimilarFilms }, getFilmSimilar] = useApi(
    path,
    {
      initialFetch: false,
    }
  );

  const language = useSelector(getLanguage);

  useEffect(() => {
    getFilmSimilar();
  }, [getFilmSimilar, language]);

  useEffect(() => {
    if (errorSimilarFilms) {
      showNotification(
        {
          message: t('film_fetch_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorSimilarFilms, showNotification, t, id]);

  return [{ similarFilms, loadingSimilarFilms, errorSimilarFilms }, getFilmSimilar];
};

export default useFilmSimilar;
