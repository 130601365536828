import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS } from 'utils/constants';

const useScreeningSeatTypes = id => {
  const [
    { result: screeningSeatTypes, loading: loadingScreeningSeatTypes, error: errorScreeningSeatTypes },
    getScreeningSeatTypes,
  ] = useApi(API_ENDPOINTS.SCREENING_SEAT_TYPES(id), {
    initialFetch: false,
  });

  return [{ screeningSeatTypes, loadingScreeningSeatTypes, errorScreeningSeatTypes }, getScreeningSeatTypes];
};

export default useScreeningSeatTypes;
