import React, { useState } from 'react';
import { GoStar } from 'react-icons/go';

// Default size is 5 or it can be less || greater depending on the size prop passed in component
const Rating = ({ size = 5, rating, setRating }) => {
  const [hover, setHover] = useState(null);

  return (
    <div className="text-center my-5">
      {[...Array(size)].map((s, i) => {
        const ratingValue = i + 1;

        return (
          <label key={ratingValue}>
            <input
              className="d-none"
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => setRating(ratingValue)}
            />

            <GoStar
              size="50"
              color={ratingValue <= (hover || rating) ? '#f5a623' : '#ebebeb'}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
            />
          </label>
        );
      })}
    </div>
  );
};

export default Rating;
