import React from 'react';
import { Alert } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ALERT_VARIANTS } from 'utils/constants';
import { BsExclamationCircle } from 'react-icons/bs';

const FormAlert = ({ color, isOpen = true, toggle = null, children, ...rest }) => {
  let AlertComponent = null;
  switch (color) {
    case ALERT_VARIANTS.DANGER:
      AlertComponent = AlertDanger;
      break;
    case ALERT_VARIANTS.SUCCESS:
      AlertComponent = AlertSuccess;
      break;
    case ALERT_VARIANTS.WARNING:
      AlertComponent = AlertWarning;
      break;
    default:
      AlertComponent = AlertDefault;
      break;
  }
  return (
    <AlertComponent color={color} isOpen={isOpen} toggle={toggle} {...rest}>
      {color === ALERT_VARIANTS.DANGER && (
        <IconWrapper>
          <BsExclamationCircle />
        </IconWrapper>
      )}
      {children}
    </AlertComponent>
  );
};

FormAlert.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const AlertDefault = styled(Alert)`
  &.alert {
    font-size: 14px;
    font-weight: 500;
    min-height: 50px;
    display: flex;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    border-color: var(--dark);
    color: var(--dark);
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  & .close {
    opacity: 1;
  }
`;

const AlertDanger = styled(AlertDefault)`
  &.alert-danger {
    border-color: var(--primary);
    color: var(--primary);
    background-color: #fbe1e2;
  }
`;
const AlertSuccess = styled(AlertDefault)`
  &.alert-success {
    color: #29c829;
    border-color: #29c829;
    background-color: #e3f9e3;
  }
`;

const AlertWarning = styled(AlertDefault)`
  color: #e59927;
  border-color: #e59927;
  background-color: #fcf4e8;
`;

const IconWrapper = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 1rem;
  margin-top: -2px;
`;

export default FormAlert;
