import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button as ReacstrapButton, Spinner } from 'reactstrap';

const Button = ({ color = 'primary', loading, disabled, children, ...rest }) => (
  <Wrapper color={color} disabled={loading || disabled} {...rest}>
    {loading ? <Spinner color="light" size="sm" /> : children}
  </Wrapper>
);

const Wrapper = styled(ReacstrapButton)`
  &.btn {
    height: ${({ noheight }) => (noheight ? '' : '54.5px')};
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid var(--primary);

    &:disabled {
      cursor: not-allowed;
    }

    ${({ color }) => {
      switch (color) {
        case 'secondary':
          return css`
            background-color: transparent;
            color: var(--grey);
            border-color: #d8d8d8;
            &&:hover,
            &&:active,
            &&:focus,
            &&:disabled {
              background-color: var(--lightGrey);
              border-color: transparent;
            }
          `;
        case 'dark':
          return css`
            background-color: #2a3a46;
            &:hover {
              background-color: #2a3a46;
            }
          `;
        case 'light':
          return css`
            border-color: #d8dce6;
            background-color: #f6f7f9;
          `;
        case 'white':
          return css`
            color: var(--white);
            border-color: #d8d8d8;
          `;
        case 'transparent':
          return css`
            border-color: transparent;
          `;
        case 'icon':
          return css`
            border-color: transparent;
            &:focus,
            &.focus {
              box-shadow: 0 0 0 0;
            }
          `;
        case 'outline':
          return css`
            background-color: transparent;
            color: var(--primary);
            border: solid 2px var(--primary);

            &&:hover {
              color: var(--primary);
            }
          `;
        case 'default-outline':
          return css`
            background-color: transparent;
            color: var(--defaultText);
            border: solid 2px var(--defaultText);

            &&:hover {
              color: var(--primary);
              border: solid 2px var(--primary);
            }
          `;
        default:
          return;
      }
    }}

    @media (min-width: 1024px) {
      font-size: 0.875rem;
    }
  }
`;

Button.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  noheight: PropTypes.string,
};

export default Button;
