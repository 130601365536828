import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { useRoot } from 'context/RootContext';
import routes, { generateLink } from 'utils/routes';
import { useDistributedPurchaseContext } from 'context/DistributedPurchaseContext';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { SEATING_TYPES, SALE_TYPES } from 'utils/constants';

const DefaultPurchaseButtonHoc = WrappedComponent =>
  function Callback(props) {
    const history = useHistory();
    const { screening, globalsale } = props;
    const { setRedirectOnLoginUrl } = useRoot();

    const onClick = () => {
      setRedirectOnLoginUrl(window.location.pathname);
      history.push(generateLink(routes.SIGNIN));
    };

    return <WrappedComponent disabled={handleIsBtnDisabled(screening, globalsale)} onClick={onClick} {...props} />;
  };

// Responsible for starting payment flow
const PurchaseButtonHoc = WrappedComponent =>
  function Callback(props) {
    const { toggleModal: toggleDistributedModal } = useDistributedPurchaseContext();
    const { initReservedPurchase } = useReservedPurchaseContext();
    const { screening, globalsale } = props;

    const onClick = () => {
      if (screening?.seatingType === SEATING_TYPES.DISTRIBUTION)
        toggleDistributedModal(screening, screening?.seatType?.ticketTypeSelectorId);

      if (screening?.seatingType === SEATING_TYPES.RESERVED) {
        // call toggle method from useReservedPurchaseContext
        initReservedPurchase(screening);
      }
    };

    return <WrappedComponent disabled={handleIsBtnDisabled(screening, globalsale)} onClick={onClick} {...props} />;
  };

const handleIsBtnDisabled = (screening, globalsale) => {
  if (!screening || !globalsale) return true;

  let isDisabled = false;

  const { isEnabled, hasStarted, isPaused } = globalsale;
  const isGlobalSaleDisabled = !isEnabled || isPaused || !hasStarted;

  let onlineSaleType = {};
  screening.screeningSaleTypes.forEach(type => {
    if (type?.saleType?.name === SALE_TYPES.ONLINE.name) {
      onlineSaleType = type;
    }
  });

  const currentDate = moment.utc(new Date()).toDate();
  let startOnlineSaleTime = moment
    .parseZone(onlineSaleType?.startTime)
    .local()
    .toDate();
  let endOnlineSaleTime = moment
    .parseZone(onlineSaleType?.endTime)
    .local()
    .toDate();
  const hasOnlineSaleStarted = moment(currentDate).diff(startOnlineSaleTime) > 0;
  const hasOnlineSaleEnded = moment(endOnlineSaleTime).diff(moment(currentDate)) < 0;
  const hasLayout = screening.totalCapacity !== 0;

  isDisabled =
    isGlobalSaleDisabled ||
    !screening.isOnlineSaleEnabled ||
    screening.hasEnded ||
    screening.isSoldOut ||
    !onlineSaleType?.isEnabled ||
    onlineSaleType?.isPaused ||
    !hasOnlineSaleStarted ||
    hasOnlineSaleEnded ||
    !hasLayout;

  return isDisabled;
};

export { DefaultPurchaseButtonHoc, PurchaseButtonHoc };
