import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropdownToggle, DropdownItem as DefaultDropdownItem } from 'reactstrap';

import { Dropdown, Text as DefaultText } from 'components';

const Select = ({ open = false, items, selectedItem, onSelect, fullWidth, ToggleElem = <DropdownToggle caret /> }) => (
  <Dropdown open={open} ToggleElem={ToggleElem} className={`${fullWidth ? 'w-100' : ''}`}>
    {items.map((item, i) => (
      <>
        <DropdownItem
          className="mr-0"
          key={i}
          onClick={() => onSelect(item)}
          active={selectedItem?.name === item?.name}
          disabled={item.disabled}
        >
          <Text text={item.name} color={item.color} />
        </DropdownItem>
        <DropdownItem divider className="m-0" />
      </>
    ))}
  </Dropdown>
);

const Text = styled(({ text, color, ...rest }) => (
  <DefaultText className="white-s" lineHeight="1.57" color={color} {...rest}>
    {text}
  </DefaultText>
))`
  text-overflow: ellipsis;
  overflow: hidden !important;
`;

const DropdownItem = styled(DefaultDropdownItem)`
  && {
    &:disabled {
      opacity: 0.7;
    }
  }
`;

Select.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.object,
  items: PropTypes.array,
  onSelect: PropTypes.func,
};

export default Select;
