import React from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/Button/Button';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from 'components/Modal';

const Modal = ({
  isOpen = false,
  onConfirm,
  onDecline,
  title = '',
  confirmText,
  declineText,
  loading,
  children,
  justifyFooter,
  customSize,
  buttonDisabled,
  hideCancelButton,
  useToggle = true,
  ...rest
}) => {
  return (
    <ModalWrapper isOpen={isOpen} toggle={() => useToggle && onDecline} customSize={customSize} {...rest}>
      <ModalHeader toggle={onDecline}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter justifyfooter={justifyFooter}>
        {onConfirm && (
          <CustomButton color="primary" loading={loading} disabled={buttonDisabled} onClick={onConfirm}>
            {confirmText}
          </CustomButton>
        )}
        {!hideCancelButton && (
          <CustomButton color="default-outline" onClick={onDecline}>
            {declineText}
          </CustomButton>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(({ customSize, children, ...rest }) => (
  <ModalContainer {...rest}>{children}</ModalContainer>
))`
  &.modal-dialog {
    ${({ customSize }) =>
      customSize
        ? css`
            max-width: ${customSize}px;
          `
        : css`
            max-width: max-content;
          `};
  }
`;

const CustomButton = styled(Button)`
  min-width: 160px;
`;

export default Modal;
