import React, { memo } from 'react';
import styled from 'styled-components';

import { CenterContent } from 'components';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';

const DEFAULT_COLOR = '#d9d9d9';

const Seat = memo(({ label, onSeatClick, x, y, seatTypes, layout }) => {
  const layoutXY = layout && layout[x] && layout[x][y] ? layout[x][y] : null;
  const { handleSeat } = useReservedPurchaseContext();
  let seatType = seatTypes?.find(st => st.id === layoutXY.seatTypeId);
  const seatTypeColor = seatType ? seatType?.color : DEFAULT_COLOR;
  const id = `seat-${x}-${y}`;

  return (
    <SeatWrapper>
      {layoutXY ? (
        <>
          {layoutXY.isAvailable ? (
            <Container
              id={id}
              backgroundColor={seatTypeColor}
              onClick={() => handleSeat(x, y)}
              unavailable={layoutXY ? !layoutXY.isAvailable : false}
              isSold={layoutXY ? layoutXY.isSold : false}
              isSelected={layoutXY.isSelected}
              isDisabled={layoutXY.isDisabled}
            >
              <CenterContent>
                <Label>{label}</Label>
              </CenterContent>
            </Container>
          ) : (
            <SeatPlaceholder />
          )}
        </>
      ) : null}
    </SeatWrapper>
  );
});

const Container = styled.div`
  width: 44px;
  height: 44px;
  opacity: ${props => (props.unavailable ? 0.35 : 1)};
  background-color: ${({ isDisabled, backgroundColor, isSelected, isSold }) => {
    if (isDisabled) return '#d9d9d9';
    if (isSelected) return '#1e2025';
    if (isSold) return '#f63275';
    return backgroundColor;
  }};
  border: ${({ isSelected, isDisabled }) => (isSelected && !isDisabled ? '1px solid #f63275' : '')};
  color: ${({ isSelected, isDisabled, isSold }) => (isSelected || isSold || isDisabled ? '#ffffff' : 'black')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.2' : '1')};

  &&:hover {
    cursor: ${({ isDisabled, isSold, isSelected }) =>
      isDisabled || (isSold && !isSelected) ? 'not-allowed' : 'pointer'};
  }
  margin-right: 1rem;
  text-transform: uppercase;
`;

const SeatPlaceholder = styled.div`
  width: 44px;
  height: 44px;
  background-color: transparent;
  margin-right: 1rem;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 1.57;
  margin-top: 3px;
`;

const SeatWrapper = styled.div`
  position: relative;
`;

export default Seat;
