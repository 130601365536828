import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from 'components';
import { handleData } from 'utils/helpers';
import { LOCALIZATION_PAGES } from 'utils/constants';

const CalendarIcon = ({ month, day, isSticky, isResponsive }) => {
  const { t: tMonths } = useTranslation(LOCALIZATION_PAGES.MONTHS);

  return (
    <Wrapper isSticky={isSticky}>
      <Month isResponsive={isResponsive}>
        <Text color="white" fontSize="0.625rem" lineHeight="2.5" fontWeight="900" textTransform="uppercase">
          {tMonths(
            handleData(month)
              .toString()
              .toLowerCase()
          )}
        </Text>
      </Month>
      <Day isResponsive={isResponsive}>
        <Text color="textGrey" fontSize="1.25rem" lineHeight="3" fontWeight="500">
          {handleData(day)}
        </Text>
      </Day>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;

  @media (min-width: 992px) {
    max-width: 5rem;

    ${({ isSticky }) =>
      isSticky &&
      css`
        position: sticky;
        top: 130px;
      `}
  }
`;

const Month = styled.div`
  background-color: var(--primary);
  padding: 0.1rem 0;
  margin-bottom: 0.3rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  ${({ isResponsive }) =>
    isResponsive &&
    css`
      @media (min-width: 768px) and (max-width: 991px) {
        && p {
          font-size: 0.875rem;
        }
      }
    `}
`;

const Day = styled.div`
  background-color: #f5f5f5;

  ${({ isResponsive }) =>
    isResponsive &&
    css`
      padding: 0.5rem 0;

      @media (min-width: 768px) {
        padding: 1.5rem 0;
      }

      @media (min-width: 992px) {
        padding: 0;
      }
    `}
`;

export default CalendarIcon;
