import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Form, FieldArray } from 'formik';

import CustomerAccreditationInfo from 'components/Purchase/CustomerAccreditationInfo';
import { Text, FormikField, PurchaseInput, Loader } from 'components';
import { useDistributedPurchaseContext } from 'context/DistributedPurchaseContext';
import { handleData } from 'utils/helpers';
import { LOCALIZATION_PAGES, CURRENCY, TICKET_TYPE_SELECTORS } from 'utils/constants';

const DistributedPurchaseForm = ({ values }) => {
  const { t: tPurchase } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const {
    apiScreening,
    apiScreeningLoading,
    seatAvailabilityLoading,
    accreditationLoading,
    ticketTypeSelector,
    accreditation,
    isLimitReached,
    handleIsLimitReached,
    totalRemainingSeatsHandler,
  } = useDistributedPurchaseContext();
  const { title = '-', startTime = '', location = { name: '-' }, programs = [] } = apiScreening || {};

  const handleDateAndStartTime = date =>
    date
      ? tPurchase('screening_start_time', {
          startDate: moment(date).format('DD. MMMM YYYY'),
          startTime: moment(date).format('HH:mm'),
        })
      : '-';

  let tickets = values.ticketInserts.find(t => t.ticketTypeSelectorId === ticketTypeSelector)?.ticketSeatTypes;

  return apiScreeningLoading || seatAvailabilityLoading || accreditationLoading ? (
    <Loader />
  ) : (
    <div className="mx-2">
      {accreditation && ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && (
        <div className="container ml-0 ">
          <CustomerAccreditationInfo accreditation={accreditation} />
        </div>
      )}

      <div className="w-100 px-3 d-lg-flex mt-lg-3">
        <div className="mb-4 mb-lg-5">
          <ScreeningTitle title={title} />
          <ScreeningDateAndTime dateAndTime={handleDateAndStartTime(startTime)} />
          <ScreeningLocation location={location.name} />
          <ScreeningPrograms programs={programs.map(p => p.name).join(' | ')} />
        </div>

        {values.ticketInserts.length > 0 && (
          <Form className="ml-lg-5">
            <FieldArray
              name={`ticketInserts[${values.ticketInserts.findIndex(
                t => t.ticketTypeSelectorId === ticketTypeSelector
              )}]`}
              render={() => {
                tickets = values.ticketInserts.find(t => t.ticketTypeSelectorId === ticketTypeSelector)
                  ?.ticketSeatTypes;

                return (
                  tickets.length > 0 &&
                  tickets.map((t, i) => (
                    <React.Fragment key={i}>
                      <div className="d-lg-flex align-items-center justify-content-between px-lg-5">
                        <div className="mr-lg-5">
                          <SeatTypeName seatTypeName={handleData(t?.seatTypeName)} />
                        </div>

                        <span className="mx-lg-5" />

                        {t.totalSeats === t.totalSoldSeats ? (
                          <div className="text-center" style={{ width: '360px' }}>
                            <SoldOutMessage message={tPurchase('sold_out')} />
                          </div>
                        ) : (
                          <div className="d-flex align-items-center mt-3 mt-lg-0 ml-lg-5 text-center">
                            {ticketTypeSelector !== TICKET_TYPE_SELECTORS.ACCREDITATION && (
                              <SeatPrice price={t.price} />
                            )}
                            <FormikField
                              name={`ticketInserts[${values.ticketInserts.findIndex(
                                t => t.ticketTypeSelectorId === ticketTypeSelector
                              )}].ticketSeatTypes[${i}].quantity`}
                              component={props => (
                                <PurchaseInput
                                  values={tickets}
                                  remainingSeats={totalRemainingSeatsHandler(
                                    t.totalSeats,
                                    t.totalSoldSeats,
                                    t.seatTypeId
                                  )}
                                  isLimitReached={isLimitReached}
                                  handleIsLimitReached={handleIsLimitReached}
                                  {...props}
                                />
                              )}
                            />

                            {ticketTypeSelector !== TICKET_TYPE_SELECTORS.ACCREDITATION && (
                              <TotalPrice totalPrice={t.price * t.quantity} />
                            )}
                          </div>
                        )}
                      </div>

                      {tickets.length - 1 !== i ? <Break className="my-4" /> : <div className="my-4" />}
                    </React.Fragment>
                  ))
                );
              }}
            />
          </Form>
        )}
      </div>

      {!tickets?.length && ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && (
        <div
          className="w-75 m-auto text-center"
          dangerouslySetInnerHTML={{
            __html: tCommon('no_tickets_accreditation', { accreditationName: accreditation?.accreditationName }),
          }}
        />
      )}
      {!tickets?.length && ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR && (
        <div className="w-75 m-auto text-center">{tCommon('no_tickets')}</div>
      )}
    </div>
  );
};

const ScreeningTitle = ({ title }) => (
  <Text className="mb-2" fontSize="1.2rem" fontWeight="500" lineHeight="1.31">
    {title}
  </Text>
);

const ScreeningDateAndTime = ({ dateAndTime }) => (
  <Text className="mb-lg-1" fontWeight="500" lineHeight="1.36">
    {dateAndTime}
  </Text>
);

const ScreeningLocation = ({ location }) => (
  <Text className="mb-1" fontSize="0.75rem" fontWeight="500" lineHeight="1.58" color="grey">
    {location}
  </Text>
);

const SeatTypeName = ({ seatTypeName }) => (
  <Text fontSize="1rem" fontWeight="500" lineHeight="1.88">
    {seatTypeName}
  </Text>
);
const SoldOutMessage = ({ message }) => <Text fontWeight="900">{message}</Text>;

const SeatPrice = styled(({ price, ...rest }) => (
  <Text fontWeight="900" {...rest}>
    {`${price.toFixed(2)} ${CURRENCY.BAM}`}
  </Text>
))`
  width: 91px;
  margin-right: 1.5rem;
`;

const ScreeningPrograms = styled(({ programs, ...rest }) => (
  <Text className="mt-2" fontSize="0.75rem" fontWeight="500" lineHeight="1.58" color="label" {...rest}>
    {programs}
  </Text>
))`
  max-width: 270px;
`;

const TotalPrice = styled(({ totalPrice, ...rest }) => (
  <Text fontWeight="900" {...rest}>
    {`${totalPrice.toFixed(2)} ${CURRENCY.BAM}`}
  </Text>
))`
  width: 101px;
  margin-left: 1.5rem;
`;

const Break = styled.hr`
  border-top: solid 2px #f5f5f5;
`;

export default DistributedPurchaseForm;
