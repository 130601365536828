import React, { createContext, useEffect, useMemo, useState, useContext, useCallback, useRef } from 'react';
import i18n from 'i18next';
import uuid from 'uuid';
import { Auth } from 'aws-amplify';
import { useImmer } from 'use-immer';
import { useSelector, useDispatch } from 'react-redux';

import * as TokenService from 'utils/tokenService';
import { Loader } from 'components';
import { sessionActions } from 'redux/actions';
import { getNotification } from 'redux/reducers/uiReducer';
import { getLanguage, getUser } from 'redux/reducers/sessionReducer';
import { useSession, useGlobalSalesSettings } from 'utils/hooks/api';
import { setupApiConfig, removeAuthorization, setupLangHeader, setupInterceptors } from 'utils/apiConfig';
import { getDefaultLanguage, getCurrentLanguageFromStorage } from 'utils/localization/localization';
import { NOTIFICATION_DISMISS_TIMEOUT } from 'utils/constants';
import { useRefreshToken } from 'utils/hooks/api';
import { setSocialAuthenticationFlag, getSocialAuthenticationFlag } from 'utils/helpers';

const RootContext = createContext();

export const RootProvider = props => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [alertSignUpSuccess, setAlertSignUpSuccess] = useState(false);
  const [signUpSuccessEmail, setSignUpSuccessEmail] = useState(false);
  const [isSociallyAuthenticated, setIsSociallyAuthenticated] = useState(null);
  const [notifications, setNotifications] = useImmer([]);
  const loggedIn = useRef;
  const [, getSession] = useSession(loggedIn?.current ? loggedIn.current : false);
  const dispatch = useDispatch();
  const [{ refreshTokenResult, loadingRefreshToken }, getRefreshToken] = useRefreshToken();
  const notification = useSelector(getNotification);
  const language = useSelector(getLanguage);
  const user = useSelector(getUser);
  const [{ resultGlobalSalesSettings }] = useGlobalSalesSettings();
  const [redirectOnLoginUrl, setRedirectOnLoginUrl] = useState(null);
  const socialFlag = getSocialAuthenticationFlag();

  const clearNotification = useCallback(
    notificationId => {
      setNotifications(draft => {
        const index = draft.findIndex(n => n.id === notificationId);
        const notification = draft[index];
        clearInterval(notification.timeout);
        draft.splice(index, 1);
      });
    },
    [setNotifications]
  );

  const showNotification = useCallback(
    ({ message, title }, variant, duration = NOTIFICATION_DISMISS_TIMEOUT) => {
      const id = uuid();
      const timeout = setTimeout(() => {
        clearNotification(id);
      }, duration);

      setNotifications(draft => {
        draft.push({
          message,
          title,
          variant,
          duration,
          id,
          timeout,
        });
      });
    },
    [clearNotification, setNotifications]
  );

  const getRefreshedToken = useCallback(() => {
    const isLoggedIn = TokenService.loggedIn();
    if (isLoggedIn) {
      getRefreshToken();
    }
  }, [getRefreshToken]);

  const initialize = useCallback(async () => {
    let language = getCurrentLanguageFromStorage() || getDefaultLanguage();
    i18n.changeLanguage(language.LANGUAGE_CODE);
    dispatch(sessionActions.setLanguage(language));
    setupLangHeader(language);
    setupInterceptors();

    const isLoggedIn = TokenService.loggedIn();

    if (isLoggedIn) {
      setupApiConfig();
      loggedIn.current = false;
      getSession();
    } else {
      Auth.currentAuthenticatedUser()
        .then(user => {
          const idToken = user?.signInUserSession?.idToken;
          setupApiConfig(idToken?.jwtToken);
          getSocialAuthenticationFlag();
          if (!socialFlag) {
            loggedIn.current = true;
            getSession();
            loggedIn.current = false;
            setSocialAuthenticationFlag(true);
          } else getSession();
          setIsSociallyAuthenticated(true);
        })
        .catch(e => {
          removeAuthorization();
          setIsInitialized(true);
        });
    }
  }, [dispatch, getSession, loggedIn, socialFlag]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (language) i18n.changeLanguage(language.LANGUAGE_CODE);
  }, [language]);

  useEffect(() => {
    if (notification) {
      showNotification({ message: notification.message, title: notification.title }, notification.variant);
    }
  }, [notification, showNotification]);

  useEffect(() => {
    if (user) {
      setIsInitialized(true);
    }
  }, [user]);

  useEffect(() => {
    const intervalId = setInterval(() => getRefreshedToken(), 900000); // 900000 miliseconds = 15 minutes
    return () => clearInterval(intervalId);
  }, [getRefreshedToken]);

  useEffect(() => {
    if (!loadingRefreshToken && refreshTokenResult) {
      TokenService.setToken(refreshTokenResult.idToken);
      setupApiConfig(refreshTokenResult.idToken);
    }
  }, [loadingRefreshToken, refreshTokenResult]);

  const context = useMemo(
    () => ({
      resultGlobalSalesSettings,
      isSociallyAuthenticated,
      alertSignUpSuccess,
      setAlertSignUpSuccess,
      isInitialized,
      notifications,
      showNotification,
      signUpSuccessEmail,
      setSignUpSuccessEmail,
      redirectOnLoginUrl,
      setRedirectOnLoginUrl,
    }),
    [
      resultGlobalSalesSettings,
      isSociallyAuthenticated,
      signUpSuccessEmail,
      setSignUpSuccessEmail,
      alertSignUpSuccess,
      setAlertSignUpSuccess,
      isInitialized,
      notifications,
      showNotification,
      redirectOnLoginUrl,
      setRedirectOnLoginUrl,
    ]
  );

  if (!isInitialized) return <Loader />;

  return <RootContext.Provider value={context}>{props.children}</RootContext.Provider>;
};

export function useRoot() {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error(`useRoot must be used within a RootProvider`);
  }
  return context;
}

export const Consumer = RootContext.Consumer;
