import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FastField, Field, getIn } from 'formik';

import { Input } from 'components';

const FormikField = ({ component: Component = Input, fast, onChange, isDisabled = false, ...props }) => {
  // FormikField connects Formik and our form components (textinput, dropdown etc)
  // and provides standardized flow for form field validation.
  // Pick formik field type based on 'fast' flag. Defaults to <Field />
  // NOTE: use FastField only if you know what you are doing
  // about FastField read more here: https://jaredpalmer.com/formik/docs/api/fastfield
  const Base = fast ? FastField : Field;

  const { t } = useTranslation();

  return (
    <Base {...props}>
      {({ form, field }) => {
        const { touched, errors } = form;
        const { name } = field;

        let fieldErrors = getIn(touched, name) && getIn(errors, name) ? [getIn(errors, name)] : null;
        let errorMsg = null;

        // translate errors, if any
        if (fieldErrors && fieldErrors.length) {
          fieldErrors = fieldErrors.map(err => {
            err = t(err);
            return err;
          });
          // in case of multiple erros, join them by newline and display in multiple lines.
          // do so until detailed requirements provided
          errorMsg = fieldErrors.join('\r\n');
        }

        // this way we make sure that all onChange handlers are invoked
        // by all handlers formik field onChange
        // and developer custom onChange are meant
        const handleChange = payload => {
          // In case of payload as an event (fired from HTML inputs etc)
          // let formik handle the onChange automagically.
          // If payload something other (fired from some custom component)
          // we must manually handle this change here so formik knows what is going on
          // if field name is not set, formik will ignore this change
          if (payload.target && payload.target.name) {
            field.onChange(payload);
          } else if (name) {
            form.setFieldValue(name, payload);
            form.setFieldTouched(name);
          }
          // invoke custom onChange if provided
          if (onChange) onChange(payload);
        };

        const handleBlur = e => {
          const { onBlur } = props;

          if (onBlur) onBlur(e);
          if (field.onBlur) field.onBlur(e);
        };

        return (
          <Component
            {...field}
            {...props}
            disabled={isDisabled}
            onChange={handleChange}
            onBlur={handleBlur}
            validationMessage={errorMsg}
          />
        );
      }}
    </Base>
  );
};

FormikField.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  fast: PropTypes.bool,
};

FormikField.defaultProps = {
  fast: false,
};

export default FormikField;
