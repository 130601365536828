import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col } from 'reactstrap';

import Seats from 'components/Seating/Seats';
import { TransformWrapper, TransformComponent as DefaultTransformComponent } from 'react-zoom-pan-pinch';
import Button from 'components/Button/Button';

import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { FiArrowRight, FiArrowLeft, FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { SCREEN_BREAKPOINTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useCurrentWidth } from 'utils/hooks';
import { useTranslation } from 'react-i18next';

const defaultWrapperSize = {
  sm: 250,
  md: 400,
};

const TransformableSeats = ({ wrapperSize, colLabels, rowLabels, layout, seatTypes, onSeatClick }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const [autoScale, setAutoScale] = useState(1);
  const width = useCurrentWidth();
  const parentSize = width < SCREEN_BREAKPOINTS.SM ? defaultWrapperSize.sm : defaultWrapperSize.md;

  useEffect(() => {
    if (layout) {
      const seatSize = 60;
      const labelOffset = 1;
      const height = parentSize / ((layout.length + labelOffset) * seatSize);
      const width = parentSize / ((layout[0]?.length + labelOffset) * seatSize);
      if (height <= width) {
        if (height < 1) setAutoScale(height);
      } else {
        if (width < 1) setAutoScale(width);
      }
    }
  }, [layout, setAutoScale, parentSize]);

  return (
    <>
      <TransformWrapper doubleClick={{ disabled: true }}>
        {({ zoomIn, zoomOut, resetTransform, setPositionX, setPositionY, positionX, positionY, scale, ...rest }) => (
          <>
            <Row>
              <Col xs="12" sm="10">
                <Row>
                  <Col xs="12">
                    <ScreenIsHere>{t('screen_here')}</ScreenIsHere>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="mb-4">
                    <TransformComponent size={wrapperSize}>
                      <Wrapper scale={autoScale}>
                        <Inner scale={autoScale}>
                          <Seats
                            scale={autoScale}
                            colLabels={colLabels}
                            rowLabels={rowLabels}
                            layout={layout}
                            seatTypes={seatTypes}
                            onSeatClick={onSeatClick}
                          />
                        </Inner>
                      </Wrapper>
                    </TransformComponent>
                  </Col>
                </Row>
              </Col>
              <LayoutOptions xs="12" sm="2">
                <LayoutOption onClick={zoomIn}>
                  <AiOutlineZoomIn />
                </LayoutOption>
                <LayoutOption onClick={zoomOut}>
                  <AiOutlineZoomOut />
                </LayoutOption>
                <LayoutOption onClick={resetTransform}>
                  <MdClose />
                </LayoutOption>
                <LayoutOption onClick={() => setPositionX(positionX + scale * autoScale * 56, 200, 'easeOut')}>
                  <FiArrowLeft />
                </LayoutOption>
                <LayoutOption onClick={() => setPositionX(positionX - scale * autoScale * 56, 200, 'easeOut')}>
                  <FiArrowRight />
                </LayoutOption>
                <LayoutOption onClick={() => setPositionY(positionY + scale * autoScale * 56, 200, 'easeOut')}>
                  <FiArrowUp />
                </LayoutOption>
                <LayoutOption onClick={() => setPositionY(positionY - scale * autoScale * 56, 200, 'easeOut')}>
                  <FiArrowDown />
                </LayoutOption>
              </LayoutOptions>
            </Row>
          </>
        )}
      </TransformWrapper>
    </>
  );
};

const LayoutOptions = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-top: 1rem; */
  > .btn {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media only screen and (min-width: ${SCREEN_BREAKPOINTS.SM}px) {
    flex-direction: column;
    & > .btn:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const LayoutOption = styled(({ children, ...rest }) => (
  <Button color="default" {...rest}>
    {children}
  </Button>
))`
  &.btn {
    width: 52px;
    height: 38px;
    padding: 0 !important;
    text-align: center;
    line-height: 38px;
    border-radius: 4px;
    border: solid 1px #d8d8d8;
    svg {
      margin-bottom: 5px;
      margin-right: 0;
    }
  }
`;

const TransformComponent = styled(DefaultTransformComponent)`
  ${({ size }) => {
    return size
      ? css`
          height: ${size}px;
          width: ${size}px;
        `
      : css`
          height: ${defaultWrapperSize.sm}px;
          width: ${defaultWrapperSize.sm}px;
          @media only screen and (min-width: ${SCREEN_BREAKPOINTS.SM}px) {
            height: ${defaultWrapperSize.md}px;
            width: ${defaultWrapperSize.md}px;
          }
        `;
  }}
`;

const Inner = styled.div`
  width: 100%;

  ${({ scale }) =>
    scale
      ? css`
          transform: scale(${scale});
          transform-origin: left top;
        `
      : css`
          margin: 1rem;
        `}
`;

const Wrapper = styled.div`
  text-align: center;
  max-height: ${defaultWrapperSize.sm}px;
  max-width: ${defaultWrapperSize.sm}px;
  margin: 0 auto;
  @media only screen and (min-width: ${SCREEN_BREAKPOINTS.SM}px) {
    max-height: ${defaultWrapperSize.md}px;
    max-width: ${defaultWrapperSize.md}px;
    margin: 0 0;
  }
  ${({ scale }) =>
    scale
      ? css`
          overflow: hidden;
        `
      : css`
          overflow: auto;
          padding-right: 2rem;
          padding-left: 1rem;
        `}

  &&::-webkit-scrollbar {
    width: 5px;
    background: var(--darker);
  }
  &&::-webkit-scrollbar-thumb {
    background: var(--secondary);
  }
`;
const ScreenIsHere = styled.div`
  text-align: center;
  width: 100%;
  font-size: 12px;
  line-height: 1.58;
  color: var(--secondary);
  border-top: 4px solid #f5f5f5;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
`;

export default TransformableSeats;
