import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useApi from 'utils/hooks/api/useApi';
import { getLanguage } from 'redux/reducers/sessionReducer';

const path = `/external/users/current/purchase-history`;

const usePurchases = () => {
  const language = useSelector(getLanguage);
  const [{ result: purchases, loading, error }, getPurchases] = useApi(path, {
    initialFetch: false,
  });

  useEffect(() => {
    getPurchases();
  }, [getPurchases, language]);

  return [{ purchases, loading, error }, getPurchases];
};

export default usePurchases;
