import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, HTTP_VERBS, API_ENDPOINTS } from 'utils/constants';

const useResendTickets = id => {
  const { showNotification } = useRoot();
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);
  const [{ result, loading, error }, resendTickets] = useApi(API_ENDPOINTS.RESEND_TICKETS(id), {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('resend_tickets_success'),
      });
    }
  }, [result, showNotification, t]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('resend_tickets_failed'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ result, loading, error }, resendTickets];
};

export default useResendTickets;
