import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    font-size: 100%;
  }

  html, body {
        margin: 0;
        height: 100%;
        width: 100%;
        background-color: var(--white);
        scroll-behavior: smooth;
    }
    
  #root{
      width: 100%;
      height: 100%;
      background-color: var(--white);
      display: flex;
  flex-direction: column;
  }
  main{
    flex: 1 0 auto;
  }
  footer{
    flex-shrink: 0;
  }

  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
    &:focus{
      outline:none;
    }
  }

  a {
    text-decoration: none;

    &:hover{
      text-decoration:none;
    }
  }

  .modal-open {
    overflow: hidden !important;
  }

  .clickable{
    cursor:pointer;
  }
  
  /* Bootstrap overrides */
  .alert-dismissible .close{
    top: -1px;
  }

  .highlighted-border {
    border: 3px solid #f63275;
  }

  .justifyTransformWrapperContent .TransformComponent-module_content__TZU5 {
    justify-content: center;
  }
`;
