import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';

const usePay = () => {
  const [{ result: resultPay, loading: loadingPay, error: errorPay }, pay] = useApi('/payment', {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });

  return [{ resultPay, loadingPay, errorPay }, pay];
};

export default usePay;
