import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input as ReactstrapInput, Button as DefaultButton } from 'reactstrap';

const PurchaseInput = ({
  value,
  values,
  screeningId,
  isLimitReached,
  handleIsLimitReached,
  onChange,
  remainingSeats,
  validationMessage,
  ...props
}) => {
  const handleChange = (value, step) => onChange(value + step);

  useEffect(() => {
    handleIsLimitReached(values);
  }, [values, handleIsLimitReached]);

  return (
    <div className="d-flex">
      <Button className="border-right-0" outline onClick={() => handleChange(value, -1)} disabled={value === 0} />
      <InputComponent readOnly type="number" min={0} value={value} onChange={onChange} {...props} />
      <Button
        outline
        className="border-left-0"
        onClick={() => handleChange(value, 1)}
        disabled={value >= remainingSeats || isLimitReached}
      />
    </div>
  );
};

const InputComponent = styled(ReactstrapInput)`
  && {
    width: 42px;
    height: 40px;
    font-size: 0.625rem;
    border-radius: 4px;
    letter-spacing: 1.25px;
    font-weight: 900;
    line-height: 4;
    text-align: center;
    color: #b7b7b7;
    border: solid 2px #f5f5f5;
    border-radius: 0;

    &:focus {
      box-shadow: none;
      border: solid 2px #f5f5f5;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:read-only {
      background-color: var(--white);
      cursor: initial;
    }

    &:focus {
      color: #b7b7b7;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:-moz-read-only {
      background-color: var(--white);
      cursor: initial;
    }
  }
`;

const Button = styled(DefaultButton)`
  &&,
  &&:disabled {
    width: 40px;
    height: 40px;
    border: solid 2px #f5f5f5;
    background-color: var(--white);
  }

  &:first-child {
    &&::before {
      content: '-';
    }
  }

  &:last-child {
    &&::before {
      content: '+';
    }
  }

  &&:disabled {
    cursor: not-allowed;
  }
`;

PurchaseInput.propTypes = {
  validationMessage: PropTypes.string,
};

export default PurchaseInput;
