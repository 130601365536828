import styled from 'styled-components';
import React from 'react';

const DatePickerWrapper = styled(({ block, className, children, ...rest }) => (
  <div {...rest} className={className}>
    {children}
  </div>
))`
  display: inline-flex;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.08);
  & .react-datepicker-popper {
    z-index: 2000; /* Temporary solution. New hook will be implemented to solve issues with pagination and querry */
  }
  & .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: var(--primary);
    color: var(--light);
  }
  & .react-datepicker__day:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover {
    background-color: var(--primary);
    color: var(--light);
  }
  & .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover {
    background-color: var(--primary);
  }
  & .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
  .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
    background-color: var(--primary);
    color: var(--light);
  }
  & .react-datepicker__month--selecting-range .react-datepicker__day--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:hover {
    background-color: var(--dark);
    color: var(--light);
  }
  & .react-datepicker__time-container {
    width: 100px;
  }
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px;
  }
  & .react-datepicker__month-container {
    box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.08);
  }
  & .react-datepicker__header {
    padding: 1rem;
  }
  .react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted {
    border-radius: 0.3rem;
    background-color: transparent;
    // background-image: url('${process.env.PUBLIC_URL}/favicon.ico');
    background-position: center;
    background-size: contain;
    color: var(--dark);
}
  & .react-datepicker__day-name,
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: var(--defaultText);
  }
  & .react-datepicker__month {
    padding: 1rem;
    margin: 0;
  }
  & .react-datepicker__navigation {
    top: 20px;
  }
  & .react-datepicker__navigation--next {
    right: 15px;
  }
  & .react-datepicker__navigation--previous {
    left: 15px;
  }
  & .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-bottom-color: var(--darkGrey);
  }

  & .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
  }

  & .react-datepicker__year-select,
  .react-datepicker__month-select {
    flex-grow: 1;
    padding: 0.5rem;
    border-radius: 4px;
    outline: none;
    border: 0;
    width: 100%;
  }
  & .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    border-radius: 4px;
    margin: 1rem 0;
    background-color: var(--light);
    display: flex;
    justify-content: space-between;
  }
  & .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    margin: 0;
    width: 100%;
  }
`;

export default DatePickerWrapper;
