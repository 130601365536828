import React from 'react';
import GlobalStyle from 'globalStyle';
import Amplify, { Auth } from 'aws-amplify';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { oauth, userPool } from 'cognitoConfig';

import routes from 'utils/routes';
import { Alerts, Header, Footer, DistributedPurchase, ReservedPurchase } from 'components';
import { CartProvider, DistributedPurchaseProvider } from 'context';
import { getUser } from 'redux/reducers/sessionReducer';
import { ReservedPurchaseProvider } from 'context/ReservedPurchaseContext';

Amplify.configure(userPool);
Auth.configure({ oauth });

const Root = () => {
  const user = useSelector(getUser);

  // <ReservedPurchaseProvider /> will be added with coresponding component
  const RoutesWrapper = ({ children }) =>
    !user ? (
      children
    ) : (
      <CartProvider>
        <ReservedPurchaseProvider>
          <DistributedPurchaseProvider>
            {children}
            <DistributedPurchase />
            <ReservedPurchase />
          </DistributedPurchaseProvider>
        </ReservedPurchaseProvider>
      </CartProvider>
    );

  return (
    <>
      <GlobalStyle />
      <Alerts />
      <RoutesWrapper>
        <Header />
        <main>
          <Switch>
            {Object.values(routes).map((r, i) => (
              <Route key={i} path={r.path} exact={r.exact} component={r.Component} />
            ))}
            <Route path="*">
              <Redirect to={{ pathname: routes.FILMS.path }} />
            </Route>
          </Switch>
        </main>
        <Footer />
      </RoutesWrapper>
    </>
  );
};

export default Root;
