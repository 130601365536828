export const userPool = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
};

export const oauth = {
  domain: process.env.REACT_APP_AWS_USER_POOL_DOMAIN,
  redirectSignIn: `${process.env.REACT_APP_CLIENT_BASE_URL}films/`,
  redirectSignOut: `${process.env.REACT_APP_CLIENT_BASE_URL}signin/`,
  responseType: 'token',
};
