import produce from 'immer';

import constants from 'redux/constants';

const INITIAL_STATE = {
  user: null,
  language: null,
  cartItemsCount: 0,
};

const session = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.session.SET_LANGUAGE:
      return produce(state, draft => {
        draft.language = payload.language;
      });
    case constants.session.LOGOUT:
      return produce(state, draft => {
        draft.user = null;
      });
    case constants.session.SET_USER_INFO:
      return produce(state, draft => {
        draft.user = payload.user;
      });
    case constants.session.GET_CART_ITEMS_COUNT:
      return produce(state, draft => {
        draft.cartItemsCount = payload.count;
      });
    default:
      return state;
  }
};

export default session;

export const getLanguage = state => state.session.language;

export const getUser = state => state.session.user;

export const getCartItemsCount = state => state.session.cartItemsCount;
