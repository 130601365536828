import { LANGUAGES } from 'utils/constants';

const LANG_KEY = 'CURRENT_LANGUAGE';

export const getDefaultLanguage = () => {
  const lang = LANGUAGES.find(l => l.DEFAULT);
  return lang || LANGUAGES[0];
};

export const setCurrentLanguageInStorage = language => {
  localStorage.setItem(LANG_KEY, JSON.stringify(language));
};

export function getCurrentLanguageFromStorage() {
  try {
    return JSON.parse(localStorage.getItem(LANG_KEY));
  } catch (error) {
    return null;
  }
}
