import * as React from 'react';
import styled from 'styled-components';
import { Container as ContainerDefault, Row, Col as ColDefault } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, URL } from 'utils/constants';

import {
  BHTelecomFooter,
  CocaColaFooter,
  AtlanticFooter,
  DohaFooter,
  MasterCardFooter,
  EAMediaProgrammeFooter,
  TRTFooter,
  UnicreditFooter,
  MinistarstvoFooter,
  VladaKSFooter,
  GradSarajevoFooter,
  VisitSarajevoFooter,
  FondacijaFooter,
  BingoFooter,
  UniqaFooter,
  HTECLogo,
  UnescoFooter,
  CineplexxFooter,
  MinOkolisFooter,
} from 'components/Icons';

const Footer = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.LAYOUT);

  return (
    <>
      <a href={URL.HTEC} target="_blank" rel="noopener noreferrer">
        <MistralLogoWrapper>
          <MistralText>{t('powered_by')}</MistralText>
          <HTECLogo height="16" />
        </MistralLogoWrapper>
      </a>
      <FullWidthContainer>
        <Container className="w-100">
          <InnerContainer>
            <Row>
              <Col xs="12" md="2">
                <SponsorText>{t('under_patronage_of')}</SponsorText>

                <Sponsor href={URL.UNESCO} target="_blank" rel="noopener noreferrer">
                  <UnescoFooter height="34" />
                </Sponsor>
              </Col>
              <Col xs="12" md="10">
                <SponsorText>{t('main_sponsors')}</SponsorText>

                <Sponsor href={URL.COCA_COLA} target="_blank" rel="noopener noreferrer">
                  <CocaColaFooter height="34" />
                </Sponsor>
                <Sponsor href={URL.BHTELECOM} target="_blank" rel="noopener noreferrer">
                  <BHTelecomFooter height="34" />
                </Sponsor>
                <Sponsor href={URL.UNICREDIT} target="_blank" rel="noopener noreferrer">
                  <UnicreditFooter height="34" />
                </Sponsor>
              </Col>

              <Col xs="12" md="12">
                <SponsorText>{t('exclusive_sponsors')}</SponsorText>

                <Sponsor href={URL.ATLANTIC} target="_blank" rel="noopener noreferrer">
                  <AtlanticFooter height="25" />
                </Sponsor>
                <Sponsor href={URL.MASTERCARD} target="_blank" rel="noopener noreferrer">
                  <MasterCardFooter height="38" />
                </Sponsor>
                <Sponsor href={URL.UNIQA} target="_blank" rel="noopener noreferrer">
                  <UniqaFooter />
                </Sponsor>
                <Sponsor href={URL.BINGO} target="_blank" rel="noopener noreferrer">
                  <BingoFooter />
                </Sponsor>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="5" md="4" lg="3">
                <SponsorText>{t('supported_by')}</SponsorText>

                <Sponsor href={URL.EU_MEDIA_PROGRAMME} target="_blank" rel="noopener noreferrer">
                  <EAMediaProgrammeFooter height="32" />
                </Sponsor>
              </Col>

              <Col xs="12" sm="6">
                <SponsorText>{t('festival_partners')}</SponsorText>

                <Sponsor href={URL.DOHA} target="_blank" rel="noopener noreferrer">
                  <DohaFooter height="39" />
                </Sponsor>
                <Sponsor href={URL.TRT} target="_blank" rel="noopener noreferrer">
                  <TRTFooter height="39" />
                </Sponsor>
                <Sponsor href={URL.CINEPLEXX} target="_blank" rel="noopener noreferrer">
                  <CineplexxFooter height="39" />
                </Sponsor>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12">
                <SponsorText>{t('under_patronage')}</SponsorText>

                <Sponsor href={URL.MINISTARSTVO} target="_blank" rel="noopener noreferrer">
                  <MinistarstvoFooter height="39" />
                </Sponsor>
                <Sponsor href={URL.FONDACIJA} target="_blank" rel="noopener noreferrer">
                  <FondacijaFooter height="27" />
                </Sponsor>
                <Sponsor href={URL.MINISTARSTVO_OKOLIS} target="_blank" rel="noopener noreferrer">
                  <MinOkolisFooter height="39" />
                </Sponsor>
                <Sponsor href={URL.VLADA_KS} target="_blank" rel="noopener noreferrer">
                  <VladaKSFooter height="27" />
                </Sponsor>
                <Sponsor href={URL.SARAJEVO} target="_blank" rel="noopener noreferrer">
                  <GradSarajevoFooter height="27" />
                </Sponsor>
                <Sponsor href={URL.VISIT_SARAJEVO} target="_blank" rel="noopener noreferrer">
                  <VisitSarajevoFooter height="27" />
                </Sponsor>
              </Col>
            </Row>
          </InnerContainer>
        </Container>
        <ContainerDefault className="px-5">
          <Row>
            <FooterText>
              Copyright © 1995-{new Date().getFullYear()} Obala Art Centar. {t('rights_reserved')}.
              <br />
              {t('design_n_develop')} <a href="https://htecgroup.com/">HTEC GROUP</a>
            </FooterText>
          </Row>
          <Row>
            <FooterText>
              <a href={`${t('terms_of_service_url')}`} target="_blank" rel="noopener noreferrer">
                {t('terms_of_service')}
              </a>
            </FooterText>
          </Row>
        </ContainerDefault>
      </FullWidthContainer>
    </>
  );
};

export default Footer;

const SponsorText = styled.span`
  position: absolute;
  top: 0;
  left: 25px;
  color: white;
  opacity: 0.5;
  font-size: 12px;
`;

const Container = styled(ContainerDefault)`
  display: flex;
  flex-wrap: wrap;

  padding-top: 15px;
  padding-bottom: 15px;
`;

const InnerContainer = styled.div`
  width: 100%;
  padding: 20px;

  /* & .row + .row {
    margin-top: 20px;
  } */
`;

const FullWidthContainer = styled.footer`
  background-color: #0f0f0f;
  padding-bottom: 20px;
`;

const Sponsor = styled.a`
  opacity: 0.5;
  cursor: pointer;

  padding: 10px;

  & img {
    margin: 4px 0;
    max-width: 100% !important;
    height: auto !important;
  }

  @media only screen and (max-width: 991px) {
    & img {
      margin-bottom: 15px;
    }
  }

  &:hover {
    opacity: 1;
  }
`;

const Col = styled(ColDefault)`
  padding-top: 30px;
  margin-bottom: 1rem;
`;

const FooterText = styled.p`
  font-size: 12px;
  color: #666;
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

const MistralLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.9rem;
  background-color: rgba(245, 245, 245, 0.57);
`;

const MistralText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #010101;
  line-height: 2.5;
  margin-right: 1rem;
`;
