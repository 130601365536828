import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS } from 'utils/constants';

const useScreenings = query => {
  const queryString = `${API_ENDPOINTS.SCREENINGS}?StartDate=${query.startDate}&EndDate=${query.endDate}&Term=${query.term}&ProgramIds=${query.programId}&VenueIds=${query.venueId}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const [{ result: screenings, loading, error, setResult: setScreenings }, getScreenings] = useApi(queryString, {
    initialFetch: false,
  });
  const language = useSelector(getLanguage);

  useEffect(() => {
    if (query.startDate !== '') getScreenings();
  }, [query, getScreenings, language]);

  return [{ screenings, loading, error }, setScreenings, getScreenings];
};

export default useScreenings;
