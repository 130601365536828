import styled, { css } from 'styled-components';
import {
  ModalHeader as ReactstrapModalHeader,
  ModalBody as ReactstrapModalBody,
  ModalFooter as ReactstrapModalFooter,
  Modal as ReactstrapModal,
} from 'reactstrap';

import PurchaseModal from './PurchaseModal';
import Modal from './Modal';

import { SCREEN_BREAKPOINTS } from 'utils/constants';

const ModalHeader = styled(ReactstrapModalHeader)`
  &.modal-header {
    padding: 2rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2;
    color: var(--defaultText);
    border-bottom: 1px solid transparent;
  }
`;

const ModalBody = styled(ReactstrapModalBody)`
  &.modal-body {
    padding: 0 2rem;
  }
`;

const ModalFooter = styled(ReactstrapModalFooter)`
  &.modal-footer {
    ${({ justifyfooter }) =>
      justifyfooter &&
      css`
        justify-content: ${justifyfooter};
      `}
    border-top: none;
    padding: 2rem;
  }

  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.SM}px) {
    &.modal-footer .btn {
      width: 100%;
    }
  }
`;

const ModalContainer = styled(ReactstrapModal)`
  color: var(--dark) !important;
  .modal-content {
    background-color: var(--white);
  }
  .close {
    color: var(--dark);
    text-shadow: none;
    span {
      font-size: 1.8rem;
    }
  }
`;

export { PurchaseModal, ModalHeader, ModalBody, ModalFooter, ModalContainer, Modal };
