import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';

const useResetPass = () => {
  const [{ result: resultResetPass, loading: loadingResetPass, error: errorResetPass }, resetPass] = useApi(
    'accounts/external/password/reset',
    {
      initialFetch: false,
      verb: HTTP_VERBS.POST,
    }
  );

  return [{ resultResetPass, loadingResetPass, errorResetPass }, resetPass];
};

export default useResetPass;
