import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as ReacstrapButton } from 'reactstrap';

const DateButton = memo(({ selected, children, ...rest }) => (
  <CustomButton selected={selected} {...rest}>
    {children}
  </CustomButton>
));

const CustomButton = styled(ReacstrapButton)`
  display: none;

  @media (min-width: 768px) {
    display: block;
    && {
      background-color: var(--white);
      width: 8.125rem;
      height: 3rem;
      font-size: 0.875rem;
      margin: 0.2rem 0.85rem 0.7rem 0rem;
      line-height: 1.21;
      font-weight: bold;
      color: ${({ selected }) => (selected ? 'var(--primary)' : 'var(--lightGrey)')};
      border: 2px solid ${({ selected }) => (selected ? 'var(--primary)' : 'var(--lightGrey)')};
      border-radius: 20rem;
      box-shadow: none !important;

      &:last-child {
        margin-right: 0;
      }

      &&:hover {
        border: 2px solid var(--primary);
        color: var(--primary);
        background-color: transparent;
      }

      &&:active,
      &&:focus {
        background-color: transparent;
        color: var(--primary);
        border: 2px solid var(--primary);
      }
    }
  }
`;

DateButton.propTypes = {
  selected: PropTypes.bool,
};

export default DateButton;
