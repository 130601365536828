import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, HTTP_VERBS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useUpdateUser = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);
  const { showNotification } = useRoot();
  const [{ result, loading, error }, updateUser] = useApi(`external/users/current`, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('user_update_fail'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('user_update_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading }, updateUser];
};

export default useUpdateUser;
