import styled from 'styled-components';

const Tag = styled.div`
  display: inline-block;
  border-radius: 4px;
  background-color: #f7f7f7;
  font-size: 12px;
  font-weight: 900;
  line-height: 2.5;
  letter-spacing: 1.5px;
  padding: 0 1rem;
  color: #b3b3b3;
`;

export default Tag;
