import { lazy } from 'react';
import { matchPath } from 'react-router-dom';
import { generatePath } from 'react-router';

import Authentication from 'components/Hoc/Authentication';

const Films = lazy(() => import('components/Films/Films'));
const FilmDetails = lazy(() => import('components/Films/FilmDetails'));
const Screenings = lazy(() => import('components/Screenings/Screenings'));
const SignUp = lazy(() => import('components/Auth/SignUp'));
const SignIn = lazy(() => import('components/Auth/SignIn'));
const ForgotPassword = lazy(() => import('components/Auth/ForgotPassword'));
const VerifyEmail = lazy(() => import('components/Auth/VerifyEmail'));
const UserProfile = Authentication(lazy(() => import('components/UserProfile/UserProfile')));
const Cart = Authentication(lazy(() => import('components/Cart/Cart')));
const PaymentSuccess = Authentication(lazy(() => import('components/Payment/PaymentSuccess')));
const PaymentFail = Authentication(lazy(() => import('components/Payment/PaymentFail')));
const Voting = lazy(() => import('components/Voting/Voting'));

const routes = {
  SIGNUP: {
    id: 'SIGNUP',
    path: '/signup',
    exact: true,
    Component: SignUp,
  },
  SIGNIN: {
    id: 'SIGNIN',
    path: '/signin',
    exact: true,
    Component: SignIn,
  },
  FORGOT_PASSWORD: {
    id: 'FORGOT_PASSWORD',
    path: '/reset-password',
    exact: true,
    Component: ForgotPassword,
  },
  VERIFY_EMAIL: {
    id: 'VERIFY_EMAIL',
    path: '/verify-email',
    exact: true,
    Component: VerifyEmail,
  },
  FILMS: {
    id: 'FILMS',
    path: '/films',
    exact: true,
    Component: Films,
  },
  FILM: {
    id: 'FILM',
    path: '/films/:id',
    exact: true,
    Component: FilmDetails,
  },
  SCREENINGS: {
    id: 'SCREENINGS',
    path: '/screenings',
    exact: true,
    Component: Screenings,
  },
  USER_PROFILE: {
    id: 'USER_PROFILE',
    path: '/user-profile',
    exact: true,
    Component: UserProfile,
  },
  CART: {
    id: 'CART',
    path: '/cart',
    exact: true,
    Component: Cart,
  },
  PAYMENT_SUCCESS: {
    id: 'PAYMENT_SUCCESS',
    path: '/payment/success',
    exact: true,
    Component: PaymentSuccess,
  },
  PAYMENT_FAIL: {
    id: 'PAYMENT_FAIL',
    path: '/payment/fail',
    exact: true,
    Component: PaymentFail,
  },
  VOTING: {
    id: 'VOTING',
    path: '/voting',
    exact: true,
    Component: Voting,
  },
};

/**
 * Find a matching route in all application routes
 * @param {String} pathname     Path to search for in routes.
 * @param {Boolean} exact       Find exact match or first partial match.
 */
export function matchRoute(pathname, exact) {
  return Object.values(routes).find(r =>
    matchPath(pathname, {
      path: r.path,
      exact: exact == null ? r.exact : exact, // if exact flag provided use it otherwise use as specified in route r
    })
  );
}

/**
 * Generate path for given route id or route. Pass params for rouutes
 * which contain dynamic parts like query params
 * @param {String|Object} routeOrRouteId    Route ID as string or complete Route object to match.
 * @param {Object} params                   Object containing data for query parameters included in matched route.
 */

export function generateLink(routeOrRouteId, params) {
  let route;
  if (typeof routeOrRouteId === 'string') {
    route = routes[routeOrRouteId];
  } else if (routeOrRouteId.hasOwnProperty('id') && routes[routeOrRouteId.id]) {
    route = routeOrRouteId;
  }

  if (!route) {
    console.error(`Route not found error. Can't generate link for unknown route ${routeOrRouteId}`);
    return '#';
  }
  return generatePath(route.path, params);
}

export default routes;
