import React from 'react';
import styled from 'styled-components';
import { DropdownToggle as BootDropdownToggle } from 'reactstrap';
import { FaEllipsisH } from 'react-icons/fa';

import Select from 'components/Dropdown/Select';

const TableOptionsToggle = ({ items, data }) => {
  const onSelect = item => item.onSelect(data);

  const Toggle = () => (
    <DropdownToggle>
      <FaEllipsisH />
    </DropdownToggle>
  );

  return <Select ToggleElem={Toggle} items={items} onSelect={onSelect} />;
};

const DropdownToggle = styled(BootDropdownToggle)`
  && {
    background-color: transparent !important;
    border: 0 !important;
    color: var(--secondary) !important;
    margin-right: -0.8rem;

    @media (min-width: 992px) {
      margin-right: 0;
    }
  }
`;

export default TableOptionsToggle;
