import React, { createContext, useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';

import { useSeatAvailability, useAddReservedSeats, useScreeningSeatTypes, useScreening, useApi } from 'utils/hooks/api';
import {
  MARKINGS,
  ORIENTATION,
  MAX_TICKETS_PURCHASE_LIMIT,
  API_ENDPOINTS,
  TICKET_TYPE_SELECTORS,
  SEATING_TYPES,
} from 'utils/constants';
import { useCartContext } from './CartContext';
import { useHistory } from 'react-router-dom';
import routes, { generateLink, matchRoute } from 'utils/routes';
import { generateLabels } from 'utils/helpers';

export const ReservedPurchaseContext = createContext();

export const ReservedPurchaseProvider = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screening, setScreening] = useState(null);
  const [ticketTypeSelector, setTicketTypeSelector] = useState(null);

  const [colLabels, setColLabels] = useImmer(['']); // added an empty item for spacing
  const [rowLabels, setRowLabels] = useImmer([]);
  const [layout, setLayout] = useImmer([]);
  const [selectedSeats, setSelectedSeats] = useImmer([]);

  const [isLoading, setIsLoading] = useState(true);
  const [cartIds, setCartIds] = useState([]); // Used for handling selected seats when they are in cart
  const [isSeatTaken, setIsSeatTaken] = useState(false);

  const [accreditationNumber, setAccreditationNumber] = useState('');
  const [cartAccreditationNumber, setCartAccreditationNumber] = useState('');
  const [toAccTicketSelection, setToAccTicketSelection] = useState(false);
  const [accLimit, setAccLimit] = useState(null);

  const getScreeningId = screening => (screening ? screening.id | screening.screeningId : null);

  const [{ screening: resultScreening, loading: loadingScreening, error: errorScreening }] = useScreening(
    getScreeningId(screening)
  );
  const [
    { seats, loadingSeatAvailability, errorSeatAvailability, setSeats },
    getSeatAvailability,
  ] = useSeatAvailability(getScreeningId(screening));

  const [
    { screeningSeatTypes, loadingScreeningSeatTypes, errorScreeningSeatTypes },
    getScreeningSeatTypes,
  ] = useScreeningSeatTypes(getScreeningId(screening));

  const [
    {
      result: accreditation,
      loading: accreditationLoading,
      error: accreditationError,
      setResult: setAccreditation,
      setError: setAccreditationError,
    },
    getAccreditation,
  ] = useApi(API_ENDPOINTS.CUSTOMER_ACCREDITATION(accreditationNumber, getScreeningId(screening)), {
    initialFetch: false,
  });
  const handleAccreditationChange = useCallback(
    e => {
      if (accreditationError) setAccreditationError(null);

      setAccreditationNumber(e.target.value);
    },
    [accreditationError, setAccreditationError]
  );
  const onConfirmAccreditation = useCallback(() => {
    if (accreditation) setAccreditation(null);

    getAccreditation();
  }, [accreditation, setAccreditation, getAccreditation]);

  const loadingData = !!(loadingScreeningSeatTypes | loadingSeatAvailability | loadingScreening);

  const isDataReady = !!(resultScreening && screeningSeatTypes && seats);

  const isLimitReached = !!(
    selectedSeats.length >= MAX_TICKETS_PURCHASE_LIMIT ||
    (accLimit && accLimit < MAX_TICKETS_PURCHASE_LIMIT && selectedSeats.length >= accLimit)
  );

  const [
    {
      result: resultAddReservedSeats,
      loading: loadingAddReservedSeats,
      error: errorAddReservedSeats,
      setResult: setResultAddReservedSeats,
    },
    addReservedSeats,
  ] = useAddReservedSeats();

  const { cart, getCart, apiClearCartResult } = useCartContext();
  const history = useHistory();
  const currentRoute = matchRoute(history.location.pathname);

  const goToCart = useCallback(() => history.push(generateLink(routes.CART)), [history]);

  const initReservedPurchase = useCallback(
    screening => {
      setScreening(screening);
      setTicketTypeSelector(screening?.seatType?.ticketTypeSelectorId);
      setIsModalOpen(true);
    },
    [setScreening, setTicketTypeSelector, setIsModalOpen]
  );

  const resetAccreditationFlow = useCallback(() => {
    setAccreditation(null);
    setAccreditationNumber('');
    setCartAccreditationNumber('');
    setToAccTicketSelection(false);
    setAccLimit(null);
  }, [setAccreditation, setAccreditationNumber, setToAccTicketSelection, setAccLimit]);

  const exitReservedPurchase = useCallback(() => {
    setIsModalOpen(false);
    setSelectedSeats(() => []);
    setLayout(() => []);
    setCartIds(() => []);
    setTicketTypeSelector(null);
    resetAccreditationFlow();
    setScreening(null);
    setSeats(null);
    setIsSeatTaken(false);
  }, [
    setIsModalOpen,
    setLayout,
    setTicketTypeSelector,
    setSelectedSeats,
    setCartIds,
    setScreening,
    setSeats,
    resetAccreditationFlow,
    setIsSeatTaken,
  ]);

  const onBack = useCallback(() => {
    if (!ticketTypeSelector) {
      exitReservedPurchase();
    }
    setTicketTypeSelector(null);
    resetAccreditationFlow();
    setSelectedSeats(() => []);
    setIsSeatTaken(false);
  }, [resetAccreditationFlow, setSelectedSeats, setTicketTypeSelector, ticketTypeSelector, exitReservedPurchase]);

  const fetchData = useCallback(async () => {
    //? getScreening called in useScreening when id is changed
    getScreeningSeatTypes();
    getSeatAvailability();
    getCart();
  }, [getSeatAvailability, getScreeningSeatTypes, getCart]);

  const handleUnavailableSeats = useCallback(
    seat => {
      if (seat.ticketTypeSelectorId !== ticketTypeSelector) return true;
      if (selectedSeats.find(s => s.id === seat.id) && seat.ticketTypeSelectorId === ticketTypeSelector) return false;

      if (
        ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION &&
        accreditation &&
        seat.ticketTypeId !== accreditation.ticketTypeId
      ) {
        return true;
      }

      return false;
    },
    [ticketTypeSelector, selectedSeats, accreditation]
  );

  const generateHeadLabels = useCallback(() => {
    if (resultScreening) {
      setRowLabels(draft => {
        draft.splice(0, draft.length);
        // generate labels for rows
        const areRowsReversed = resultScreening?.columnsOrientation?.toString() === ORIENTATION.reverse.name.toString();
        let labels = generateLabels(areRowsReversed, resultScreening?.rowsCount);

        if (resultScreening?.rowsCount) {
          for (let i = 0; i < resultScreening?.rowsCount; i++) {
            draft.push(
              resultScreening?.rowsMarking.toLowerCase() === MARKINGS.letters.name.toLowerCase()
                ? labels.alphabetLabels[i]
                : labels.numericLabels[i]
            );
          }
        }
      });

      setColLabels(draft => {
        draft.splice(1, draft.length); // do not delete the empty cell, start from 1

        // generate labels for columns
        const areColumnsReversed = resultScreening?.rowsOrientation?.toString() === ORIENTATION.reverse.name.toString();
        let labels = generateLabels(areColumnsReversed, resultScreening?.columnsCount);

        if (resultScreening?.columnsCount) {
          for (let j = 0; j < resultScreening?.columnsCount; j++) {
            draft.push(
              resultScreening?.columnsMarking.toLowerCase() === MARKINGS.letters.name.toLowerCase()
                ? labels.alphabetLabels[j]
                : labels.numericLabels[j]
            );
          }
        }
      });
    }
  }, [resultScreening, setColLabels, setRowLabels]);

  const handleCart = useCallback(() => {
    if (cart && screening) {
      // Finding if selected screening has values in cart
      let cartScreenings = cart.cartScreenings?.filter(s => s.screeningId === getScreeningId(screening));

      if (cartScreenings) {
        let arr = [];
        let arr2 = [];

        // Making an array of seatIds
        cartScreenings.forEach(cartScreening => {
          // omit the cart items which are not of the same ticketTypeSelector
          // because the cart handles only one selector per screening
          if (ticketTypeSelector !== cartScreening.seatType.ticketTypeSelectorId) return;

          arr = [...arr, ...cartScreening.seatType.seatIds];
        });

        // Finding corresponding values for seatIds
        if (seats && seats.reservedSeats?.reservedSeats?.length > 0)
          arr.forEach(seatId => {
            let item = seats.reservedSeats.reservedSeats.find(seat => seat.id === seatId);
            if (
              item.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION &&
              accreditation &&
              accreditation.ticketTypeId !== item.ticketTypeId
            ) {
              return;
            }
            arr2 = [...arr2, item];
          });
        setCartIds(arr);
        setSelectedSeats(draft => {
          if (draft !== arr2) {
            return arr2;
          }
        });
      }
    }
  }, [cart, screening, seats, setSelectedSeats, accreditation, ticketTypeSelector]);

  // create seat layout
  const generateMatrix = useCallback(() => {
    if (
      !seats ||
      !seats.reservedSeats ||
      !seats.reservedSeats.reservedSeats ||
      seats.reservedSeats.reservedSeats.length < 1 ||
      !resultScreening
    )
      return [];
    const matrix = [];

    for (let i = 0; i < resultScreening.rowsCount; i++) {
      matrix.push([]);

      for (let j = 0; j < resultScreening.columnsCount; j++) {
        const seat = seats.reservedSeats.reservedSeats.find(s => s.row === i && s.number === j);
        if (!seat) {
          return [];
        }
        const ticketTypeSelectorId = seat.ticketTypeSelectorId;
        const seatTypeId = seat.seatTypeId;
        const isAvailable = seat.isAvailable;
        const label = isAvailable ? seat.label : '';
        const isDisabled = handleUnavailableSeats(seat);

        const isSold = seat.isSold && !cartIds.includes(seat.id);
        const isSelected = selectedSeats.find(s => s.id === seat.id) ? true : false;
        const permissions = seat.permissions;

        const item = {
          id: seat.id,
          row: i,
          number: j,
          label,
          isAvailable,
          isSelected,
          isDisabled,
          isSold,
          ticketTypeSelectorId,
          seatTypeId,
          permissions,
        };

        matrix[i][j] = item;
      }
    }
    return matrix;
  }, [seats, cartIds, handleUnavailableSeats, selectedSeats, resultScreening]);

  // on seat selection
  // * select seat if limit is not reached
  // * check accreditation limit for the respective case
  const handleSeat = useCallback(
    (x, y) => {
      if (!layout[x][y].isDisabled && !layout[x][y].isSold) {
        if (!layout[x][y].isSelected && selectedSeats.length < MAX_TICKETS_PURCHASE_LIMIT) {
          switch (ticketTypeSelector) {
            case TICKET_TYPE_SELECTORS.REGULAR:
              setSelectedSeats(draft => {
                return [...draft, layout[x][y]];
              });
              break;
            case TICKET_TYPE_SELECTORS.ACCREDITATION:
              if (accreditation && selectedSeats.length < accLimit) {
                setSelectedSeats(draft => {
                  return [...draft, layout[x][y]];
                });
              }
              break;
            default:
              return;
          }
        } else {
          setSelectedSeats(draft => draft.filter(s => s.id !== layout[x][y].id));
        }
      }
    },
    [layout, setSelectedSeats, selectedSeats, accLimit, accreditation, ticketTypeSelector]
  );

  const getCartSeatIds = useCallback(
    selector => {
      return cart && cart.cartScreenings
        ? cart.cartScreenings
            .filter(x => x.seatType?.ticketTypeSelectorId === selector)
            .map(x => x.seatType?.seatIds)
            .reduce((acc, current) => [...acc, ...current], [])
        : [];
    },
    [cart]
  );

  // backend returns sold and in-cart tickets as purchased
  // value will be used to substract from purchased tickets for limit per screening
  const getCartSeatIdsOfScreening = useCallback(() => {
    return cart && cart.cartScreenings && resultScreening
      ? cart.cartScreenings
          .filter(
            x =>
              x.seatType?.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION &&
              x.screeningId === getScreeningId(resultScreening)
          )
          .map(x => x.seatType.seatIds)
          .reduce((acc, current) => [...acc, ...current], [])
      : [];
  }, [cart, resultScreening]);

  // value will be used to substract from purchased tickets for limit per screening date
  const getCartSeatIdsOfScreeningDate = useCallback(() => {
    return cart && cart.cartScreenings && resultScreening && resultScreening.startTime
      ? cart.cartScreenings
          .filter(
            x =>
              x.seatType?.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION &&
              x.screeningDateTime === resultScreening.startTime
          )
          .map(x => x.seatType?.seatIds)
          .reduce((acc, current) => [...acc, ...current], [])
      : [];
  }, [cart, resultScreening]);

  const handleIsSubmitBtnDisabled = useCallback(() => {
    if (screening && screening.seatingType === SEATING_TYPES.RESERVED) {
      if (loadingAddReservedSeats || loadingData) return true;
      // General
      switch (ticketTypeSelector) {
        case TICKET_TYPE_SELECTORS.ACCREDITATION:
          if (accreditation && !toAccTicketSelection) return false;
          else if (!accreditation && !toAccTicketSelection) return true;
          break;
        default:
          break;
      }
      // no selected seats and no seats in cart
      if (selectedSeats.length === 0 && cartIds.length === 0) return true;
      return false;
    }
  }, [
    screening,
    loadingAddReservedSeats,
    loadingData,
    ticketTypeSelector,
    accreditation,
    toAccTicketSelection,
    selectedSeats,
    cartIds,
  ]);

  const checkDeselectedSeats = useCallback(() => {
    if (!selectedSeats || !cartIds) return false;
    return !!(selectedSeats.length === 0 && cartIds.length > 0);
  }, [selectedSeats, cartIds]);

  const isEveryCartSeatDeselected = checkDeselectedSeats();

  // purchase modal submit button
  const onSubmit = useCallback(() => {
    // When accreditaiton number is filled and user wants to proceed to ticket selection
    if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && !toAccTicketSelection) {
      setToAccTicketSelection(true);
      return;
    }
    // Submit values case
    const idsToSubmit = [];
    layout
      .reduce((a, b) => a.concat(b), [])
      .filter(i => i.isSelected)
      .forEach(i => {
        idsToSubmit.push(i.id);
      });

    let accredSeatIds = [];
    let regularSeatIds = [];
    if (ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR) {
      regularSeatIds = idsToSubmit;
      accredSeatIds = getCartSeatIds(TICKET_TYPE_SELECTORS.ACCREDITATION);
    } else if (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION) {
      accredSeatIds = idsToSubmit;
      regularSeatIds = getCartSeatIds(TICKET_TYPE_SELECTORS.REGULAR);
    }

    const values = {
      screeningId: getScreeningId(screening),
      cartId: cart?.id,
      ticketInserts: [
        {
          ticketTypeSelectorId: TICKET_TYPE_SELECTORS.REGULAR,
          seatIds: regularSeatIds,
        },
        {
          ticketTypeSelectorId: TICKET_TYPE_SELECTORS.ACCREDITATION,
          seatIds: accredSeatIds,
          accreditationId: accreditation?.id,
        },
      ],
    };

    addReservedSeats(values);
  }, [
    layout,
    screening,
    addReservedSeats,
    ticketTypeSelector,
    cart,
    toAccTicketSelection,
    accreditation,
    getCartSeatIds,
  ]);

  // generate seat matrix
  const initLayout = useCallback(() => {
    generateHeadLabels();
    const matrix = generateMatrix();
    setLayout(draft => {
      draft.splice(0, draft.length);
      matrix.forEach(m => {
        draft.push(m);
      });
    });
  }, [generateMatrix, setLayout, generateHeadLabels]);

  useEffect(() => {
    if (screening) {
      fetchData();
    }
  }, [screening, fetchData]);

  useEffect(() => {
    if (cart) {
      // refresh selected/sold seats whenever the cart is refreshed
      handleCart(cart);
      const cartScreeningAccreditationNumber = cart.cartScreenings
        ?.filter(x => x.screeningId === getScreeningId(screening))
        ?.find(x => x.seatType.ticketTypeSelectorId === TICKET_TYPE_SELECTORS.ACCREDITATION)?.seatType
        ?.accreditationNumber;

      // when the cart contains seats bought with an accreditation
      // automatically set the accreditationNumber state
      // so the modal proceeds to ticket selection
      if (cartScreeningAccreditationNumber) {
        setCartAccreditationNumber(cartScreeningAccreditationNumber);
        setAccreditationNumber(cartScreeningAccreditationNumber);
      }
    }
  }, [cart, handleCart, screening, setAccreditationNumber, setCartAccreditationNumber]);

  // when there is a ticket in cart which is bought with an accreditation
  useEffect(() => {
    if (cartAccreditationNumber) {
      getAccreditation();
    }
  }, [cartAccreditationNumber, getAccreditation, setToAccTicketSelection]);

  // when accreditation is fetched, set the proper seat selection limit
  useEffect(() => {
    if (accreditation) {
      if (accreditation.ticketsLimitDaily < accreditation.ticketsLimitPerScreening) {
        setAccLimit(
          accreditation.ticketsLimitDaily - (accreditation.ticketsUsedForDay - getCartSeatIdsOfScreeningDate().length)
        );
      } else {
        setAccLimit(
          accreditation.ticketsLimitPerScreening -
            (accreditation.ticketsUsedForScreening - getCartSeatIdsOfScreening().length)
        );
      }
    }
  }, [
    setAccLimit,
    accreditation,
    handleCart,
    cart,
    getCartSeatIds,
    getCartSeatIdsOfScreening,
    getCartSeatIdsOfScreeningDate,
  ]);

  // when the cart contains tickets bought with an accreditation
  // proceed automatically to ticket selection
  // so the user does not need to enter an accreditation number
  useEffect(() => {
    if (accreditation && cartAccreditationNumber) {
      setToAccTicketSelection(true);
    }
  }, [accreditation, cart, handleCart, setToAccTicketSelection, cartAccreditationNumber]);

  // when screening is selected, and all related data is fetched
  // generate seat matrix
  useEffect(() => {
    if (isDataReady) {
      initLayout();
    }
  }, [initLayout, isDataReady]);

  // when seats are bought after selection
  useEffect(() => {
    if (resultAddReservedSeats) {
      setIsSeatTaken(false);
      exitReservedPurchase();
      getCart();
    }
  }, [resultAddReservedSeats, getCart, exitReservedPurchase]);

  // when there is an error while adding tickets to cart
  // exit modal and reset all data
  useEffect(() => {
    if (errorAddReservedSeats) {
      if (errorAddReservedSeats?.response?.status === 400) {
        setIsSeatTaken(true);
        getSeatAvailability();
      } else {
        exitReservedPurchase();
      }
    }
  }, [errorAddReservedSeats, exitReservedPurchase, setIsSeatTaken, getSeatAvailability]);

  // when tickets are added to cart
  useEffect(() => {
    if (resultAddReservedSeats && !errorAddReservedSeats && currentRoute.path !== routes.CART.path) {
      setTimeout(() => {
        goToCart();
        // reset the response object so a change can be detected by other effects
        setResultAddReservedSeats(null);
      }, 400);
    }
  }, [resultAddReservedSeats, goToCart, currentRoute, setResultAddReservedSeats, errorAddReservedSeats]);

  // when cart is cleared in Cart view
  // reset all related state objects
  // so a change can be detected on next screening selection
  useEffect(() => {
    if (apiClearCartResult) {
      setSelectedSeats(() => []);
      setLayout(() => []);
      setSeats(null);
      setCartIds(() => []);
      getCart();
      getSeatAvailability();
    }
  }, [apiClearCartResult, getCart, getSeatAvailability, setSelectedSeats, setCartIds, setLayout, setSeats]);

  const context = useMemo(
    () => ({
      screening,
      ticketTypeSelector,
      setTicketTypeSelector,
      isModalOpen,
      initReservedPurchase,
      exitReservedPurchase,
      screeningSeatTypes,
      colLabels,
      rowLabels,
      layout,
      handleSeat,
      onSubmit,
      resultAddReservedSeats,
      loadingData,
      onBack,
      setColLabels,
      setRowLabels,
      isLoading,
      setIsLoading,
      errorScreening,
      errorSeatAvailability,
      errorScreeningSeatTypes,
      loadingAddReservedSeats,
      isLimitReached,
      getAccreditation,
      accreditationNumber,
      accreditation,
      accreditationLoading,
      accreditationError,
      toAccTicketSelection,
      handleAccreditationChange,
      onConfirmAccreditation,
      resultScreening,
      handleIsSubmitBtnDisabled,
      isEveryCartSeatDeselected,
      isSeatTaken,
      setIsSeatTaken,
      getCartSeatIdsOfScreening,
      getCartSeatIdsOfScreeningDate,
    }),
    [
      screening,
      ticketTypeSelector,
      setTicketTypeSelector,
      isModalOpen,
      initReservedPurchase,
      exitReservedPurchase,
      screeningSeatTypes,
      colLabels,
      rowLabels,
      layout,
      handleSeat,
      onSubmit,
      resultAddReservedSeats,
      loadingData,
      onBack,
      setColLabels,
      setRowLabels,
      isLoading,
      setIsLoading,
      errorScreening,
      errorSeatAvailability,
      errorScreeningSeatTypes,
      loadingAddReservedSeats,
      isLimitReached,
      getAccreditation,
      accreditationNumber,
      accreditation,
      accreditationLoading,
      accreditationError,
      toAccTicketSelection,
      handleAccreditationChange,
      onConfirmAccreditation,
      resultScreening,
      handleIsSubmitBtnDisabled,
      isEveryCartSeatDeselected,
      isSeatTaken,
      setIsSeatTaken,
      getCartSeatIdsOfScreening,
      getCartSeatIdsOfScreeningDate,
    ]
  );

  return <ReservedPurchaseContext.Provider value={context}>{props.children}</ReservedPurchaseContext.Provider>;
};

export function useReservedPurchaseContext() {
  const context = useContext(ReservedPurchaseContext);
  if (!context) {
    throw new Error(`ReservedPurchaseContext must be used within a ReservedPurchaseProvider`);
  }
  return context;
}

export const Consumer = ReservedPurchaseContext.Consumer;
