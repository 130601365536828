import React, { Suspense } from 'react';
import GlobalStyle from 'globalStyle';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';

import 'utils/localization/i18n';
import Root from 'components/Root/Root';
import CustomBrowserRouter from 'utils/customHistory';
import notificationMiddleware from 'redux/customMiddlewares/notificationMiddleware';
import { sessionReducer, uiReducer } from 'redux/reducers';
import { RootProvider } from 'context/RootContext';
import { Loader } from 'components';

const rootReducer = combineReducers({
  session: sessionReducer,
  ui: uiReducer,
});

const middlewares = [thunkMiddleware, notificationMiddleware];

// use logger middleware only while developing
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

const App = () => (
  <Provider store={store}>
    <GlobalStyle />
    <CustomBrowserRouter>
      <RootProvider>
        <Suspense fallback={<Loader />}>
          <Root />
        </Suspense>
      </RootProvider>
    </CustomBrowserRouter>
  </Provider>
);

export default App;
