import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';
import styled from 'styled-components';

import { ErrorMessage } from 'components';

const Checkbox = ({ label, name, validationMessage, className, disabled, ...rest }) => {
  return (
    <CheckboxWrapper className={className}>
      <CheckboxInput name={name} id={name} label={label} type="checkbox" disabled={disabled} {...rest} />
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  width: PropTypes.string || PropTypes.number,
  label: PropTypes.object,
  validationMessage: PropTypes.string,
};

const CheckboxInput = styled(CustomInput)`
  color: #2f3036;
  > * {
    cursor: pointer;
    font-size: 14px;
    line-height: 1.825;
  }
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export default Checkbox;
