import React from 'react';
import { CenterContent } from 'components';
import { Spinner as DefaultSpinner } from 'reactstrap';
import styled from 'styled-components';

const Loader = () => {
  return (
    <CenterContent>
      <Spinner className="m-5" color="secondary" />
    </CenterContent>
  );
};

const Spinner = styled(DefaultSpinner)`
  width: 5rem;
  height: 5rem;
`;

export default Loader;
