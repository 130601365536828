import styled from 'styled-components';

import DatePickerWrapper from './DatePickerWrapper';

export const PortalDatePicker = styled(DatePickerWrapper)`
  background-color: transparent;
  box-shadow: 0 0 0 0;
  .react-datepicker {
    background-color: transparent;
    border: 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.75rem;
    line-height: 2.75rem;
  }

  .react-datepicker__month {
    background-color: transparent;
    padding: 0 1rem 1rem 1rem;
  }
  .react-datepicker__month-container {
    box-shadow: 0 0 0 0;
  }
  .react-datepicker__header {
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    background-color: transparent;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: var(--primary);
    border-radius: 100%;
  }

  .react-datepicker__day--selected.react-datepicker__day--highlighted {
    border-radius: 100%;
    color: var(--light);
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover {
    border-radius: 100%;
    color: var(--light);
  }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:hover,
  .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:hover {
    background-color: var(--light);
    color: var(--dark);
  }

  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    margin: 1rem 1.5rem;
    border: solid 2px var(--defaultText);
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--selectt {
    height: 54.5px;
  }
  .react-datepicker__year-select,
  .react-datepicker__month-select {
    height: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    color: var(--defaultText);
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation {
    display: none;
    top: 37px;
  }
  .react-datepicker__navigation--next {
    right: 30px;
  }
  .react-datepicker__navigation--previous {
    left: 30px;
  }
  .react-datepicker__current-month,
  .react-datepicker__current-month--hasYearDropdown,
  .react-datepicker__current-month--hasMonthDropdown {
    display: none;
    font-size: 1.5rem;
    font-weight: 300;
  }

  @media only screen and (max-width: 380px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__navigation--next {
      right: 20px;
    }
    .react-datepicker__navigation--previous {
      left: 20px;
    }
    & .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
      margin: 1rem;
    }
  }
`;

export default PortalDatePicker;
