import { uiActions } from 'redux/actions';

const notificationMiddleware = store => next => action => {
  const result = next(action);
  const { notification } = action;

  if (notification) {
    store.dispatch(uiActions.showNotification(notification));
  }

  return result;
};

export default notificationMiddleware;
