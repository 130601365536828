import React from 'react';
import styled from 'styled-components';

import { Dropdown, DropdownToggleButton, Checkbox } from 'components';

const MultiselectDropdown = ({ toggleText, items, isCheckedHandler, onChangeHandler, disabled }) => {
  const Toggle = () => <DropdownToggleButton disabled={disabled}>{toggleText}</DropdownToggleButton>;

  return (
    <Dropdown ToggleElem={Toggle}>
      {items.map(item => (
        <CheckboxItem key={item.id}>
          <Checkbox
            label={item.name}
            id={`checkbox-${item.id}-${item.name}`}
            type="checkbox"
            name="multipleChoiceFilter"
            checked={isCheckedHandler(item.id)}
            onChange={e => onChangeHandler(e, item.id)}
          />
        </CheckboxItem>
      ))}
    </Dropdown>
  );
};

const CheckboxItem = styled.div`
  padding: 0.25rem 1.5rem;
  &:first-child {
    padding-top: 0.25rem;
  }
  &:last-child {
    padding-bottom: 0.25rem;
  }
  & > div {
    margin-bottom: 0;
  }
`;

export default MultiselectDropdown;
