import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DefaultInput from 'components/Form/Input';
import { DEBOUNCE_TIMEOUT, LOCALIZATION_PAGES } from 'utils/constants';
import { useDebounce } from 'utils/hooks';

const FilterInput = ({ searchTerm = '', placeholder = '', onSearch, ...rest }) => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const [keyword, setKeyword] = useState(searchTerm);
  const debouncedSearchTerm = useDebounce(keyword, DEBOUNCE_TIMEOUT);

  if (!placeholder) {
    placeholder = tCommon('search_text');
  }

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  const onChange = e => {
    const search = e.target.value;
    setKeyword(search);
  };

  return (
    <InputWrapper>
      <Input id="search-filter" placeholder={placeholder} onChange={onChange} value={keyword} {...rest} />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Input = styled(DefaultInput)`
  &.form-control {
    height: 54.5px;
    border-radius: 4px;
    border: solid 2px #f5f5f5;
    background-color: #ffffff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 285px;
    max-width: 100%;
  }
  &.form-control::placeholder {
    font-size: 13px;
    font-weight: bold;
    color: #666666;
    opacity: 0.29;
  }
`;

export default FilterInput;
