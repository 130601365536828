import React from 'react';
import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';

import { InputLabel, ErrorMessage, DropdownToggleButton, Dropdown as DefaultDropdown, Text } from 'components';

const SelectInput = ({ label = '', disabled, validationMessage, items, value, onChange, onBlur, ...rest }) => {
  const handleChange = payload => onChange(payload);

  const Toggle = () => (
    <ToggleButton isInput={true} disabled={disabled}>
      <Text fontWeight="bold" fontSize="0.75rem" color="textGrey">
        {value && value.name}
      </Text>
    </ToggleButton>
  );

  return (
    <>
      {label && (
        <InputLabel textTransform="none" lineHeight="2.5" fontWeight="500">
          {label}
        </InputLabel>
      )}
      <DefaultDropdown className="w-100" ToggleElem={Toggle} {...rest}>
        {items.map((item, i) => (
          <DropdownItem key={`${i}-${item.name}`} onClick={() => handleChange(item.id)} active={value === item}>
            <DropdownItemText>{item.name}</DropdownItemText>
          </DropdownItem>
        ))}
      </DefaultDropdown>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </>
  );
};

const DropdownItemText = styled.span`
  color: ${({ color }) => (color ? color : '#2f3036')};
  font-size: 0.875rem;
  line-height: 1.57;

  &:hover {
    color: var(--white);
  }
`;

const ToggleButton = styled(DropdownToggleButton)`
  min-width: 100%;
  padding: 0 1rem !important;
`;

export default SelectInput;
