import produce from 'immer';

import constants from 'redux/constants';

const INITIAL_STATE = {
  notification: null,
};

const ui = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.ui.SHOW_NOTIFICATION:
      return produce(state, draft => {
        draft.notification = payload.notification;
      });
    default:
      return state;
  }
};

export default ui;

export const getNotification = state => state.ui.notification;
