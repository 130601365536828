import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button as DefaultButton, Input as DefaultInput, Spinner } from 'reactstrap';

import { Text } from 'components';
import { LOCALIZATION_PAGES } from 'utils/constants';

const CustomerAccreditation = ({ onChange, onClick, accreditationNumber, accreditation, loading, error }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);

  return (
    <div className="h-75 my-md-3 mx-md-5 px-3 px-md-5">
      <div className="h-100 m-sm-5 d-flex flex-column align-items-center justify-content-center">
        <Title title={t('accreditation_modal_title')} />

        <Description description={t('accreditation_modal_description')} />

        <div className="w-100 mx-5 d-flex justify-content-center">
          <Input
            maxLength="15"
            defaultValue={accreditationNumber}
            disabled={loading}
            valid={accreditation ? true : false}
            invalid={error ? true : false}
            onChange={onChange}
          />
          <Button outline onClick={onClick} disabled={loading || !accreditationNumber.length}>
            {loading ? <Spinner size="sm" /> : tCommon('check')}
          </Button>
        </div>

        <AccreditationMsg className="w-100 ml-5 ml-sm-0 mr-xl-auto ml-xl-auto mb-5" isValid={!error}>
          {accreditation && t('accreditation_valid')}
          {error && t('accreditation_invalid')}
        </AccreditationMsg>
      </div>
    </div>
  );
};

const Title = ({ title }) => (
  <Text className="mt-3 mb-4 text-center" fontSize="1rem" fontWeight="500" lineHeight="1.5">
    {title}
  </Text>
);

const Description = styled(({ description, ...rest }) => (
  <Text fontWeight="500" lineHeight="1.86" color="grey" {...rest}>
    {description}
  </Text>
))`
  margin-bottom: 4rem;
  max-width: 340px;
  text-align: center;
`;

const Button = styled(DefaultButton)`
  && {
    min-width: 93px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    border: solid 2px #d8d8d8;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: var(--white);

    &:focus,
    &:hover {
      box-shadow: none;
      border-color: #d8d8d8;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 1;
    }
  }
`;

const Input = styled(DefaultInput)`
  && {
    height: 50px;
    min-width: 180px;
    max-width: 323px;
    font-size: 0.875rem;
    color: var(--defaultText);
    border: solid 2px #d8d8d8;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--white);

    &.is-valid {
      border: solid 2px #8ed27f;
      background-color: rgba(45, 155, 18, 0.08);
    }

    &:focus {
      box-shadow: none;
      border-color: #d8d8d8;
    }
  }
`;

const AccreditationMsg = styled.p`
  max-width: 378px;
  opacity: 0.62;
  font-size: 0.875rem;
  line-height: 1.86;
  margin-right: 2.5rem;

  ${({ isValid }) =>
    isValid
      ? css`
          color: #00b612;
        `
      : css`
          color: var(--red);
        `}
`;

export default CustomerAccreditation;
