import React from 'react';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Button from 'components/Button/Button';

const DropdownButtonMimic = ({ isInput = false, color = 'primary', disabled, onClick, children, ...rest }) => {
  return (
    <ToggleButton color={color} disabled={disabled} onClick={onClick} {...rest}>
      <TextWrapper>{children}</TextWrapper>
      <IconWrapper>
        <ArrowIcon />
      </IconWrapper>
    </ToggleButton>
  );
};

const ToggleButton = styled(Button)`
  min-width: 174.3px;
  max-width: 174.3px;

  &.btn.btn-secondary {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 54.5px;
    border-radius: 4px;
    border: solid 2px #f5f5f5;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &&:hover,
    &&:focus {
      background-color: transparent;
      color: #666666;
      border: solid 2px #f5f5f5;
    }
    &:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: transparent;
      color: #666666;
    }
  }

  &:disabled {
    opacity: 0.3;
    ${({ color }) => {
      switch (color) {
        case 'light':
          return css`
            border: solid 1px #d8dce6;
          `;
        default:
          return;
      }
    }}
  }
`;

const TextWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
  color: #666666;
`;

const IconWrapper = styled.div`
  display: inline;
  position: relative;
  margin-left: 40px;
`;

const ArrowIcon = styled(MdKeyboardArrowDown)`
  opacity: 50%;
  position: absolute;
  top: -6px;
  left: -10px;
`;

export default DropdownButtonMimic;
