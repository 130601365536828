import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { TICKET_TYPE_SELECTORS, LOCALIZATION_PAGES } from 'utils/constants';
import { useCurrentWidth } from 'utils/hooks';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';

import { PurchaseModal } from 'components';
import TransformableSeats from 'components/Seating/TransformableSeats';
import SeatTypesLegend from 'components/Seating/SeatTypesLegend';
import ScreeningBasicInfo from '../ScreeningBasicInfo';
import Loader from 'components/Loader/Loader';
import CustomerAccreditation from '../CustomerAccreditation';
import CustomerAccreditationInfo from '../CustomerAccreditationInfo';

const ReservedPurchase = () => {
  const {
    resultScreening,
    ticketTypeSelector,
    setTicketTypeSelector,
    isModalOpen,
    exitReservedPurchase,
    colLabels,
    rowLabels,
    layout,
    screeningSeatTypes,
    ticketTypes,
    handleSeat,
    onSubmit,
    loadingData,
    onBack,
    isLimitReached,
    handleAccreditationChange,
    accreditationNumber,
    accreditationError,
    accreditationLoading,
    onConfirmAccreditation,
    accreditation,
    toAccTicketSelection,
    loadingAddReservedSeats,
    handleIsSubmitBtnDisabled,
    isEveryCartSeatDeselected,
    isSeatTaken,
    setIsSeatTaken,
    getCartSeatIdsOfScreening,
    getCartSeatIdsOfScreeningDate,
  } = useReservedPurchaseContext();
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const currentWidth = useCurrentWidth();

  const ticketsCountAndPrice = [
    layout.reduce((a, b) => a.concat(b), []).filter(i => i.isSelected).length,
    screeningSeatTypes
      ? layout
          .reduce((a, b) => a.concat(b), [])
          .filter(i => i.isSelected)
          .reduce(
            (acc, seat) =>
              acc +
              parseInt(
                screeningSeatTypes.find(x => {
                  return x.id === seat.seatTypeId || x.seatType.id === seat.seatTypeId;
                })?.price
              ),
            0
          )
      : 0,
  ];

  return (
    <PurchaseModal
      width={currentWidth}
      isOpen={isModalOpen}
      ticketTypeSelector={ticketTypeSelector}
      setTicketTypeSelector={setTicketTypeSelector}
      isLimitReached={isLimitReached}
      accreditation={accreditation}
      toAccTicketSelection={toAccTicketSelection}
      loading={loadingAddReservedSeats}
      fetchLoading={loadingData}
      ticketsCountAndPrice={ticketsCountAndPrice}
      onConfirm={onSubmit}
      toggle={exitReservedPurchase}
      onBack={onBack}
      screening={resultScreening}
      isSubmitDisabled={handleIsSubmitBtnDisabled}
      isEveryCartSeatDeselected={isEveryCartSeatDeselected}
      isSeatTaken={isSeatTaken}
      setIsSeatTaken={setIsSeatTaken}
    >
      {ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR ||
      (ticketTypeSelector === TICKET_TYPE_SELECTORS.ACCREDITATION && accreditation && toAccTicketSelection) ? (
        <div className="px-4">
          {loadingData ? (
            <Row>
              <Col>
                <Loader />
              </Col>
            </Row>
          ) : (
            <>
              <Row noGutters>
                <Col xs="12" sm="6">
                  <ScreeningBasicInfo screening={resultScreening} className={accreditation ? 'mb-3' : 'mb-4 mb-lg-5'} />
                </Col>
                {accreditation && (
                  <Col xs="12" sm={{ size: 5, offset: 1 }}>
                    <CustomerAccreditationInfo
                      accreditation={accreditation}
                      ticketsInCartPerScreening={getCartSeatIdsOfScreening().length}
                      ticketsInCartPerScreeningDate={getCartSeatIdsOfScreeningDate().length}
                    />
                  </Col>
                )}
              </Row>
              <Row noGutters className="mb-4 mb-lg-5">
                {layout && layout.length > 0 ? (
                  <>
                    <Col md="12" lg="6" className="d-flex justify-content-center">
                      <div>
                        <TransformableSeats
                          colLabels={colLabels}
                          rowLabels={rowLabels}
                          layout={layout}
                          seatTypes={screeningSeatTypes}
                          ticketTypes={ticketTypes}
                          onSeatClick={handleSeat}
                        />
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      sm={{ size: 8, offset: 2 }}
                      lg={{ size: 5, offset: 1 }}
                      className="d-flex flex-column align-items-stretch  mb-4 mb-lg-5"
                    >
                      <SeatTypesLegend />
                    </Col>
                  </>
                ) : (
                  <span className="flex-grow-1 text-center mb-3">{t('no_layout')}</span>
                )}
              </Row>
            </>
          )}
        </div>
      ) : (
        <CustomerAccreditation
          onChange={handleAccreditationChange}
          accreditationNumber={accreditationNumber}
          accreditation={accreditation}
          error={accreditationError}
          loading={accreditationLoading}
          onClick={onConfirmAccreditation}
        />
      )}
    </PurchaseModal>
  );
};

export default ReservedPurchase;
