import React from 'react';
import styled, { css } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const CenterContent = ({ url, children }) => {
  return (
    <FullHeightContainer cover={url} fluid>
      <FullHeightRow>
        <XYCenteredCol>{children}</XYCenteredCol>
      </FullHeightRow>
    </FullHeightContainer>
  );
};

const FullHeightContainer = styled(Container)`
  height: 100%;
  position: relative;

  ${({ cover }) =>
    cover &&
    css`
&:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.06;
    background-image: 'url(${cover})';
    background-size: cover;
    content: '';
  }`}
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const XYCenteredCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default CenterContent;
