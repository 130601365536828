import moment from 'moment';

import { LANGUAGES, SOCIAL_FLAG } from 'utils/constants';

export const getDefaultLanguage = () => {
  const lang = LANGUAGES.find(l => l.DEFAULT);
  return lang || LANGUAGES[0];
};

export const isNumber = n => !isNaN(parseFloat(n)) && isFinite(n);

export const handleData = (value, shouldReduce, maxLength = 20) => {
  if (typeof value === 'string' && shouldReduce) {
    if (value.length > maxLength) {
      let words = value.split(' ');
      let totalCharacters = 0;
      let summaryWords = [];

      words.forEach(w => {
        if (totalCharacters > maxLength) {
          return;
        }

        summaryWords.push(w);
        totalCharacters += w.length + 1; // +1 is for empty space between words
      });

      value = `${summaryWords.join(' ')}${summaryWords.length !== words.length ? '...' : ''}`;
    }
  }

  return value || value === 0 ? value : '-';
};

export const cutContent = (value, maxLength = 20) => {
  if (value.length > maxLength) {
    let words = value.split(' ');
    let totalCharacters = 0;
    let summaryWords = [];

    words.forEach(w => {
      if (totalCharacters > maxLength) {
        return;
      }

      summaryWords.push(w);
      totalCharacters += w.length + 1; // +1 is for empty space between words
    });

    value = `${summaryWords.join(' ')}${summaryWords.length !== words.length ? '...' : ''}`;
  }

  return value;
};

export const arrayToCsv = (array, separator = ', ') => {
  return Array.isArray(array) && array.map((x, i, a) => (i + 1 < a.length ? `${x}${separator}` : x));
};

export const toLocalDate = start =>
  start &&
  moment(moment.utc(start).toDate())
    .local()
    .format();

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const generateNumericArray = arrLength => {
  return Array.from(Array(arrLength), (_, i) => i + 1);
};

// used on Venues, Screenings, ScreeningPreview and ReservedSeatingPreview
export const generateLabels = (isReversed, lablesLength) => {
  let alphabetLabels = [];
  let numericLabels = [];

  if (isReversed) {
    alphabetLabels = alphabet.slice(0, lablesLength).reverse();
    numericLabels = generateNumericArray(lablesLength).reverse();
  } else {
    alphabetLabels = alphabet.slice(0, lablesLength + 1);
    numericLabels = generateNumericArray(lablesLength);
  }

  let generatedLabels = [];
  generatedLabels['alphabetLabels'] = alphabetLabels;
  generatedLabels['numericLabels'] = numericLabels;

  return generatedLabels;
};

export const setSocialAuthenticationFlag = value => {
  localStorage.setItem(SOCIAL_FLAG, JSON.stringify(value));
};

export const removeSocialAuthenticationFlag = () => {
  localStorage.removeItem(SOCIAL_FLAG);
};

export function getSocialAuthenticationFlag() {
  try {
    return JSON.parse(localStorage.getItem(SOCIAL_FLAG));
  } catch (error) {
    return null;
  }
}
