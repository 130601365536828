import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { Text } from 'components/Typography';
import { useReservedPurchaseContext } from 'context/ReservedPurchaseContext';
import { handleData } from 'utils/helpers';
import { LOCALIZATION_PAGES, TICKET_TYPE_SELECTORS, CURRENCY } from 'utils/constants';

const SeatsLegend = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { screeningSeatTypes, ticketTypeSelector } = useReservedPurchaseContext();

  const staticSeats = [
    { screeningId: 4, color: '#d9d9d9', seatTypeName: t('unavailable_seat'), opacity: '0.2;' },
    { screeningId: 5, color: '#f63275', seatTypeName: t('sold_seat') },
    { screeningId: 6, color: '#1e2025', seatTypeName: t('selected_seat'), border: true },
  ];

  const priceHandler = price => {
    return (
      price > -1 &&
      `${Number.parseFloat(ticketTypeSelector === TICKET_TYPE_SELECTORS.REGULAR ? price : 0).toFixed(2)} ${
        CURRENCY.BAM
      }`
    );
  };

  const DisplaySeatTypeLegend = ({ seatTypes, ...rest }) => {
    return (
      <div {...rest}>
        {seatTypes &&
          seatTypes.map((s, i) => {
            return (
              <React.Fragment key={`${s.screening ? s.screening.id : s.screeningId}.${i}`}>
                <Row className="justify-content-between">
                  <Col xs="8">
                    <Row className="flex-wrap align-items-center my-2">
                      <Col xs="2">
                        <SeatColor color={s.color} border={s.border} opacity={s.opacity} />
                      </Col>
                      <Col xs="10">
                        <CustomText>{handleData(s.seatType ? s.seatType.name : s.seatTypeName)}</CustomText>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="4" className="d-flex align-items-center justify-content-end">
                    <CustomText>{priceHandler(s.price)}</CustomText>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <DisplaySeatTypeLegend seatTypes={screeningSeatTypes} />
      <DisplaySeatTypeLegend seatTypes={staticSeats} className="mt-4" />
    </>
  );
};

const SeatColor = styled.div`
  width: 20px;
  height: 20px;
  border: ${({ border }) => border && '1px solid #f63275'};
  background-color: ${({ color }) => (color ? color : null)};
  opacity: ${({ opacity }) => (opacity ? opacity : null)};
`;

const CustomText = styled(Text)`
  color: #999999;
  margin: 0;
`;

export default SeatsLegend;
