import { useEffect } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const path = '/external/carts/tickets/distributed';

const useAddToCartDistributed = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.PURCHASE);
  const { showNotification } = useRoot();
  const [{ result, loading, error, setResult }, addToCart] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  /* eslint-disable */
  useEffect(() => {
    if (result) {
      showNotification({
        message: t('add_to_cart_success'),
      });
    }
  }, [result, showNotification]);

  useEffect(() => {
    if (error) {
      console.log(error);
      const msg = error.status === 422 ? error.data.Message : t('add_to_cart_fail')
      showNotification(
        {
          message: msg,
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification]);
  /* eslint-enable */

  return [{ result, loading, error, setResult }, addToCart];
};

export default useAddToCartDistributed;
