import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  DropdownToggle as DropdownToggleDefault,
  Dropdown,
  DropdownMenu,
  DropdownItem as DropdownItemDefault,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

import { getLanguage } from 'redux/reducers/sessionReducer';
import { sessionActions } from 'redux/actions';
import { LANGUAGES } from 'utils/constants';

const Select = ({ open, value, items, labelKey, placeholder, onSelect }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);

  const [isOpen, setIsOpen] = useState(open);

  const activeLanguageName = value ? value[labelKey] : placeholder || items[0][labelKey];

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle color="transparent" caret>
        <CountryFlag iconPath={value.iconPath} />
        {/* <span>{value ? value[labelKey] : placeholder || items[0][labelKey]}</span> */}
        <span>{t(`${activeLanguageName}`)}</span>
      </DropdownToggle>
      <DropdownMenu>
        {items.map((item, i) => {
          return (
            <DropdownItem key={i} onClick={() => onSelect(item)}>
              {t(`${item[labelKey]}`)}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

const LanguageSelect = ({ ...rest }) => {
  const language = useSelector(getLanguage);
  const dispatch = useDispatch();

  const Toggle = () => {
    const langConfig = LANGUAGES.find(l => l.value === language.value);

    return <CustomToggle block>{langConfig && langConfig.value}</CustomToggle>;
  };

  const onSelect = lng => (language.id !== lng.id ? dispatch(sessionActions.setLanguage(lng)) : {});

  return (
    <LanguageContainer>
      <Select
        width="100%"
        value={language}
        items={LANGUAGES}
        labelKey="name"
        onSelect={onSelect}
        ToggleElem={Toggle}
        {...rest}
      />
    </LanguageContainer>
  );
};

const LanguageContainer = styled.div`
  align-items: center;
`;

const DropdownToggle = styled(DropdownToggleDefault)`
  font-size: 14px !important;
  padding: 0 !important;
  color: #666666 !important;

  @media (max-width: 767px) {
    font-weight: 700 !important;
  }
`;

const CustomToggle = styled(DropdownToggleDefault)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownItem = styled(DropdownItemDefault)`
  font-size: 14px;
`;

const CountryFlag = styled(props => {
  return (
    <img
      src={props.iconPath}
      // it does not apply styles in styled components ``
      style={{
        width: '20px',
        paddingRight: '4px',
      }}
      alt="Country flag"
    />
  );
})``;

export default LanguageSelect;
