import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import FilterInput from 'components/FilterInput/FilterInput';
import { FilterDropdown, ButtonDropdown, Title } from 'components';
import { useCurrentWidth } from 'utils/hooks';
import { SCREEN_BREAKPOINTS, LOCALIZATION_PAGES } from 'utils/constants';

const ListHeader = ({
  title,
  onSearch,
  searchTerm,
  searchPlaceholder,
  disabled,
  sortItems,
  sortByItem,
  applyFilters,
  resetFilters,
  contentOverflow,
  minScreen = SCREEN_BREAKPOINTS.SM,
  maxScreen = SCREEN_BREAKPOINTS.XL,
  children,
  isFiltered,
  ...rest
}) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const currentScreenWidth = useCurrentWidth();

  const Sort = () => {
    return (
      sortItems &&
      sortByItem && (
        <>
          <div className="ml-auto"></div>
          <SortLabel>{`${t('sort_by')}:`}</SortLabel>
          <ButtonDropdown items={sortItems} selectedItem={sortByItem} />
        </>
      )
    );
  };
  return (
    <Wrapper {...rest}>
      {title && currentScreenWidth > minScreen && (
        <Row className="py-5">
          <Col>
            <Title>{title}</Title>
          </Col>
        </Row>
      )}
      <Row>
        {currentScreenWidth > minScreen ? (
          <>
            <Col className="col-auto pr-0">
              <FilterInput
                onSearch={onSearch}
                searchTerm={searchTerm}
                disabled={disabled}
                placeholder={searchPlaceholder}
              />
            </Col>
            <Col className="w-100">
              <div id="filter-list-wrapper">
                {currentScreenWidth > maxScreen ? (
                  <div id="filter-list" className=" d-flex align-items-center justify-content-end">
                    {Array.isArray(children) ? (
                      Object.values(children).map((x, index, array) => {
                        return (
                          <div key={index} className={`${index + 1 < array.length ? 'mr-3' : ''}`}>
                            {x}
                          </div>
                        );
                      })
                    ) : (
                      <>{children}</>
                    )}
                    <Sort />
                  </div>
                ) : (
                  <div id="filter-list-dropdown" className="text-right">
                    <FilterDropdown applyFilters={applyFilters} resetFilters={resetFilters} isFiltered={isFiltered}>
                      {children}
                      <Sort />
                    </FilterDropdown>
                  </div>
                )}
              </div>
            </Col>
          </>
        ) : (
          <Col className="d-flex justify-content-between">
            {title && <Title>{title}</Title>}
            <div>
              <FilterDropdown applyFilters={applyFilters} resetFilters={resetFilters} isFiltered={isFiltered}>
                <FilterDropdownSearch
                  onSearch={onSearch}
                  searchTerm={searchTerm}
                  disabled={disabled}
                  placeholder={searchPlaceholder}
                />
                {children}
                <Sort />
              </FilterDropdown>
            </div>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  margin-bottom: 2rem;
`;

const FilterDropdownSearch = styled(FilterInput)`
  &.form-control {
    width: 90vw;
    height: 42px;
    line-height: 42px;
  }
`;

const SortLabel = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #b3bac5;
  margin-right: 0.5rem;
  min-width: fit-content;
`;

export default ListHeader;
