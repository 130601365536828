import React from 'react';
import { PaginationItem as BootstrapPaginationItem, PaginationLink } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PaginationItem = ({ visible = true, number, onClick, linkAttrs, ...rest }) => {
  return visible ? (
    <Wrapper {...rest}>
      <PaginationLink {...linkAttrs} onClick={() => onClick(number)}>
        {linkAttrs && (linkAttrs.previous ? <CaretLeft /> : <CaretRight />)}
        {!linkAttrs && <span>{number}</span>}
      </PaginationLink>
    </Wrapper>
  ) : null;
};

const Wrapper = styled(BootstrapPaginationItem)`
  cursor: pointer;
`;

const CaretLeft = styled.span`
  opacity: 70%;
`;

const CaretRight = styled.span`
  opacity: 70%;
`;

PaginationItem.propTypes = {
  number: PropTypes.number,
  linkAttrs: PropTypes.object,
  onClick: PropTypes.func,
};

export default PaginationItem;
