import moment from 'moment';

export const TOKEN_KEY = 'SFF-EXTERNAL-JWT-TOKEN';
export const REFRESH_TOKEN_KEY = 'REFRESH-TOKEN';
export const ACCESS_TOKEN_KEY = 'SFF-EXTERNAL-JWT-ACCESS-TOKEN';
export const LANG_KEY = 'CURRENT_LANGUAGE';
export const USER = 'CURRENT_USER';
export const DEFAULT_PAGE_SIZE = 10;
export const DEBOUNCE_TIMEOUT = 300;
export const NOTIFICATION_DISMISS_TIMEOUT = 4000;
export const MAX_TICKETS_PURCHASE_LIMIT = 4;
export const DEFAULT_POSTER = `${process.env.PUBLIC_URL}/assets/img/noposter.jpg`;
export const BA_FLAG = `${process.env.PUBLIC_URL}/assets/media/flags/ba.svg`;
export const EN_FLAG = `${process.env.PUBLIC_URL}/assets/media/flags/en.svg`;
export const SOCIAL_FLAG = 'SOCIAL_FLAG';

const today = new Date();

export const CURRENCY = {
  KM: 'KM',
  BAM: 'BAM',
};

export const SEATING_TYPES = {
  DISTRIBUTION: 'DISTRIBUTION',
  RESERVED: 'RESERVED',
};

export const SALE_TYPES = {
  POS: { id: -1, name: 'POS' },
  ONLINE: { id: -2, name: 'Online' },
};

export const TICKET_TYPE_SELECTORS = {
  ACCREDITATION: -1,
  REGULAR: -2,
};

export const SORT_ORDER = {
  ASC: 0,
  DESC: 1,
};

export const USER_PROFILE_ENUMS = {
  PURCHASES: 'purchases',
  SETTINGS: 'settings',
};

export const USER_PROFILE_SETTINGS_ENUMS = {
  PERSONAL_INFO: 'personal_info',
  PASSWORD_AND_SECURITY: 'password_and_security',
};

export const GENDER_TYPES = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  PREFER_NOT_TO_SAY: 'PREFER_NOT_TO_SAY',
};

export const SCREEN_BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const HTTP_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  OK: 200,
  NOT_FOUND: 404,
};

export const ALERT_VARIANTS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
};

export const HTTP_VERBS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const LANGUAGES = [
  {
    id: 1,
    value: 'ENG',
    name: 'English',
    LANGUAGE_CODE: 'en-us',
    LCID: 'en-us',
    iconPath: EN_FLAG,
    DEFAULT: true,
    ISO_CODE: 'en',
  },
  {
    id: 2,
    value: 'BiH',
    name: 'Bosnian',
    LANGUAGE_CODE: 'bs-BA',
    LCID: 'bs-BA',
    iconPath: BA_FLAG,
    ISO_CODE: 'bs',
  },
];

export const LOCALIZATION_PAGES = {
  COMMON: 'common',
  SCREENINGS: 'screenings',
  SCREENING: 'screening',
  USER_PROFILE: 'user_profile',
  AUTH: 'auth',
  LAYOUT: 'layout',
  CART: 'cart',
  PURCHASE: 'purchase',
  FILMS: 'films',
  MONTHS: 'months',
  WEATHER: 'weather',
  VOTING: 'voting',
};

export const API_ENDPOINTS = {
  AUTH_TOKEN: 'auth/external/token',
  REFRESH_AUTH_TOKEN: 'auth/external/token/refresh',
  USERS: 'users',
  SCREENINGS: 'screenings/online',
  FILMS: 'films',
  LANGUAGES: 'editions/loaded/languages',
  COUNTRIES: 'editions/loaded/countries',
  CURRENT_EDITION: 'editions/current/basic',
  VENUES: 'venues',
  EDITIONS: 'editions',
  ALL_COUNTRIES: 'common/countries',
  CART: '/external/carts',
  TICKET_TYPES: 'ticket-types',
  PAYMENT_CONFIRM: 'payment/confirm',
  SCREENING: id => `screenings/online/${id}`,
  SCREENING_SEAT_TYPES: id => `external/screenings/${id}/seat-types`,
  SEAT_AVAILABILITY: id => `external/screenings/${id}/availability`,
  PROMO_CODE: discountCode => `external/carts/discount?discountCode=${discountCode}`,
  CUSTOMER_ACCREDITATION: (customerAccreditationNumber, screeningId) =>
    `external/customer-accreditations/${customerAccreditationNumber}/availability?screeningId=${screeningId}`,
  RESEND_TICKETS: screeningId => `tickets/screenings/${screeningId}/resend`,
  TICKET_VOTE_VALIDITY: ticketCode => `tickets/${ticketCode}/voting-validity`,
  TICKET_VOTE: ticketCode => `tickets/${ticketCode}/vote`,
};

export const MARKINGS = {
  letters: { id: 0, name: 'Letters' },
  numbers: { id: 1, name: 'Numbers' },
};
export const ORIENTATION = {
  normal: { id: 0, name: 'NORMAL' },
  reverse: { id: 1, name: 'REVERSE' },
};

export const PURCHASE_SEATS_LIMIT = 4;

export const DATEPICKER = {
  today: 'today',
  reset: 'reset',
};

const Query = {
  shared: {
    search: '',
    term: '',
    name: '',
    sortBy: '',
    sortOrder: SORT_ORDER.ASC,
    includes: '',
  },
  defaultAll: {
    pageSize: '',
    page: '',
    retrieveAll: true,
    includeCount: '',
  },
  default: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
    retrieveAll: false,
    includeCount: true,
  },
  custom: {
    screenings: {
      startDate: moment(today).format('YYYY-M-D'),
      endDate: moment(today).format('YYYY-M-D'),
      programId: '',
      venueId: '',
      sortBy: 'State',
      // includes:
      //   'ScreeningFilms.Film.FilmSections.Section.SectionTranslations.Language&Includes=ScreeningFilms.FilmPackage.FilmPackageSections.Section.SectionTranslations.Language',
    },
    films: {
      pageSize: 8,
      sectionId: '',
      languageId: '',
      countryId: '',
      includes:
        'FilmSections.Section.SectionTranslations.Language&Includes=FilmPhotos&Includes=FilmSynopses.Language&Includes=FilmCountries.Country&Includes=FilmLanguages.Language',
    },
    filmScreenings: {
      startDate: moment(today).format('YYYY-M-D'),
      includes:
        'ScreeningFilms.Film.FilmSections.Section.SectionTranslations.Language&Includes=ScreeningFilms.FilmPackage.FilmPackageSections.Section.SectionTranslations.Language&Includes=ScreeningFilms.Film.FilmLanguages.Language',
      pageSize: 30,
    },
    filmSimilar: {
      includes:
        'FilmSections.Section.SectionTranslations.Language&Includes=FilmPhotos&Includes=FilmCountries.Country&Includes=FilmLanguages.Language',
    },
  },

  generateQuerries: function() {
    let querries = {
      default: {
        ...this.default,
        ...this.shared,
      },
      defaultAll: {
        ...this.defaultAll,
        ...this.shared,
      },
    };
    Object.keys(this.custom).forEach(x => {
      querries[x] = {
        ...this.shared,
        ...this.default,
        ...this.custom[x],
      };
      querries[`${x}All`] = {
        ...this.shared,
        ...this.defaultAll,
        ...this.custom[x],
      };
    });

    return querries;
  },
};

export const CONTACTS_TYPES = {
  PRODUCTION: 'Production Company',
  CONTACT: 'Public Contact',
  CO_PRODUCTION: 'Co-Production Company',
};

export const API_QUERY = Query.generateQuerries();

export const URL = {
  SFF: 'https://www.sff.ba/',
  RAIFEISSEN: 'https://www.raiffeisenbank.ba/',
  COCA_COLA: 'https://www.coca-cola.ba/',
  BHTELECOM: 'https://bhtelecom.ba/',
  ATLANTIC: 'https://www.atlantic.hr/hr/',
  MASTERCARD: 'https://mastercard.com/',
  EU_MEDIA_PROGRAMME: 'https://ec.europa.eu/culture/index_en.htm',
  DOHA: 'https://www.dohafilminstitute.com/',
  TRT: 'https://www.trt.net.tr/bosanski/',
  DM: 'https://www.dm-drogeriemarkt.ba/',
  VISIT_SARAJEVO: 'https://www.visitsarajevo.ba/',
  SARAJEVO: 'https://www.sarajevo.ba/',
  MINISTARSTVO: 'https://www.fmks.gov.ba/',
  VLADA_KS: 'https://vlada.ks.gov.ba/',
  FONDACIJA: 'https://fondacijakinematografija.ba/',
  ASA: 'https://www.asa.ba/',
  MISTRAL: 'https://www.mistral.ba/',
  MONRI: 'https://monri.com/',
  TERMS_OF_SERVICE: 'https://www.sff.ba/stranica/pravila-upotrebe-web-stranice',
  UNICREDIT: 'https://www.unicredit.ba/',
  BINGO: 'https://bingogroup.ba/',
  UNIQA: 'https://www.uniqa.ba/',
  HTEC: 'https://htecgroup.com/',
  CINEPLEXX: 'https://cineplexx.ba',
  MINISTARSTVO_OKOLIS: 'https://www.fmoit.gov.ba/',
  UNESCO: 'https://www.unesco.org/en',
};
