import decode from 'jwt-decode';

import { TOKEN_KEY, REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY, SOCIAL_FLAG } from 'utils/constants';

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function removeTokens() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(SOCIAL_FLAG);
}

export function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    const validToken = decoded.exp < Date.now() / 1000;
    return validToken;
  } catch (err) {
    console.error('Token validation error', err);
    return true;
  }
}

export function loggedIn() {
  const token = getToken();
  return !!token && !isTokenExpired(token);
}
