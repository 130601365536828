import React from 'react';
import { DropdownToggle as ReactstrapDropdownToggle } from 'reactstrap';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown } from 'react-icons/md';

const DropdownToggleButton = ({ isInput = false, color = 'primary', disabled, onClick, children, ...rest }) => {
  return (
    <DropdownToggle isInput={isInput} color={color} disabled={disabled} onClick={onClick} {...rest}>
      <TextWrapper>{children}</TextWrapper>
      <IconWrapper>
        <ArrowIcon />
      </IconWrapper>
    </DropdownToggle>
  );
};

const DropdownToggle = styled(({ isInput, color, children, ...rest }) => (
  <ReactstrapDropdownToggle {...rest}>{children}</ReactstrapDropdownToggle>
))`
  width: 180px;

  &.btn.btn-secondary {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 54.5px;
    border-radius: 4px;
    border: solid 2px #f5f5f5;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      color: #666666;
      background-color: #ffffff;
      border-color: #f5f5f5;
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
    }
  }

  &:disabled {
    opacity: 0.3;
    ${({ color }) => {
      switch (color) {
        case 'light':
          return css`
            border: solid 1px #d8dce6;
          `;
        default:
          return;
      }
    }}
  }
  ${({ isInput }) =>
    isInput &&
    css`
      height: 38px;
      align-items: center;
      & svg {
        top: -7px;
      }
    `}
`;

const TextWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
  color: #666666;
`;

const IconWrapper = styled.div`
  display: inline;
  position: relative;
  margin-left: 40px;
`;

const ArrowIcon = styled(MdKeyboardArrowDown)`
  opacity: 50%;
  position: absolute;
  top: -6px;
  left: -10px;
`;

export default DropdownToggleButton;
