import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/reducers/sessionReducer';

import useApi from 'utils/hooks/api/useApi';
import { usePath } from 'utils/hooks';

const useFilm = id => {
  const path = usePath(`films/${id}/basic`);
  const [{ result: film, loading: loadingFilm, error: errorFilm }, getFilm] = useApi(path, {
    initialFetch: false,
  });
  const language = useSelector(getLanguage);

  useEffect(() => {
    getFilm();
  }, [getFilm, language]);

  return [{ film, loadingFilm, error: errorFilm }, getFilm];
};

export default useFilm;
