import {
  Card as BootstrapCard,
  CardHeader as BootstrapCardHeader,
  CardFooter as BootstrapCardFooter,
  CardBody as BootstrapCardBody,
  CardTitle as BootstrapCardTitle,
  CardText as BootstrapCardText,
} from 'reactstrap';
import styled, { css } from 'styled-components';
import React from 'react';

export const Card = styled(({ width, children, ...rest }) => <BootstrapCard {...rest}>{children}</BootstrapCard>)`
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaedf3;
  background-color: var(--white);
  ${({ width }) =>
    width === 'sm' &&
    css`
      width: 730px;
    `}
`;

export const CardHeader = styled(BootstrapCardHeader)`
  &.card-header {
    background-color: var(--white);
    padding: 1.75rem;
  }
`;
export const CardBody = styled(BootstrapCardBody)`
  padding: 1.75rem;
`;

export const CardFooter = styled(BootstrapCardFooter)`
  &.card-footer {
    background-color: var(--white);
    padding: 1.25rem;
  }
`;

export const CardTitle = styled(BootstrapCardTitle)`
  font-size: 18px;
  font-weight: 500;
  color: #3e3f42;
`;
export const CardSubtitle = styled(BootstrapCardText)`
  color: #9ea0a5;
  font-size: 14px;
`;

export const CardSpacer = styled.div`
  height: 1px;
  background-color: #eaedf3;
  margin: 1rem 0rem 2rem 0rem;
  width: 100%;
`;
